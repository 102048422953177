import Container from "../../Global/Container";
import PureButton from "../../Global/Button";
import "./style.css";
import Text from "../../Global/Text";
import { useAppStore } from "../../../store";
import { useEffect } from "react";

const AddCTA = ({ products }) => {
  const { setBasket, isAddFav, setIsAddFav, basket } = useAppStore();

  useEffect(() => {
    let isExist = false;
    products.map((product) => {
      if (basket?.find((el) => el?.item?.id === product?.id)) {
        isExist = true;
      }
    });
    setIsAddFav(!isExist);
  }, [basket, products]);

  const addToBasket = () => {
    let newBasket = products
      ?.map((el) => {
        return {
          qty: 1,
          item: {
            count: el?.count,
            description: el?.description,
            discountType: el?.discountType,
            endprice: el?.endprice,
            id: el?.id,
            imgs: JSON.parse(el?.img),
            name: el?.name,
            price: el?.price,
            product: el?.product,
            productID: el?.productID,
            productVariationID: el?.productVariationID,
            size: String(
              `${!!el?.size ? el?.size : ""} ${!!el?.unit ? el?.unit : ""}`
            ).trim(),
            vID: el?.id,
            variations: el?.variations,
          },
        };
      })
      .filter((product) => !basket?.find((el) => el?.item?.id === product.id));

    setBasket([...basket, ...newBasket]);
    setIsAddFav(false);
    localStorage.setItem(
      "grocery_basket",
      JSON.stringify([...basket, ...newBasket])
    );
  };

  return products?.length > 0 && isAddFav ? (
    <div className="fav-action">
      <Container>
        <PureButton type="primary" block radius={24} onClick={addToBasket}>
          <Text bold size={18} color="#fff">
            اضافة العناصر الى السلة
          </Text>
        </PureButton>
      </Container>
    </div>
  ) : null;
};

export default AddCTA;
