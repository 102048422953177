import "./style.css";

export const SwitchButton = ({ value, onChange }) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onChange(!value);
      }}
      className="switch-btn"
      style={value ? { backgroundColor: "#0000ff", flexDirection: "row" } : {}}
    >
      <span className={value ? "active" : ""}></span>
    </div>
  );
};
