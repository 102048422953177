import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useState } from "react";
import Space from "../Space";
import Container from "../Container";

const Slider = ({ banners = false, items, isActiveCard }) => {
  const [current, setCurrent] = useState(0);

  return (
    <div className="app-slider">
      <Swiper
        spaceBetween={0}
        slidesPerView={"auto"}
        onSlideChange={(e) => setCurrent(e?.activeIndex)}
      >
        {items?.map((el, i) => (
          <SwiperSlide key={i}>
            {isActiveCard ? el : <Container>{el}</Container>}
          </SwiperSlide>
        ))}
        <Space height={12} />
      </Swiper>
      <div className="swip-dots">
        {banners
          ? items?.map((el, i) => (
              <span
                key={i}
                className={i === current ? "dot dot-active" : "dot"}
              />
            ))
          : items
              ?.slice(0, -1)
              ?.map((el, i) => (
                <span
                  key={i}
                  className={i === current ? "dot dot-active" : "dot"}
                />
              ))}
      </div>
    </div>
  );
};

export default Slider;
