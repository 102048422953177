import "./style.css";

import { useState, useEffect } from "react";
import { BalyCheck, BalyBiker, BalyHome, BalyHome3 } from "../../BalyIcons";
import { useAppStore } from "../../../store";

const activeStyle = {
  color: "#0000ff",
  borderColor: "#0000ff",
};

// 1 : PENDING / طلبك ينتضر موافقة المطعم
// 2 : VENDORـACCEPTED / تم استلام طلبك من قبل المطعم
// BIKER_ACCEPTED
// 3 : BIKER_AT_BEKUP / طلبك في الطريق
// PICKED_UP
// 4 : ARRIVED_AT_DROP_OFF /  تم التوصيل

const statusPosition = {
  SUSPEND: {
    position: 1,
  },
  PENDING: {
    position: 1,
  },
  VENDOR_ACCEPTED: {
    position: 3,
  },
  PREPARING: {
    position: 3,
  },
  READY: {
    position: 3,
  },
  BIKER_ACCEPTED: {
    position: 4,
  },
  ARRIVED_AT_PICKUP: {
    position: 5,
  },
  PICKED_UP: {
    position: 6,
  },
  ARRIVED_AT_DROP_OFF: {
    position: 7,
  },
  DELIVERED: {
    position: 7,
  },
};

export const StatusBar = ({
  orderId,
  status = "PENDING",
  onChange = () => {},
}) => {
 
  const [position, setPosition] = useState(0);
  const [currentStatus, setCurrentStatus] = useState();
  const { orderStatus, orderStatusList } = useAppStore();

  useEffect(() => {
    let lastStatus =
      orderStatus?.id === orderId ? orderStatus?.lastStatus : status;
    setCurrentStatus(orderStatusList?.find((el) => el?.code === lastStatus));
  }, [orderStatusList, orderStatus ,orderId]);

  useEffect(() => {
    if (currentStatus) {
      let currentPosition = statusPosition[currentStatus?.code];
      if (currentPosition) setPosition(currentPosition.position);
      onChange(currentStatus?.description || "....");
    }
  }, [currentStatus ,orderId]);
 
  return (
    <div className="status-icons">
      <BalyHome
        size={18}
        color={position >= 7 ? activeStyle.color : "#ccc"}
        style={position >= 7 ? activeStyle : {}}
      />
      <div className="status-line" style={position >= 6 ? activeStyle : {}}>
        <span
          className={
            position === 4 || position === 6 || position === 5 ? "active" : ""
          }
        ></span>
      </div>
      <BalyBiker
        size={24}
        color={position >= 5 ? activeStyle.color : "#ccc"}
        style={position >= 5 ? activeStyle : {}}
      />
      <div className="status-line" style={position >= 4 ? activeStyle : {}}>
        <span className={position === 3 ? "active" : ""}></span>
      </div>
      <BalyHome3
        size={23}
        color={position >= 3 ? activeStyle.color : "#ccc"}
        style={position >= 3 ? activeStyle : {}}
      />
      <div className="status-line">
        <span
          className={position === 1 ? "active" : ""}
          style={position >= 2 ? activeStyle : {}}
        ></span>
      </div>
      <BalyCheck
        size={26}
        color={position >= 1 ? activeStyle.color : "#ccc"}
        style={position >= 1 ? activeStyle : {}}
      />
    </div>
  );
};
