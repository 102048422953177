import "./style.css";
import DiscountColorful from "../../../assets/images/discount_colorful2.png";

export const PureTagVoucher = ({
  VoucherCode,
  category,
  color = "#333333",
}) => {
  const selectedCategory =
    Array.isArray(category) && category.length === 1 ? category[0] : undefined;

  return (
    <div
      className="pure-tag-voucher"
      style={{ background: "#fff", color: color, border: "1px solid #eeeeee" }}
    >
      <div className="discount_image">
        <img
          src={DiscountColorful}
          style={{ marginTop: 3 }}
          alt=""
          width={40}
        />
      </div>
      <div className="title-tag">
        <span>الخصم</span>
        <span style={{ fontWeight: "bold", padding: "0 4px" }}>
          {VoucherCode}
        </span>
        {!!selectedCategory ? (
          <span> سيطبق عند اكمال الطلب من قسم {selectedCategory}</span>
        ) : (
          <span> سيطبق عند اكمالك لعملية الشراء</span>
        )}
      </div>
    </div>
  );
};
