import Ripples from "react-ripples";
import "./style.css";

const PureButton = ({
  onClick,
  children,
  type = "default",
  block = false,
  small = false,
  radius = 8,
  disabled = false,
  styles = {},
  className = undefined,
}) => {
  const btnTypes = {
    default: "",
    primary: "pure-btn-primary",
    secondary: "pure-btn-secondary",
    black: "pure-btn-black",
    accent: "pure-btn-primary-accent",
  };

  return (
    <div
      className="btn-container"
      style={{ width: block ? "100%" : "fit-content", borderRadius: radius }}
    >
      <Ripples>
        <button
          onClick={onClick}
          disabled={disabled}
          className={`pure-btn ${btnTypes[type]} ${small ? "pure-btn-small" : ""} ${!!className ? className : ""
            }`}
          style={Object.assign({}, { ...styles, borderRadius: radius })}
        >
          {children}
        </button>
      </Ripples>
    </div>
  );
};

export default PureButton;
