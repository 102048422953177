import Screen from "../components/Global/Screen";
import MainHeader from "../components/Global/MainHeader";
import Space from "../components/Global/Space";
import { OrderTab, HistoryList, ActiveList } from "../components/Orders";
import Container from "../components/Global/Container";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppStore } from "../store";
import { Empty } from "../components/Global/Empty";
import RatingModal from "../components/Global/RatingModal";
import { Helmet } from "react-helmet";

const OrdersScreen = () => {
  const [searchParams] = useSearchParams();
  const { user } = useAppStore();
  const navigate = useNavigate();

  let renderTab = {
    active: <ActiveList />,
    history: <HistoryList />,
  };

  return (
    <>
      <Helmet>
        <title>
          {searchParams?.get("tab") === "active"
            ? "الطلبات الحالية"
            : "الطلبات السابقة"}
        </title>
      </Helmet>
      <MainHeader withoutAddress bottom={<OrderTab />} />
      <Screen>
        <Space height={4} />
        {user ? (
          <Container>
            {renderTab[searchParams?.get("tab")]}
            <Space height={80} />
          </Container>
        ) : (
          <Empty
            title="لم تقم بتسجيل الدخول"
            msg={"سجل دخولك لعرض حتى يمكنك رؤية الطبات في هذه الصفحة"}
            screen="COMMENTS"
            action={() => navigate("/login?p=orders")}
            actionText={"تسجيل دخول"}
          />
        )}
        <Space height={80} />
      </Screen>
      <RatingModal />
    </>
  );
};

export default OrdersScreen;
