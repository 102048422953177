import "./style.css";

const ItemGridSkeleton = () => {
  const list = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  return (
    <div className="item-grid-sk">
      <ul className={"item-grid-sk"}>
        {list?.map((_, key) => (
          <li key={key}>
            <div className="sk-img pure-skeleton"></div>
            <div className="sk-text-1 pure-skeleton"></div>
            <div className="sk-text-2 pure-skeleton"></div>
            <div className="sk-text-3 pure-skeleton"></div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ItemGridSkeleton;
