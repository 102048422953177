import { useState } from "react";
import "./styles.css";
import { IoIosArrowBack } from "react-icons/io";

const VoucherSteps = ({
  isModal = false,
  steps,
  clientUsage = 0,
  currentStep = 0,
}) => {
  const [stepInfo] = useState(steps || []);

  const renderStyle = (step, index) => {
    const isActive = currentStep > index;
    if (isActive && !isModal) return `step step-active`;
    else if (isActive && isModal) return `step step-modal step-active-modal`;
    else if (isModal) return `step step-modal`;
    return `step`;
  };

  return (
    <div className="voucher-steps">
      {stepInfo
        ?.sort((a) => a.priority)
        .map((step, index) => (
          <div key={index} className={renderStyle(step, index)}>
            <div className="info">
              <span className="dot" />
              {step?.voucherType === "DELIVERY" ? (
                <p>
                  {step?.totalValue === 100
                    ? "توصيل مجاني"
                    : step?.totalValue + "% توصيل"}
                </p>
              ) : step?.voucherType === "COMBINED" ? (
                <p>{step?.totalValue}% وتوصيل مجاني</p>
              ) : (
                <p>{step?.totalValue}%</p>
              )}
            </div>
            <div className="line">
              {currentStep === index + 1 && (
                <div className="arrow">
                  <IoIosArrowBack />
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default VoucherSteps;
