import { useEffect } from "react";
import "./style.css";
import { useSearchParams, useNavigate } from "react-router-dom";

export const OrderTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchParams.get("tab")) {
      setSearchParams({ tab: "history" });
    }
  }, []);

  const changeTab = (tab) => {
    searchParams.delete("tab");
    navigate(`${window.location.pathname}?tab=${tab}`, { replace: true });
  };

  return (
    <div className="order-tab">
      <div
        className={`tab-item ${
          searchParams.get("tab") === "active" && "tab-active"
        }`}
        onClick={() =>
          searchParams.get("tab") !== "active" && changeTab("active")
        }
      >
        الطلبات الحالية
      </div>
      <div
        className={`tab-item ${
          searchParams.get("tab") === "history" && "tab-active"
        }`}
        onClick={() =>
          searchParams.get("tab") !== "history" && changeTab("history")
        }
      >
        الطلبات السابقة
      </div>
    </div>
  );
};
