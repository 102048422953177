import Screen from "../components/Global/Screen";
import MainHeader from "../components/Global/MainHeader";
import Slider from "../components/Global/Slider";
import { BannerCard } from "../components/Global/BannerCard";
import SearchBox from "../components/Global/SearchBox";
import Space from "../components/Global/Space";
import Container from "../components/Global/Container";
import { CategoryList } from "../components/Categories";
import { Empty } from "../components/Global/Empty";
import ItemBannerList from "../components/Global/ItemBannerList";
import { useQuery } from "react-query";
import { useAppStore } from "../store";
import { getActiveOrdersList, getHome } from "../services";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import CatSkeleton from "../components/Categories/Skeleton";
import BannerSkeleton from "../components/Global/BannerCard/Skeleton";
import ItemsBannerSkeleton from "../components/Global/ItemBannerList/Skeleton";
import { useNavigate } from "react-router-dom";
import { GoToLink } from "../helper";
import { PureTagVoucher } from "../components/Global/pureTagVoucher";
import DiscountCap from "../components/Home/DiscountCap";
import { OrderCard } from "../components/Orders";
import ga4 from "react-ga4";
import RatingModal from "../components/Global/RatingModal";
import { useRateStore } from "../components/Global/RatingModal/store";
import RatingCard from "../components/Home/RatingCard";
import { PureFreeDiscountAlert } from "../components/Global/pureFreeDiscountAlert";
import { Helmet } from "react-helmet";

const HomeScreen = () => {
  const {
    setLogout,
    setIsClientMap,
    deepVoucher,
    deepVoucherInfo,
    vendorID,
    clearBasket,
    setShouldRefetchVendorId,
    setComment,
    deliveryDiscountInfo,
  } = useAppStore();
  const [appBanners, setAppBanners] = useState([]);
  const { order } = useRateStore();
  const navigate = useNavigate();
  const mountRef = useRef(vendorID);

  const { data, isLoading, refetch } = useQuery(
    [`homeData-${vendorID}`, vendorID],
    getHome,
    {
      refetchOnWindowFocus: false,
      enabled: !!vendorID,
    }
  );

  useEffect(() => {
    if ((vendorID && mountRef.current !== vendorID) || vendorID === 0) {
      setAppBanners([]);
      clearBasket();
      setComment("");
    }

    mountRef.current = vendorID;
  }, [vendorID]);

  const { data: lastActiveOrder, refetch: activeRefetch } = useQuery(
    [`activeOrders`],
    getActiveOrdersList,
    {
      refetchOnWindowFocus: false,
      enabled: !!vendorID,
    }
  );

  useEffect(() => {
    if (lastActiveOrder && lastActiveOrder?.success) {
      if (lastActiveOrder?.data?.records?.length > 0) {
        let intervalId = setInterval(() => {
          activeRefetch();
        }, 50000);
        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [lastActiveOrder, activeRefetch]);

  useEffect(() => {
    if (data?.data && data?.success) {
      setAppBanners(data?.data?.appBanners);
    }
  }, [data]);

  const renderSkeleton = (
    <Container>
      <SearchBox disable />
      <Space height={16} />
      <BannerSkeleton />
      <Space height={16} />
      <CatSkeleton />
      <Space height={22} />
      <ItemsBannerSkeleton />
      <Space height={22} />
      <BannerSkeleton />
    </Container>
  );

  const renderBanners = (el) => {
    if (el?.type === "BEST_SELLER") {
      el.type = "CAROUSEL";
    } else if (el?.type === "LIST") {
      el.type = "BANNER";
    }
    let list = {
      SLIDER: (
        <>
          <Slider
            banners
            items={el?.banner?.map((val) => [
              <BannerCard
                img={val?.img}
                onClick={() => {
                  navigate(`/group/${val?.id}/${val?.name}`);
                  ga4.event("Grocery Carousel  Open", {
                    title: el?.name,
                    carouselID: el?.id,
                    page: "Home",
                  });
                  if (window && window.webengage)
                    window.webengage.track("Grocery Carousel  Open", {
                      title: el?.name,
                      carouselID: el?.id,
                      page: "Home",
                    });
                }}
              />,
            ])}
          />
          {el?.banner?.length > 0 && (
            <Space className="x-slider-space" height={16} />
          )}
        </>
      ),
      CAROUSEL: (
        <>
          <ItemBannerList
            id={el?.id}
            title={el?.name}
            description={el?.description}
            list={el?.appBanner_product}
          />
          {el?.appBanner_product && <Space height={16} />}
        </>
      ),
      BANNER: (
        <>
          <Helmet>
            <title>Baly Mart - الرئيسية</title>
          </Helmet>
          <Container>
            <BannerCard
              onClick={() => {
                GoToLink(() => navigate(`/group/${el?.id}/${el?.name}`));

                ga4.event("Grocery Banner Open", {
                  title: el?.name,
                  carouselID: el?.id,
                  page: "Home",
                });
                if (window && window.webengage)
                  window.webengage.track("Grocery Banner  Open", {
                    title: el?.name,
                    carouselID: el?.id,
                    page: "Home",
                  });
              }}
              img={el?.img}
            />
          </Container>
          <Space className="x-banner-space" height={16} />
        </>
      ),
      CATEGORY: (
        <>
          <Container>
            <CategoryList isHome list={el?.category} />
          </Container>
          {el?.category && <Space height={16} />}
        </>
      ),
    };

    return list[el?.type];
  };

  return (
    <>
      <MainHeader />
      <Screen>
        {!!order && lastActiveOrder?.data?.records?.length === 0 && (
          <Container>
            <RatingCard item={order} />
          </Container>
        )}
        {!isLoading && appBanners?.length !== 0 && (
          <Suspense>
            {lastActiveOrder?.success &&
              lastActiveOrder?.data?.records?.length > 0 && (
                <Container>
                  <Slider
                    banners
                    isActiveCard
                    items={[
                      ...lastActiveOrder?.data?.records?.map((el, index) => (
                        <OrderCard key={index} order={el} isFullScreen />
                      )),
                    ]}
                  />
                </Container>
              )}
          </Suspense>
        )}
        {(!deepVoucher || deliveryDiscountInfo?.Discount > 0) && (
          <Space height={16} />
        )}

        {deepVoucher && (
          <div className="voucherTagContainer">
            <PureTagVoucher
              VoucherCode={deepVoucher}
              category={deepVoucherInfo.categoryTags}
              color={"#666666"}
            />
          </div>
        )}

        {deliveryDiscountInfo?.Discount > 0 && (
          <Container>
            <PureFreeDiscountAlert />
            <Space height={16} />
          </Container>
        )}

        {!isLoading && appBanners?.length !== 0 && (
          <Container>
            <SearchBox />
          </Container>
        )}
        <DiscountCap />
        <Space height={16} />
        {isLoading ? (
          renderSkeleton
        ) : appBanners?.length === 0 ? (
          <Empty
            title="سنصل لك قريبا"
            msg={
              "نعتذر, خدماتنا لا تغطي موقعك الحالي ولكن نعمل جاهدين لنوفرها لك"
            }
            screen="HOME"
            action={() => {
              setIsClientMap(true);
              setShouldRefetchVendorId(true);
            }}
            actionText={"تغيير الموقع"}
          />
        ) : (
          appBanners?.map((el, i) => <div key={i}>{renderBanners(el)}</div>)
        )}
        <Space height={80} />
      </Screen>
      <RatingModal />
    </>
  );
};

export default HomeScreen;
