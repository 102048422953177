import "./style.css";
import Space from "../../Global/Space";
import { useAppStore } from "../../../store";
import Container from "../../Global/Container";
import discount from "../../../assets/images/discount.png";

const DiscountCap = () => {
  const { vendor } = useAppStore();
  return vendor?.discountCap?.id ? (
    <Container>
      <Space height={8} />
      <div className="dicount-cap-card">
        <div className="discount-icon">
          <img src={discount} />
        </div>
        <Space width={8} />
        {!vendor?.discountCap?.minamount ? (
          <p>{`تخفيض ${
            vendor?.discountCap?.totalValue
          }٪ على طلبك, بحد اقصى ${Number(
            vendor?.discountCap?.maxamount
          ).toLocaleString("en")} د.ع.`}</p>
        ) : (
          <p>{`خصم ${vendor?.discountCap?.totalValue}٪ على طلبك بقيمة ${Number(
            vendor?.discountCap?.minamount
          ).toLocaleString("en")} د.ع واكثر وبحد تخفيض اقصى ${Number(
            vendor?.discountCap?.maxamount
          ).toLocaleString("en")} د.ع`}</p>
        )}
      </div>
    </Container>
  ) : null;
};

export default DiscountCap;
