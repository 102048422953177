import ScreenContainer from "../components/Global/Screen";
import { CommentsList } from "../components/Comments/CommemtsList";
import { getUserComments } from "../services";
import { useAppStore } from "../store";
import { useQuery } from "react-query";
import { Skeleton } from "../components/Comments/Skeleton";
import ChildHeader from "../components/Global/ChildHeader";
import { Helmet } from "react-helmet";

const CommentsScreen = () => {
  const { user } = useAppStore();

  const { isLoading, data } = useQuery(
    [`userComments`, user?.id],
    getUserComments,
    {
      refetchOnWindowFocus: false,
      enabled: !!user?.id,
    }
  );

  return (
    <>
      <Helmet>
        <title>تعليقاتي</title>
      </Helmet>
      <ChildHeader title="تعليقاتي" />
      <ScreenContainer>
        <div className="faivorate-screen">
          {isLoading ? (
            <Skeleton />
          ) : (
            <CommentsList data={data && data?.data?.records} />
          )}
        </div>
      </ScreenContainer>
    </>
  );
};

export default CommentsScreen;
