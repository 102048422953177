import { useQuery } from "react-query";
import { OrderCard } from "../Card";
import { getOrdersList } from "../../../services";
import { useAppStore } from "../../../store";
import { useEffect, useState } from "react";
import { Empty } from "../../Global/Empty";
import { useNavigate } from "react-router-dom";
import { OrderSkeleton } from "../Skeleton";
import { useRateStore } from "../../Global/RatingModal/store";

export const HistoryList = () => {
  const { setLogout } = useAppStore();
  const { shouldRefreshAList, setShouldRefreshAList } = useRateStore();

  const [list, setList] = useState([]);
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery(
    [`order-history`, 1],
    getOrdersList,
    {
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  useEffect(() => {
    /**
     * Will refetch the list of order only when
     * "shouldRefreshAList" is true and then
     * it will deactivated automatically, this
     * will prevent the user from rating the same
     * order more than once.
     */
    if (shouldRefreshAList) {
      refetch();
      setShouldRefreshAList(false);
    }
  }, [shouldRefreshAList]);

  useEffect(() => {
    if (data?.data && data?.success) {
      setList(data?.data?.records);
    }
  }, [data]);

  if (isLoading) return <OrderSkeleton />;

  if (!isLoading && list?.length === 0)
    return (
      <Empty
        top={"35vh"}
        title="ابدأ بالطلب"
        msg={"قم بوضع طلبات جديدة, وستظهر لك الطلبات السابقة هنا"}
        screen="CART"
        action={() => navigate("/")}
        actionText={"اطلب الان"}
      />
    );

  return list?.map((el, i) => <OrderCard history key={i} order={el} />);
};
