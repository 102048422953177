export const BalyNavCat = ({
  isFill,
  size = 18,
  color = "#000",
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="22"
        y="22.5"
        width="8"
        height="8"
        rx="2"
        transform="rotate(-180 22 22.5)"
        stroke={isFill ? "#0000ff" : "#000000"}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <rect
        x="10"
        y="22.5"
        width="8"
        height="8"
        rx="4"
        transform="rotate(-180 10 22.5)"
        stroke={isFill ? "#0000ff" : "#000000"}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M12 3.5L7.66987 11L16.3301 11L12 3.5Z"
        stroke={isFill ? "#0000ff" : "#000000"}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
