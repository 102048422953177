import "./style.css";
import { BalyClose, FinishReview } from "../../../BalyIcons";
import { useRateStore } from "../store";
import { useNavigate } from "react-router-dom";

const Finish = () => {
  const { setIsRateModal, setShouldRefreshAList } = useRateStore();
  const navigate = useNavigate();
  return (
    <div className="finish">
      <section className="head">
        <div></div>
        <button onClick={() => setIsRateModal(false)} className="close-btn">
          <BalyClose size={24} />
        </button>
      </section>

      <section className="body">
        <div className="img">
          <FinishReview size={104} />
        </div>
        <b>شكرا لتقييم تجربتك</b>
      </section>
      <section className="actions">
        <button
          className="finish-btn"
          onClick={() => {
            setIsRateModal(false);
            setShouldRefreshAList(true);
            const timeout = setTimeout(() => {
              navigate("/", {
                replace: true,
              });
              clearTimeout(timeout);
            }, 150);
          }}
        >
          اذهب الى الرئيسية
        </button>
      </section>
    </div>
  );
};

export default Finish;
