import Screen from "../components/Global/Screen";
import Space from "../components/Global/Space";
import Container from "../components/Global/Container";
import { CategoryList } from "../components/Categories";
import ChildHeader from "../components/Global/ChildHeader";
import { BalyClose, BalyFilter, BalySearch } from "../components/BalyIcons";
import { SearchHistory } from "../components/Search";
import { useQuery, useInfiniteQuery } from "react-query";
import { getCatogeries, gloableSearch, pageSize } from "../services";
import CatSkeleton from "../components/Categories/Skeleton";
import { useAppStore } from "../store";
import { useEffect, useRef, useState } from "react";
import PureButtonIcon from "../components/Global/ButtonIcon";
import { Empty } from "../components/Global/Empty";
import ItemGrid from "../components/Global/ItemGrid";
import ItemsFilter from "../components/Items/Filter";
import ItemGridSkeleton from "../components/Global/ItemGrid/Skeleton";
import Divider from "../components/Global/Divider";
import Text from "../components/Global/Text";
import InfiniteScroll from "react-infinite-scroll-component";
import ga4 from "react-ga4";
import { Helmet } from "react-helmet";

const SearchScreen = () => {
  const inputRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [queryString, setQueryString] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const {
    querySearch,
    setQuerySearch,
    searchHistory,
    setSearchHistory,
    setIsFilter,
    vendorID,
    basket,
  } = useAppStore();

  const { data: cats, isLoading: catsLoading } = useQuery(
    ["categories", vendorID],
    getCatogeries,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [`search-${querySearch}`, querySearch, queryString, vendorID],
    gloableSearch,
    {
      getNextPageParam: (lastPage) => lastPage?.nextCursor,
      select: (data) => ({
        ...data,
        success: data.pages.flatMap((page) => page?.data?.success)[0],
        err: data.pages.flatMap((page) => page?.data?.err)[0] || "",
        pages: data.pages.flatMap((page) => page?.data?.data?.Products),
      }),
      refetchOnWindowFocus: false,
      refetchInterval: 0,
      enabled: !!querySearch,
    }
  );

  useEffect(() => {
    let pwa_search_history = localStorage.getItem("grocery_search_history");
    if (pwa_search_history) {
      let jsom_search = JSON.parse(pwa_search_history);
      setSearchHistory(jsom_search);
    }
  }, []);

  useEffect(() => {
    if (searchHistory) {
      localStorage.setItem(
        "grocery_search_history",
        JSON.stringify(searchHistory)
      );
    }
  }, [searchHistory]);

  useEffect(() => {
    if (data) {
      let history = [...searchHistory, querySearch.trim()];
      let uniqueHistory = [...new Set(history)];
      let last5 = uniqueHistory.slice(-5);
      setSearchHistory(last5);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.success && Array.isArray(data?.pages[0]?.records)) {
      setProducts(
        data?.pages
          ?.map((el) => el?.records)
          ?.reduce((a, b) => [...a, ...b], [])
      );
    }
  }, [data]);
  useEffect(() => {
    ga4.event("Grocery Search", {
      items: [
        {
          ChosenResultCategory: products?.map((el) => el?.category),
        },
      ],
    });
    if (window && window.webengage)
      window.webengage.track("Grocery Search", {
        ChosenResultCategory: products?.map((el) => el?.category),
      });
  }, [products]);

  const snipping = (
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );

  const appendComp = (text) =>
    products?.length >= pageSize && (
      <p style={{ textAlign: "center" }}>
        <Space height={10} />
        <Divider />
        <Space height={10} />
        <Text size={16} bold>
          {text}
        </Text>
      </p>
    );

  useEffect(() => {
    setTimeout(() => {
      if (searchValue?.length > 2) {
        setQuerySearch(searchValue);
      }
    }, 500);
  }, [searchValue]);

  return (
    <>
      <Helmet>
        <title>البحث</title>
      </Helmet>
      <ChildHeader
        style={{ height: 27 }}
        withoutBack={querySearch}
        backIcon={<BalyClose size={24} />}
        backAction={
          querySearch &&
          (() => {
            inputRef.current.value = null;
            setSearchValue(null);
            setQuerySearch(null);
          })
        }
        search={
          <input
            ref={inputRef}
            type="search"
            value={searchValue}
            onChange={(e) => {
              if (e.target.value === "") setQuerySearch(null);
              setSearchValue(e.target.value);
              ga4.event("Grocery Search", {
                chosenResultCategory: "Search",
              });
              if (window && window.webengage)
                window.webengage.track("Grocery Search", {
                  chosenResultCategory: "Search",
                });
            }}
            placeholder="ابحث عن المنتجات"
            className="cat-search-input"
          />
        }
        actions={[
          querySearch ? (
            isLoading ? (
              snipping
            ) : (
              <PureButtonIcon onClick={() => setIsFilter(true)}>
                <BalyFilter size={26} />
              </PureButtonIcon>
            )
          ) : (
            <BalySearch size={26} color="#ccc" />
          ),
        ]}
      />
      <Screen>
        <Space height={16} />
        {!isLoading && products?.length > 0 && querySearch ? (
          <Container>
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={products?.length}
              next={fetchNextPage}
              hasMore={hasNextPage}
              loader={appendComp("جاري التحميل ...")}
              endMessage={appendComp("تم تحميل جميع المنتجات")}
              style={{
                paddingBottom: basket.length > 0 ? 40 : 0,
                transition: "all 0.3s ease",
              }}
            >
              <ItemGrid list={products || []} />
            </InfiniteScroll>
          </Container>
        ) : !isLoading && products?.length === 0 && querySearch ? (
          <Container>
            <Empty
              top={"35vh"}
              title="للأسف, لم نجد ما تبحث عنه"
              msg={"لم نجد نتيجة لما تبحث عنه, حاول البحث عن منتج اخر"}
              screen="SEARCH"
              action={() => {
                setQuerySearch("");
                setSearchValue("");
              }}
              actionText={"ابحث عن شيء اخر"}
            />
          </Container>
        ) : isLoading ? (
          <Container>
            <ItemGridSkeleton />
          </Container>
        ) : (
          <>
            <Container>
              <SearchHistory setSearchValue={setSearchValue} />
            </Container>
            <Space height={16} />
            <Container>
              {catsLoading ? (
                <CatSkeleton />
              ) : (
                <CategoryList list={cats?.data || []} />
              )}
            </Container>
          </>
        )}
      </Screen>
      <ItemsFilter
        brandQuery={`?term=${querySearch}`}
        onFilter={(val) => setQueryString(val)}
      />
    </>
  );
};

export default SearchScreen;
