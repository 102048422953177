import Ripples from "react-ripples";
import "./style.css";
import { BalyBasket } from "../../BalyIcons";
import { useState, useEffect } from "react";
import Space from "../Space";
import Container from "../Container";
import { linkAvilable } from "../BottomNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStore } from "../../../store";

const hideWhen = [
  "cart",
  "checkout",
  "order",
  "login",
  "verify",
  "profile",
  "voucher-center",
  "comments",
  "referral",
];

const BasketBtn = ({ radius = 24, disabled = false, count = 0 }) => {
  const [active, setActive] = useState(true);
  const { appClose } = useAppStore();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setActive(true);
    let container = document.querySelector(".screen-container");

    if (!container) {
      setActive(false);
      return;
    }

    container.addEventListener("scroll", (e) => {
      var element = e.target;
      let isEnd =
        element.scrollHeight - element.scrollTop - element.clientHeight <= 10;
      if (isEnd) {
        setActive(true);
      } else if (e.target.scrollTop > 10) {
        setActive(false);
      } else {
        setActive(true);
      }
    });
  }, [location?.pathname]);

  const isView = () => {
    let state = true;
    let _location = location?.pathname;
    if (_location !== "/") {
      _location = _location?.split("/")[1];
      state = !!!hideWhen?.find((el) => el === _location);
    }
    return count && state;
  };

  const isMarginButtom = () => {
    let state = true;
    let _location = location?.pathname;
    if (_location !== "/") {
      _location = _location?.split("/")[1];
      state = !!linkAvilable?.find((el) => el.slice(1) === _location);
    }
    return state;
  };

  return isView() ? (
    <div
      className="pure-basket-btn"
      style={{
        bottom: isMarginButtom() ? 100 : 20,
      }}
    >
      <Container>
        <div
          className="basket-btn-container"
          style={{
            borderRadius: radius,
            width: active ? "100%" : 100,
          }}
        >
          <Ripples>
            <button
              disabled={disabled || appClose}
              className={`basket-btn`}
              style={{ borderRadius: radius }}
              onClick={() => navigate("/cart")}
            >
              <BalyBasket size={20} color="#fff" />
              {active ? (
                <span className="text">اذهب الى السلة</span>
              ) : (
                <Space width={20} />
              )}
              <div className="btn-count">{count}</div>
            </button>
          </Ripples>
        </div>
      </Container>
    </div>
  ) : null;
};

export default BasketBtn;
