import "./style.css";
import Text from "../../Global/Text";
import ItemGrid from "../../Global/ItemGrid";
import Space from "../../Global/Space";
import { useEffect, useState } from "react";
import CatSkeleton from "../../Categories/Skeleton";
import { useAppStore } from "../../../store";

export const ItemGroupList = ({ catName, list, isLoading }) => {
  const { setSubCatList } = useAppStore();

  useEffect(() => {
    setSubCatList(
      list.filter((el) => {
        return (
          el?.products &&
          el?.products?.length !== 0 && { name: el?.name, id: el?.id }
        );
      })
    );
  }, [list]);

  return (
    <div className="item-group-list">
      <div className="group-item">
        <Text bold size={18}>
          {catName}
        </Text>
        <Space height={8} />
        {isLoading ? (
          <CatSkeleton />
        ) : (
          <div className="sub-group-list">
            {list?.map(
              (subGroup) =>
                subGroup?.products &&
                subGroup?.products?.length !== 0 && (
                  <div
                    key={subGroup?.id}
                    className="sub-group-item"
                    id={`group-${subGroup?.id}`}
                  >
                    <div className="flex-space">
                      <Text size={16}>{subGroup?.name}</Text>
                    </div>
                    <Space height={8} />
                    <div className="sub-items">
                      <ItemGrid list={subGroup?.products || []} />
                    </div>
                  </div>
                )
            )}
          </div>
        )}
        <Space height={20} />
      </div>
    </div>
  );
};
