import { useAppStore } from "../../../store";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { PureModalSheet } from "../../Global/PureModalSheet";
import PureButton from "../../Global/Button";
import Space from "../../Global/Space";

export const LoginModal = ({ backTo = "/" }) => {
  const { isAskLogin, setIsAskLogin } = useAppStore();
  const navigate = useNavigate();

  return (
    <PureModalSheet
      isOpen={isAskLogin}
      onClose={() => setIsAskLogin(false)}
      viewHeight={160}
    >
      <br />
      <div className="login-modal">
        <div className="app-container">
          <Space height={16} />
          <p>يجب عليك تسجيل الدخول اولاَ</p>
          <Space height={16} />
          <PureButton
            block
            type="primary"
            onClick={() => {
              navigate(`/login?p=${backTo}`);
              setIsAskLogin(false);
            }}
          >
            تسجيل الدخول
          </PureButton>
        </div>
      </div>
    </PureModalSheet>
  );
};
