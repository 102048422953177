import "./style.css";
import { CategoryCard } from "../Card";
import Text from "../../Global/Text";
import Space from "../../Global/Space";
import { BalyDots } from "../../BalyIcons";
import Touchable from "../../Global/Ripple";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GoToLink } from "../../../helper";
import favIcon from "../../../assets/images/favIcon.png";
import ga4 from "react-ga4";

const CatMore = () => {
  const navigate = useNavigate();
  return (
    <div className="cat-list-more">
      <div className="cat-list-icon">
        <Touchable onClick={() => GoToLink(() => navigate("/categories"))}>
          <BalyDots size={25} />
        </Touchable>
      </div>
      <Space height={4} />
      <Text size={14}>المزيد</Text>
    </div>
  );
};

export const CategoryList = ({ list, isHome = false }) => {
  const [catList, setCatList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (list?.length > 0) {
      if (isHome) {
        setCatList(list.slice(0, 6));
      } else {
        setCatList(list);
      }
    }
  }, [list]);
  const handleCatClick = (id, name) => {
    GoToLink(() => navigate(`/category?id=${id}`));
    ga4.event("Grocery Category Open", {
      categoryName: name || "",
      categoryID: id || "",
      page: "Home",
    });
    if (window && window.webengage)
      window.webengage.track("Grocery Category Open", {
        categoryName: name || "",
        categoryID: id || "",
        page: "Home",
      });
  };
  return (
    <div className={`cat-list`}>
      <Space height={12} />
      <div className="list">
        <CategoryCard
          key={1}
          img={favIcon}
          label={"المفضلات"}
          onClick={() => GoToLink(() => navigate("/faivorate"))}
        />
        {catList.map((el) => (
          <CategoryCard
            key={el?.id}
            img={el?.image}
            label={el?.name}
            onClick={() => handleCatClick(el?.id, el?.name)}
          />
        ))}
        {isHome && list?.length > 7 && <CatMore />}
      </div>
    </div>
  );
};
