import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { cloneElement } from "react";
import { useRef } from "react";
import "./index.css";

const POPOVER_OFFSET = 24;

/**
 * @typedef {object} Props
 * @property {string} title
 * @property {JSX.Element[]} actions
 * @property {string} position - topLeft | topRight | bottomLeft | bottomRight | centerTop | centerBottom | centerLeft | centerRight
 * @property {boolean} backdrop
 * @property {function} onClose
 * @property {boolean} closeOnBackdrop
 * @returns {JSX.Element}
 */
export default function Popover({
  title,
  actions = [],
  position = "topLeft",
  backdrop = true,
  onClose,
  closeOnBackdrop = true,
  show = false,
  children,
}) {
  const [popoverPositionStyle, setPopoverPositionStyle] = useState({});
  const [caretPositionStyle, setCaretPositionStyle] = useState({});
  const childrenContainerRef = useRef(null);

  // get the width and height of the children container
  const childrenContainerWidth = childrenContainerRef.current?.clientWidth;
  const childrenContainerHeight = childrenContainerRef.current?.clientHeight;
  const windowWidth = window.innerWidth;

  useEffect(() => {
    switch (position) {
      case "topLeft":
        setPopoverPositionStyle({
          top: childrenContainerHeight + POPOVER_OFFSET,
          left: POPOVER_OFFSET,
        });
        setCaretPositionStyle({
          left: windowWidth / 2 - POPOVER_OFFSET,
          bottom: -10,
        });
        break;
      case "topRight":
        setPopoverPositionStyle({
          top: childrenContainerHeight + POPOVER_OFFSET,
          right: -childrenContainerWidth,
        });
        setCaretPositionStyle({
          right: childrenContainerWidth + POPOVER_OFFSET,
          bottom: -10,
        });
        break;
      case "bottomLeft":
        setPopoverPositionStyle({
          bottom: childrenContainerHeight + POPOVER_OFFSET,
          left: 0,
        });
        break;
      case "bottomRight":
        setPopoverPositionStyle({
          bottom: childrenContainerHeight + POPOVER_OFFSET,
          right: 0,
        });
        break;
      case "centerTop":
        setPopoverPositionStyle({
          top: childrenContainerHeight + POPOVER_OFFSET,
          left: childrenContainerWidth + POPOVER_OFFSET,
          transform: "translate(-50%, -50%)",
        });
        break;
      case "centerBottom":
        setPopoverPositionStyle({
          bottom: childrenContainerHeight + POPOVER_OFFSET,
          left: childrenContainerWidth + POPOVER_OFFSET,
          transform: "translate(-50%, 50%)",
        });
        break;
      case "centerLeft":
        setPopoverPositionStyle({
          top: childrenContainerHeight + POPOVER_OFFSET,
          left: childrenContainerWidth + POPOVER_OFFSET,
          transform: "translate(-50%, -50%)",
        });
        break;
      case "centerRight":
        setPopoverPositionStyle({
          top: childrenContainerHeight + POPOVER_OFFSET,
          right: childrenContainerWidth + POPOVER_OFFSET,
          transform: "translate(50%, -50%)",
        });
        break;
      default:
        setPopoverPositionStyle({
          top: childrenContainerHeight + POPOVER_OFFSET,
          left: childrenContainerWidth + POPOVER_OFFSET,
        });
        break;
    }
  }, [position, childrenContainerWidth, childrenContainerHeight]);

  return (
    <div>
      {show && (
        <div
          className="popover"
          style={{
            ...popoverPositionStyle,
          }}
        >
          {backdrop && (
            <div
              className="popover_backdrop"
              onClick={closeOnBackdrop ? onClose : undefined}
            ></div>
          )}
          <div className="popover__content">
            <div
              className="popover__content__caret"
              style={{
                ...caretPositionStyle,
              }}
            ></div>

            <div className="popover__content__header">
              <div className="popover__content__header__title">{title}</div>
            </div>
            <div className="popover__content__actions">{actions}</div>
          </div>
        </div>
      )}
      <div ref={childrenContainerRef}>
        {cloneElement(children, {
          onClick: () => {
            show && onClose();
          },
        })}
      </div>
    </div>
  );
}
