import dayjs from "dayjs";
import { BalyStarFill } from "../../BalyIcons";
import PureCard from "../../Global/Card";
import Text from "../../Global/Text";
import "./style.css";

const CommentCard = ({ item }) => {
  return (
    <div className="my-comments">
      <PureCard style={{ borderRadius: 16 }}>
        <div className="flex-space">
          <Text size={16} bold>
            {dayjs(item.CreatedAt).format(" MMMM D, YYYY h:mm A")}
          </Text>
          <div className="rate">
            <Text size={14}>
              {item.rate ? (
                <span>{item.rate.toString().substr(0, 3)}</span>
              ) : (
                "جديد"
              )}
            </Text>
            <BalyStarFill size={15} color={"#F0AD01"} />
          </div>
        </div>
        {item.comment && (
          <div className="text">
            <Text color="#666" size={14}>
              {item.comment}
            </Text>
          </div>
        )}
      </PureCard>
    </div>
  );
};

export default CommentCard;
