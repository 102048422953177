import { BalyClose } from "../../BalyIcons";
import PureButton from "../Button";
import Container from "../Container";
import { PureModalSheet } from "../PureModalSheet";
import "./infoModal.css";

export const InfoModal = ({
  title,
  isOpen,
  onClose,
  ctaText = "حسناً، فهمت",
  height = 80,
  children,
}) => {
  return (
    <PureModalSheet
      isOpen={isOpen}
      onClose={onClose}
      top={height + "%"}
      bodyStyle={{
        boxSizing: "border-box",
        padding: 24,
      }}
    >
      <div
        className="info__modal"
        style={{
          boxSizing: "border-box",
          position: "relative",
          height: "100%",
        }}
      >
        <div className="info__modal-header">
          <div> {title}</div>
          <div onClick={onClose}>
            <BalyClose />
          </div>
        </div>
        <div className="info__modal-content">{children}</div>
        <div className="info__modal-cta">
          <PureButton
            block
            type="primary"
            styles={{
              boxSizing: "border-box",
            }}
            onClick={onClose}
          >
            {ctaText}
          </PureButton>
        </div>
      </div>
    </PureModalSheet>
  );
};
