import "./style.css";

const RadioButton = ({
  name,
  id,
  value,
  onChange,
  checked,
  text,
  textSize = 18,
  labelStyle = {},
}) => {
  return (
    <label
      htmlFor={id}
      className="radio-label"
      style={{
        fontSize: textSize,
        ...labelStyle,
      }}
    >
      <input
        className="radio-input"
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className="custom-radio" style={{ right: text ? "-8px" : 0 }} />
      {text}
    </label>
  );
};

export default RadioButton;
