import { useCallback, useEffect, useState } from "react";
import { useAppStore } from "../../../store";
import { MdOutlineMyLocation } from "react-icons/md";
import "./style.css";
import { MapContainer, TileLayer, useMap, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "../../../assets/images/marker.png";

let MarkerIcon = L.icon({
  iconUrl: icon,
  iconSize: [50, 50],
});

L.Marker.prototype.options.icon = MarkerIcon;

export const PureMap = ({ onChange, editItem }) => {
  const { currentLocation, realLocation } = useAppStore();
  const center = editItem
    ? [editItem.lat, editItem.lon]
    : currentLocation || realLocation || [33.3152, 44.3661];
  const [position, setPosition] = useState(center);
  const [requestPosition, setRequestPosition] = useState(center);
  const [isRealLocation, setIsRealLocation] = useState(false);

  const MapComponent = () => {
    const map = useMap();

    // useEffect(() => {
    //   setTimeout(function () {
    //     map.invalidateSize();
    //   }, 100);
    // }, [realLocation]);

    const onMove = useCallback(() => {
      if (!isRealLocation) {
        setPosition(map.getCenter());
      } else {
        if (realLocation) {
          setPosition(realLocation);
        } else {
          getLocation((LatLon) => {
            console.L(LatLon);
            setPosition([LatLon[0], LatLon[1]]);
          });
        }
      }
    });
    const onMoveRequest = useCallback(() => {
      setRequestPosition(map.getCenter());
      setIsRealLocation(false);
    });
    useEffect(() => {
      map.on("move", onMove);
      map.on("moveend", onMoveRequest);
      return () => {
        map.off("move", onMove);
        map.off("moveend", onMoveRequest);
      };
    }, [map, onMove]);
  };

  useEffect(() => {
    onChange([requestPosition.lat, requestPosition.lng]);
  }, [requestPosition]);

  const Button = () => {
    const map = useMap();
    const userLocation = () => {
      if (realLocation) {
        setIsRealLocation(true);
        setPosition(realLocation);
        map.flyTo(realLocation);
      } else {
        getLocation((LatLon) => {
          setIsRealLocation(true);
          map.flyTo([LatLon[0], LatLon[1]]);
          setPosition([LatLon[0], LatLon[1]]);
        });
      }
    };
    return (
      <button className="mapButton" onClick={userLocation}>
        <MdOutlineMyLocation size={24} />
      </button>
    );
  };

  const getLocation = (cb) => {
    try {
      if (getLocationParam().length >= 2) {
        const [lat, long] = getLocationParam();
        cb([lat, long]);
        return;
      }
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            cb([position.coords.latitude, position.coords.longitude]);
          },
          (err) => alert("قم بتفعيل خاصية تحديد الموقع اولا!")
        );
      }
    } catch (err) {
      console.error(`clientMap PureMap getLocation: ${err}`);
    }
  };

  function getLocationParam() {
    try {
      const locationParam = new URLSearchParams(window.location.search).get(
        "location"
      );
      return typeof locationParam === "string"
        ? locationParam.split(",").map(Number)
        : [];
    } catch (err) {
      console.error(`clientMap PureMap getLocationParam: ${err}`);
    }
  }

  return (
    <MapContainer
      center={center}
      zoom={20}
      zoomControl={false}
      scrollWheelZoom={true}
      attributionControl={false}
      style={{ height: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://osrm.baly.dev/osm/{z}/{x}/{y}.png"
      />
      <Marker position={position} />
      <MapComponent />
      <Button />
    </MapContainer>
  );
};
