import "./style.css";
import { PureCard } from "./card";
import { Empty } from "../../Global/Empty";
import { PureModalSheet } from "../../Global/PureModalSheet";
import { useAppStore } from "../../../store";
import Tauchable from "../../Global/Ripple";
import toast, { Toaster } from "react-hot-toast";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Copy } from "../../BalyIcons";

const copy = require("clipboard-copy");

export const VoucherList = ({ data }) => {
  const {
    isVoucherCenterModal,
    setIsVoucherCenterModal,
    voucherCenterInfo,
    setDeepVoucher,
    setVoucherCenterInfo,
    deepVoucher,
  } = useAppStore();

  const [buttonStyle, setButtonStyle] = useState({
    title: "تطبيق",
    class: "primary-voucher-btn",
    disabled: false,
  });
  const currentDate = dayjs(new Date());
  const endDate = voucherCenterInfo && voucherCenterInfo?.endDate;
  const navigate = useNavigate();

  const numberForamt = (number) => {
    let roundedNum = Math.round(number);
    return String(roundedNum).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const handleClick = () => {
    if (
      deepVoucher === voucherCenterInfo.code &&
      buttonStyle.title === "الغاء"
    ) {
      setDeepVoucher(null);
      setButtonStyle({
        title: "تطبيق",
        class: "primary-voucher-btn",
        disabled: false,
      });
      setIsVoucherCenterModal(false);
    } else if (
      deepVoucher !== voucherCenterInfo.code &&
      buttonStyle.title === "تطبيق"
    ) {
      setButtonStyle({
        title: "الغاء",
        class: "applied-voucher-btn",
        disabled: false,
      });
      setDeepVoucher(voucherCenterInfo?.code);
      setIsVoucherCenterModal(false);
      toast.success("تم تفعيل رمز الخصم");
      navigate("/");
    }
  };

  useEffect(() => {
    if (voucherCenterInfo) {
      if (
        deepVoucher === voucherCenterInfo?.code ||
        voucherCenterInfo?.available_status === "expiringApplied"
      ) {
        setButtonStyle({
          title: "الغاء",
          class: "applied-voucher-btn",
          disabled: false,
        });
      } else if (
        voucherCenterInfo?.available_status === "EXPIRED USED" ||
        voucherCenterInfo?.available_status === "ACTIVE USED" ||
        voucherCenterInfo?.available_status === "expiringUsed"
      ) {
        setButtonStyle({
          title: "تم استخدام الرمز",
          class: "used-voucher-btn",
          disabled: true,
        });
      } else if (voucherCenterInfo?.available_status === "EXPIRED") {
        setButtonStyle({
          title: "الرمز غير فعال",
          class: "used-voucher-btn",
          disabled: true,
        });
      } else if (
        voucherCenterInfo?.available_status === "ACTIVE" ||
        voucherCenterInfo?.available_status === "expiring"
      ) {
        setButtonStyle({
          title: "تطبيق",
          class: "primary-voucher-btn",
          disabled: false,
        });
      }
    }
  }, [voucherCenterInfo, deepVoucher]);

  const copyLink = (val) => {
    let successPromise = copy(val);
    if (successPromise) {
      toast.success("تم نسخ رمز الخصم");
    } else {
      toast.error("Faild Copy !");
    }
  };

  return (
    <div className="vouchersList">
      <Toaster />
      <div className="app-container">
        <ul>
          {data &&
            data.length > 0 &&
            data &&
            data?.map(
              (el) =>
                el?.code === deepVoucher && (
                  <li key={el.id} className="tauchable">
                    <PureCard key={el.id} data={el} />
                  </li>
                )
            )}
          {data && data.length > 0 ? (
            data &&
            data?.map(
              (el) =>
                el?.code !== deepVoucher && (
                  <li key={el.id} className="tauchable">
                    <PureCard key={el.id} data={el} />
                  </li>
                )
            )
          ) : (
            <div style={{ marginTop: "40vh" }}>
              <Empty msg={<p>لا يوجد لديك رمز تخفيض</p>} />
            </div>
          )}
        </ul>
      </div>
      <PureModalSheet
        isOpen={isVoucherCenterModal}
        onClose={() => {
          setIsVoucherCenterModal(false);
          setVoucherCenterInfo(null);
        }}
        viewHeight={420}
      >
        <div className="app-container">
          <section className="filter-modal">
            <div className="VoucherModal-title">
              <p className="title">
                {setDeepVoucher && voucherCenterInfo?.customerDesc}
              </p>
            </div>
            <div className="VoucherModal-title">
              <ul>
                <p className="secondary-title">تفاصيل الخصم</p>
                <li>
                  <div style={{ display: "flex", gap: "6px" }}>
                    <p style={{ display: "flex", alignItems: "center" }}>
                      رمز الخصم {voucherCenterInfo?.code}{" "}
                    </p>
                    <div
                      className="copy-container"
                      onClick={() =>
                        voucherCenterInfo?.code &&
                        copyLink(voucherCenterInfo?.code)
                      }
                    >
                      <Copy />
                    </div>
                  </div>
                </li>
                {voucherCenterInfo?.customerDesc != null ||
                  (voucherCenterInfo?.customerDesc !== " " && (
                    <li>{setDeepVoucher && voucherCenterInfo?.customerDesc}</li>
                  ))}
                <li>
                  الحد الاقصى للخصم {numberForamt(voucherCenterInfo?.maxAmount)}{" "}
                  د.ع
                </li>
                {voucherCenterInfo?.tags !== null &&
                  Array.isArray(voucherCenterInfo?.tags) &&
                  voucherCenterInfo?.tags.map((el) => (
                    <li key={el}>
                      <span>{el}</span>
                    </li>
                  ))}

                <li>
                  الحد الأدنى للسلة {numberForamt(voucherCenterInfo?.minAmount)}{" "}
                  د.ع
                </li>
                {voucherCenterInfo?.noOfUsage >= 1 && (
                  <li>صالح ل {voucherCenterInfo?.noOfUsage} استخدامات </li>
                )}
              </ul>
            </div>
            <div className="VoucherModal-title">
              <ul>
                <p className="secondary-title">تاريخ الصلاحية</p>
                <li>
                  {dayjs(endDate).diff(currentDate, "day") + 1 > 0
                    ? `صالح لمدة ${
                        dayjs(endDate).diff(currentDate, "day") + 1
                      } ايام`
                    : "انتهى"}
                </li>
                {voucherCenterInfo?.startTime && voucherCenterInfo?.endTime && (
                  <li>
                    {"فعال من الساعة "}{" "}
                    {Intl.DateTimeFormat("en-EG", {
                      timeStyle: "short",
                    })
                      .format(new Date(voucherCenterInfo?.startTime))
                      .replace("PM", "مساءً")
                      .replace("AM", "صباحاً")}
                    {" الى "}{" "}
                    {Intl.DateTimeFormat("en-EG", {
                      timeStyle: "short",
                      minutes: undefined,
                    })
                      .format(new Date(voucherCenterInfo?.endTime))
                      .replace("PM", "مساءً")
                      .replace("AM", "صباحاً")}
                  </li>
                )}
              </ul>
            </div>

            <div className="control-btn app-container">
              <Tauchable>
                <button
                  onClick={handleClick}
                  className={buttonStyle.class}
                  style={{ width: "100%" }}
                  disabled={buttonStyle.disabled}
                >
                  {buttonStyle.title}
                </button>
              </Tauchable>
            </div>
          </section>
        </div>
      </PureModalSheet>
    </div>
  );
};
