import jwt_decode from "jwt-decode";
import ReconnectingEventSource from "reconnecting-eventsource";
import { useAppStore } from "./store";
const EVENTS_SERVER =
  process.env.REACT_APP_IS_DEV === "true"
    ? "https://events.baly.dev"
    : "https://events-s1.cloud.baly.iq";

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? `${window.location.origin}/api/v1`
    : "https://app.grocery.baly.dev/api/v1";

const reNewToken = async () => {
  let refToken = localStorage.getItem("grocery_refresh_token");
  let headers = {};

  if (!isTokenValid(refToken)) {
    localStorage.clear();
    useAppStore.setState({
      isLogin: false,
      user: null,
    });
    throw Error("refresh token expired");
  }
  headers["Authorization"] = `Bearer ${refToken}`;
  let res = await fetch(`${BASE_URL}/../auth/refresh?tz=${tz}`, {
    method: "POST",
    headers,
  });

  let resData = await res.json();
  let { token, refreshToken } = resData.data;
  localStorage.setItem("grocery_token", token);
  localStorage.setItem("grocery_refresh_token", refreshToken);
  return token;
};

const isTokenValid = (token) => {
  try {
    let { exp } = jwt_decode(token);
    return Date.now() <= exp * 1000;
  } catch (err) {
    return false;
  }
};

let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const pageSize = 12;
export const imgResolution = 60;
export const vendorID = parseInt(process.env.REACT_APP_ACTIVE_VENDOR_ID);

export const apiCall = async (
  method,
  prefix,
  data,
  auth = true,
  upload = false
) => {
  let token = localStorage.getItem("grocery_token");
  let url = new URL(`${BASE_URL}/${prefix}`);
  let headers = {};

  url.searchParams.set("tz", tz);

  if (auth && !isTokenValid(token)) token = await reNewToken();

  if (!!data) headers["Content-Type"] = "application/json; charset=utf-8";
  if (auth) headers["Authorization"] = `Bearer ${token}`;

  headers["Accept"] = "application/json";

  try {
    let res = await fetch(url.toString(), {
      method,
      headers,
      body: !!data ? JSON.stringify(data) : undefined,
    });
    let resData = await res.json();
    return resData;
  } catch (err) {
    return { success: false, err };
  }
};

export const Req = (method, prefix, data) => {
  let headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: `Bearer ${localStorage.getItem("grocery_token")}`,
  };

  return fetch(`${BASE_URL}/${prefix}`, {
    method,
    headers,
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((e) => ({ success: false, err: e }));
};

export const register = async ({ phone, stoken }) =>
  await apiCall("POST", `../auth/users?tz=${tz}`, { phone, stoken }, false);

export const updateUser = async (data) =>
  await apiCall("PUT", `../profile`, data);

export const getNearAddress = async (e) =>
  await apiCall(
    "GET",
    `clients/near_address?lat=${e.queryKey[1][0]}&lon=${e.queryKey[1][1]}&tz=${tz}`
  );

export const login = async (data) =>
  await apiCall("POST", `../auth/login?tz=${tz}`, data, false);

export const SSO_REQUEST = async (data) => {
  return await apiCall(
    "GET",
    `../auth/signon?token=${data?.token}&platform=${data?.platform}&tz=${tz}`,
    null,
    false
  );
};

// removed &vendor-id=${vendorID} from url (dynamic vendor id)
export const checkServiceArea = async (e) =>
  await apiCall(
    "GET",
    `service/check?lat=${e.queryKey[1][0]}&lon=${e.queryKey[1][1]}&tz=${tz}`,
    null,
    false
  );

export const getSettings = async (e) =>
  await apiCall("GET", `setting?tz=${tz}`, null, false);

export const createAddress = async (data) =>
  await apiCall("POST", `clients/address?tz=${tz}`, data);

export const deleteAddress = async (id) =>
  await apiCall("DELETE", `clients/address?id=${id}`);

export const updateAddress = async (data) =>
  await apiCall("PUT", `clients/address?tz=${tz}`, data);

export const addFaivorate = async (data) =>
  await apiCall("PUT", `clients/fav/product`, data);

export const getAddress = async () =>
  await apiCall("GET", `clients/address?tz=${tz}`);

export const getHome = async ({ queryKey }) => {
  return await apiCall(
    "GET",
    `home/guest?vendor-id=${queryKey[1]}&tz=${tz}`,
    null,
    false
  );
};

export const getFaivorates = async ({ queryKey }) =>
  await apiCall(
    "GET",
    `clients/fav/product?vendor-id=${queryKey[2]}&${queryKey[1] || "min=0&max=1000000"
    }`
  );

export const getProduct = async ({ queryKey }) =>
  await apiCall(
    "GET",
    `product/search?vendor-id=${queryKey[2]}&id=${queryKey[1]}`
  );

export const getBrands = async ({ queryKey }) =>
  await apiCall("GET", `brand${queryKey[1]}`, null, false);

export const getBrandsFilter = async ({ queryKey }) =>
  await apiCall("GET", `brandfilter${queryKey[1]}`, null, false);

export const getUserComments = async (e) => {
  try {
    if (
      !e.queryKey &&
      typeof e.queryKey != "object" &&
      e.queryKey.length < 2 &&
      e.queryKey[1].length < 2
    )
      return;

    return await apiCall(
      "GET",
      `vendorreview?clientID=${e.queryKey[1]}&page_num=1&page_size=${pageSize}&tz=${tz}`
    );
  } catch (err) { }
};

export const getReferalcode = async (e) =>
  await apiCall("GET", `referalcode?tz=${tz}`);

export const getSimilar = async (data) =>
  await apiCall("POST", `product/similar`, data, false);

export const makeOrder = async (data) =>
  await apiCall("POST", `../v2/orders`, data);

export const checkFirstOrder = async () =>
  await apiCall("GET", `../v1/orders/first`);

export const checkAvilableAddress = async (data) =>
  await apiCall("POST", `orders/check/out_of_range_vendor`, data);

export const checkVoucher = async (data) =>
  await apiCall("PUT", `orders/check`, data);

export const getCatogeries = async ({ queryKey }) =>
  await apiCall("GET", `category?vendor-id=${queryKey[1]}`, null, false);

export const getReasons = async () =>
  await apiCall("GET", `reviewreasons`, null, false);

export const getReasonsV2 = async ({ queryKey }) =>
  await apiCall("GET", `../v2/reviewreasons?term=${queryKey[1]}`);

// export const submitBikerReview = async (data) =>
//   await apiCall("POST", `../v2/review`, data);

export const submitBikerReview = async (data) =>
  await apiCall("POST", `bikereview`, data);

export const submitVendorReview = async (data) =>
  await apiCall("POST", `review`, data);

export const getVendorInfo = async ({ queryKey }) =>
  await apiCall(
    "GET",
    `vendorbyid?lat=${queryKey[1][0]}&lon=${queryKey[1][1]}&id=${queryKey[2]}`,
    null,
    false
  );

export const getProductsGroups = async ({ queryKey }) =>
  await apiCall(
    "GET",
    `vendor?lat=33.26858937408316&lon=44.32095805346762&page_size=1000&page_num=1&vendor-id=${queryKey[3]
    }&category-id=${queryKey[1]}&${queryKey[2] || "min=0&max=1000000"}`,
    null,
    false
  );

export const getProductsByBanner = async ({ pageParam = 1, queryKey }) => {
  try {
    const res = await apiCall(
      "GET",
      `vendor?page_size=${pageSize}&page_num=${pageParam}&vendor-id=${queryKey[3]
      }&appbanner-id=${queryKey[1]}&${queryKey[2] || "min=10&max=1000000"}`,
      null,
      false
    );

    let nextPage = pageParam + 1;
    let totalPages = res?.success ? Math.ceil(res?.data?.total / pageSize) : 0;

    return {
      data: res,
      nextCursor: pageParam < totalPages ? nextPage : undefined,
    };
  } catch (err) { }
};

export const gloableSearch = async ({ pageParam = 1, queryKey }) => {
  try {
    const res = await apiCall(
      "GET",
      `gloabl/search?vendor-id=${queryKey[3]}&page_size=${pageSize}&term=${queryKey[1]
      }&page_num=${pageParam}&${queryKey[2] || "min=10&max=1000000"}`,
      null,
      false
    );
    let nextPage = pageParam + 1;
    let totalPages = res?.success
      ? Math.ceil(res?.data?.Products?.total / pageSize)
      : 0;

    return {
      data: res,
      nextCursor: pageParam < totalPages ? nextPage : undefined,
    };
  } catch (err) { }
};

export const getLastOrder = async () =>
  await apiCall("GET", `../v2/orders/last`);

export const getOrdersList = async ({ queryKey }) =>
  await apiCall("GET", `orders?page_size=${pageSize}&page_num=${queryKey[1]}`);

export const getActiveOrdersList = async () =>
  await apiCall("GET", `orders/active`);

export const getOrderInfo = async ({ queryKey }) =>
  await apiCall("GET", `orders/search?id=${queryKey[1]}`);

export const VoucherAvailable = async (e) =>
  await apiCall(
    "GET",
    `vouchers/av_to_customers?page_size=${e.queryKey[2]}&page_num=${e.queryKey[1]}&tz=${tz}&platform=${e.queryKey[3]}`
  );

export const VoucherAvailableV2 = async (e) =>
  await apiCall(
    "GET",
    `../v2/vouchers/av_to_customers?page_size=${e.queryKey[2]}&page_num=${e.queryKey[1]}&tz=${tz}&platform=${e.queryKey[3]}`
  );

export const VoucherAvailableV3 = async (e) =>
  await apiCall(
    "GET",
    `../v3/vouchers/av_to_customers?page_size=${e.queryKey[2]}&page_num=${e.queryKey[1]}&tz=${tz}&platform=${e.queryKey[3]}`
  );

export const getOrderStatus = async () =>
  await apiCall("GET", `order/status?lang=ar`, null, false);

export const cancelOrder = async (data) =>
  await apiCall("PUT", "orders/status", data);

export const declineReason = async (data) =>
  await apiCall("PUT", "orders/declinereason", data);

export const getServiceFeeInfo = async ({ queryKey }) =>
  await apiCall("GET", `vendor/fee?id=${queryKey[1]}`, null, false);

export const createReview = async (data) =>
  await apiCall("POST", `review?tz=${tz}`, data);

export const startEventWatching = async (callback) => {
  let token = localStorage.getItem("grocery_token");
  if (!isTokenValid(token)) token = await reNewToken();
  let es = new ReconnectingEventSource(
    `${EVENTS_SERVER}/events/subscribe?Authorization=${token}&service=GROCERY`,
    {
      withCredentials: false,
    }
  );

  es.addEventListener("open", () => console.log("events connection opened..."));
  es.addEventListener("error", (err) =>
    console.log(`events connection err...`, err)
  );
  es.addEventListener("ORDER_STATUS", (evt) => {
    callback("ORDER_STATUS", evt.data);
  });
  es.addEventListener("BIKER_LOCATION", (evt) =>
    callback("BIKER_LOCATION", JSON.parse(evt.data))
  );
};
