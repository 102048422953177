import "./style.css";

export const OrderSkeleton = () => {
  return [0, 1, 2, 3].map((el) => (
    <div key={el} className="order-sk">
      <div className="order-sk-main">
        <div className="pure-skeleton order-sk-img"></div>
        <div className="order-sk-info">
          <div className="pure-skeleton order-sk-title"> </div>
          <div className="pure-skeleton order-sk-sub"> </div>
          <div className="pure-skeleton order-sk-address"> </div>
        </div>
      </div>
      <div className="pure-skeleton order-sk-footer"></div>
    </div>
  ));
};
