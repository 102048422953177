import "./style.css";
import { useNavigate } from "react-router-dom";
import {
  BalyNavHome,
  BalyNavOrder,
  BalyNavCat,
  BalyVoucher,
} from "../../BalyIcons";
import { useAppStore } from "../../../store";

const NavItem = (props) => {
  return (
    <div className="nav-item" {...props}>
      {props.items ? (
        <div
          className="badage"
          style={
            props.active === "true"
              ? { backgroundColor: "#0000ff" }
              : { backgroundColor: "#eee", color: "#000" }
          }
        >
          {props.items}
        </div>
      ) : null}

      <span className="icon">{props.icon}</span>
      <p
        className="label"
        style={
          props.active === "true" ? { color: "#0000ff" } : { color: "#000" }
        }
      >
        {props.label}
      </p>
    </div>
  );
};

export const linkAvilable = ["/", "/categories", "/orders", "/voucher-center"];

export const BottomNavBar = ({ page }) => {
  let navigate = useNavigate();

  const { vouchersAvilable } = useAppStore();

  return (
    <div
      className="botton-navbar"
      style={{
        bottom: !linkAvilable.find((el) => el === page) ? "-80px" : 0,
      }}
    >
      <div className="app-container">
        <ul>
          <li>
            <NavItem
              active={page === "/" ? "true" : "false"}
              label="الرئيسية"
              icon={
                page === "/" ? (
                  <BalyNavHome fill color="#0000ff" size={24} />
                ) : (
                  <BalyNavHome size={24} />
                )
              }
              onClick={() => {
                navigate("/");
              }}
            />
          </li>
          <li>
            <NavItem
              active={page === "/categories" ? "true" : "false"}
              label="التصنيفات"
              icon={
                page === "/categories" ? (
                  <BalyNavCat size={24} isFill color="#0000ff" />
                ) : (
                  <BalyNavCat size={24} />
                )
              }
              onClick={() => {
                navigate("/categories");
              }}
            />
          </li>
          <li>
            <NavItem
              active={page === "/orders" ? "true" : "false"}
              label="الطلبات"
              icon={
                page === "/orders" ? (
                  <BalyNavOrder color="#0000ff" isFill size={24} />
                ) : (
                  <BalyNavOrder size={24} />
                )
              }
              onClick={() => {
                navigate("/orders?tab=active");
              }}
            />
          </li>
          <li>
            <NavItem
              active={page === "/voucher-center" ? "true" : "false"}
              label="القسائم"
              items={vouchersAvilable.length || 0}
              icon={
                page === "/voucher-center" ? (
                  <BalyVoucher size={24} isFill color="#0000ff" />
                ) : (
                  <BalyVoucher size={24} />
                )
              }
              onClick={() => {
                navigate("/voucher-center");
              }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};
