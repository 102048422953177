export const BalySupport = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 1C6.85786 1 3.5 4.22355 3.5 8.2V12.6H2C1.44772 12.6 1 12.1523 1 11.6V9.2C1 8.64771 1.44772 8.2 2 8.2H3.5C3.5 4.22355 6.85786 1 11 1C15.1421 1 18.5 4.22355 18.5 8.2H20C20.5523 8.2 21 8.64771 21 9.2V11.6C21 12.1523 20.5523 12.6 20 12.6H18.5V8.2C18.5 4.22355 15.1421 1 11 1Z"
        fill="white"
      />
      <path
        d="M8.08333 16.3333V18H12.5C13.0523 18 13.5 17.5523 13.5 17V16.3333C13.5 15.781 13.0523 15.3333 12.5 15.3333H9.08333C8.53105 15.3333 8.08333 15.781 8.08333 16.3333Z"
        fill="white"
      />
      <path
        d="M18.5 8.2C18.5 4.22355 15.1421 1 11 1C6.85786 1 3.5 4.22355 3.5 8.2M18.5 8.2V12.6H20C20.5523 12.6 21 12.1523 21 11.6V9.2C21 8.64771 20.5523 8.2 20 8.2H18.5ZM3.5 8.2V12.6H2C1.44772 12.6 1 12.1523 1 11.6V9.2C1 8.64771 1.44772 8.2 2 8.2H3.5ZM3.5 15.3333V15.3333C3.5 16.8061 4.69391 18 6.16667 18H8.08333M8.08333 18V16.3333C8.08333 15.781 8.53105 15.3333 9.08333 15.3333H12.5C13.0523 15.3333 13.5 15.781 13.5 16.3333V17C13.5 17.5523 13.0523 18 12.5 18H8.08333Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
