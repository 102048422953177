import "./style.css";

import ItemCard from "../ItemCard";

const ItemGrid = ({ list }) => {
  return (
    <div className="item-grid">
      <ul className={"item-grid"}>
        {list?.map((el) => (
          <li key={el?.id}>
            <ItemCard
              id={el?.id}
              status={el?.status}
              productID={el?.productID}
              imgs={JSON.parse(el?.img)}
              name={el?.name}
              product={el?.product}
              size={String(
                `${!!el?.size ? el?.size : ""} ${!!el?.unit ? el?.unit : ""}`
              ).trim()}
              price={el?.price}
              description={el?.description}
              availableQty={el?.availableQty}
              endprice={el?.endprice}
              count={el?.count}
              discount={el?.discount}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ItemGrid;
