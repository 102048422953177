import "./style.css";
import { FiPlus, FiMinus } from "react-icons/fi";
import Touchable from "../Ripple";
import Text from "../Text";
import Space from "../Space";

const QtButton = ({
  value = 0,
  label = 0,
  onChange,
  small,
  disabledDecrease = false,
  disabledIncrease = false,
  increaseColor = "#000",
  decreaseColor = "#000",
  ...props
}) => {
  let style = small
    ? {
        width: value === 0 ? 32 : 69,
        height: 32,
      }
    : {
        width: value === 0 ? 48 : 120,
        height: 48,
      };

  const firstView = (
    <div className="qt-button" style={style} {...props}>
      <Touchable onClick={() => !disabledIncrease && onChange(value + 1, "+")}>
        <FiPlus size={20} color={disabledDecrease ? "#ccc" : increaseColor} />
      </Touchable>
    </div>
  );

  const secondView = (
    <div className="qt-button qt-visible" style={style} {...props}>
      <Touchable onClick={() => !disabledIncrease && onChange(value + 1, "+")}>
        <FiPlus size={20} color={disabledDecrease ? "#ccc" : increaseColor} />
      </Touchable>
      <Space width={small ? 5 : 10} />
      <Text bold={!small} size={small ? 14 : 18}>
        {label || value}
      </Text>
      <Space width={small ? 5 : 10} />
      <Touchable onClick={() => !disabledDecrease && onChange(value - 1, "-")}>
        <FiMinus size={20} color={disabledDecrease ? "#ccc" : decreaseColor} />
      </Touchable>
    </div>
  );

  return value === 0 ? firstView : secondView;
};

export default QtButton;
