import "./style.css";

const CatSkeleton = () => {
  const textSize = [80, 60, 30, 68, 66, 45, 70];
  return (
    <div className="sk-cat">
      <div className="sk-cat-title pure-skeleton"></div>
      <div className="sk-cat-list">
        {textSize?.map((el) => (
          <div key={el} className="sk-cat-item">
            <div className="sk-cat-item-img pure-skeleton"></div>
            <div
              className="sk-cat-item-text pure-skeleton"
              style={{ width: `${textSize}%` }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CatSkeleton;
