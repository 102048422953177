export const EmptyCommentsIcon = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52" cy="52" r="51.5" fill="#F6F6F6" stroke="#F0F0F0" />
    <path
      d="M46.8599 48.1584C46.859 48.1584 46.8582 48.1577 46.8582 48.1568C46.8589 46.3198 48.0181 43.561 51.5188 43.561C55.0206 43.561 56.1794 46.3215 56.1794 48.1584V60.0626C56.1794 60.4823 56.4414 60.8574 56.8355 61.0017L66.7936 64.6479C68.9807 65.2714 70.301 67.4576 69.8145 69.6513C69.8078 69.6817 69.8028 69.7069 69.7986 69.7378C69.7392 70.1721 69.2133 74.0198 68.6255 78.2602C67.682 85.0659 61.9526 90.0001 55.0818 90.0001V90.0001C49.7153 90.0001 44.8048 86.9824 42.3805 82.1948L36.0284 69.6506C32.5677 64.433 39.3847 62.0957 44.3831 67.7146C45.0984 68.5187 46.8616 68.0537 46.8616 66.9774V59.8845V48.1601C46.8616 48.1592 46.8608 48.1584 46.8599 48.1584V48.1584Z"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M32 36.6744L33.6667 38.3798L37 34.969M42 36.6744C42 32.436 38.6422 29 34.5 29C30.3579 29 27 32.436 27 36.6744C27 39.7944 28.8194 42.4795 31.4318 43.6794C33.7773 44.6229 35.8182 44.2862 36.5455 44C39.7803 43.1481 42 40.2504 42 36.6744Z"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.3496 63.5563C73.3496 59.9365 70.4769 57.0291 66.9004 57.0291C70.4769 57.0291 73.3496 54.1217 73.3496 50.502C73.3496 54.1217 76.2222 57.0291 79.7987 57.0291C76.2698 57.0291 73.3496 59.9365 73.3496 63.5563Z"
      fill="#CCCCCC"
    />
    <path
      d="M33.5921 62.727C33.5921 60.532 31.8514 58.7702 29.6826 58.7702C31.8514 58.7702 33.5921 57.0085 33.5921 54.8135C33.5921 57.0085 35.3328 58.7702 37.5015 58.7702C35.3328 58.7702 33.5921 60.532 33.5921 62.727Z"
      fill="#CCCCCC"
    />
    <path
      d="M83.9329 68.0932C83.9329 65.8982 82.1922 64.1364 80.0234 64.1364C82.1922 64.1364 83.9329 62.3747 83.9329 60.1797C83.9329 62.3747 85.6736 64.1364 87.8423 64.1364C85.6736 64.1364 83.9329 65.8982 83.9329 68.0932Z"
      fill="#CCCCCC"
    />
    <path
      d="M20.7141 74.3847C20.7141 72.1897 18.9734 70.4279 16.8047 70.4279C18.9734 70.4279 20.7141 68.6662 20.7141 66.4712C20.7141 68.6662 22.4548 70.4279 24.6236 70.4279C22.4548 70.4279 20.7141 72.1897 20.7141 74.3847Z"
      fill="#CCCCCC"
    />
    <path
      d="M79.2316 36.5898C79.2316 33.6377 76.9169 31.2949 74 31.2949C76.9169 31.2949 79.2316 28.9522 79.2316 26C79.2316 28.9522 81.5464 31.2949 84.4632 31.2949C81.5464 31.2949 79.2316 33.7054 79.2316 36.5898Z"
      fill="#CCCCCC"
    />
    <path
      d="M21.378 60.8136C21.378 59.4717 20.3258 58.4068 19 58.4068C20.3258 58.4068 21.378 57.3419 21.378 56C21.378 57.3419 22.4302 58.4068 23.756 58.4068C22.4302 58.4068 21.378 59.5024 21.378 60.8136Z"
      fill="#CCCCCC"
    />
    <path
      d="M83.3292 49.739C83.3292 47.8603 81.8562 46.3695 80 46.3695C81.8562 46.3695 83.3292 44.8786 83.3292 43C83.3292 44.8786 84.8022 46.3695 86.6584 46.3695C84.8022 46.3695 83.3292 47.9034 83.3292 49.739Z"
      fill="#CCCCCC"
    />
    <path
      d="M60.214 50.1263H60.8148H64.4156C68.0522 50.1263 71 47.2268 71 43.6499V29.4763C71 25.8995 68.0522 23 64.4156 23H27.5844C23.9479 23 21 25.8995 21 29.4763V43.6499C21 47.2268 23.9479 50.1263 27.5844 50.1263H40.7531V60L43 58.5"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="48.5"
      y1="30.5"
      x2="63.5"
      y2="30.5"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="53.5"
      y1="36.5"
      x2="63.5"
      y2="36.5"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
