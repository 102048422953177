import "./style.css";
import { BsCheckLg } from "react-icons/bs";

const Checkbox = ({ name, id, value, onChange, checked, text }) => {
  return (
    <label htmlFor={id} className="checkbox-label">
      <input
        className="checkbox-input"
        type="checkbox"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span class={`custom-checkbox`}>
        <BsCheckLg size={14} />
      </span>
      {text}
    </label>
  );
};

export default Checkbox;
