import Screen from "../components/Global/Screen";
import Space from "../components/Global/Space";
import Container from "../components/Global/Container";
import ChildHeader from "../components/Global/ChildHeader";
import PureButtonIcon from "../components/Global/ButtonIcon";
import { IoSearch } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CatHorizontalList,
  CatFilter,
  ItemGroupList,
} from "../components/Category";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getProductsGroups } from "../services";
import { useAppStore } from "../store";
import { Empty } from "../components/Global/Empty";
import ItemsFilter from "../components/Items/Filter";
import { Helmet } from "react-helmet";

const CategoryScreen = () => {
  const navigate = useNavigate();
  const { subCatList, vendorID } = useAppStore();
  const [searchParams] = useSearchParams();
  const [queryString, setQueryString] = useState("");
  const [catName, setCatName] = useState(null);
  const [tag, setTag] = useState(null);
  const [catList, setCatList] = useState([]);

  const { data, isLoading } = useQuery(
    ["productGroups", Number(searchParams.get("id")), queryString, vendorID],
    getProductsGroups,
    {
      refetchOnWindowFocus: false,
      enabled: !!Number(searchParams.get("id")),
    }
  );

  useEffect(() => {
    if (data?.success) {
      setCatList(data.data || []);
    }
  }, [data]);

  const navHeader = (state) => {
    let listElem = document.querySelector(".cat-h-list");
    let listElem2 = document.querySelector(".cat-h-list-scroll");
    if (listElem) {
      if (state) {
        listElem.style.marginTop = "-140px";
        listElem2.style.marginTop = "0";
        listElem.style.opacity = 0;
      } else {
        listElem.style.marginTop = "0px";
        listElem2.style.marginTop = "-35px";
        listElem.style.opacity = 1;
      }
    }
  };

  var timer = null;

  useEffect(() => {
    let container = document.querySelector(".screen-container");
    let elems = document.getElementsByClassName("sub-group-item");
    let getLastElem = elems[elems.length - 1];
    var selectedEleme;
    container.addEventListener("scroll", (e) => {
      navHeader(e.target.scrollTop >= 1);

      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        if (e.target.scrollTop === 0 && subCatList?.length > 0) {
          setTag(subCatList[0]?.id);
        } else if (getLastElem?.offsetTop) {
          if (Math.ceil(e.target.scrollTop) >= getLastElem.offsetTop - 444) {
            setTag(
              Number(getLastElem.getAttribute("id").replace("group-", ""))
            );
          } else {
            for (let elem of elems) {
              if (e.target.scrollTop >= elem.offsetTop - 200) {
                selectedEleme = elem.getAttribute("id");
              }
            }
            if (selectedEleme)
              setTag(Number(selectedEleme.replace("group-", "")));
          }
        }
      }, 100);
    });
  }, [subCatList, catName]);

  useEffect(() => {
    if (tag) {
      let elemFilter = document?.getElementById(`#tag-${tag}`);
      elemFilter?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [tag]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          right: 0,
          left: 0,
          zIndex: 999,
        }}
      >
        <ChildHeader
          actions={[
            <PureButtonIcon onClick={() => navigate("/search")}>
              <IoSearch size={22} />
            </PureButtonIcon>,
          ]}
          bottom={
            <>
              <Space height={16} />
              <Container style={{ overflow: "auto", position: "relative" }}>
                <CatHorizontalList
                  value={Number(searchParams.get("id"))}
                  onSelect={(cat) => {
                    setCatName(cat?.name);
                    navigate(`/category?id=${cat?.id}`, { replace: true });
                  }}
                  bannerID={Number(searchParams.get("bannerID"))}
                />
              </Container>

              <Container>
                <Space height={4} />
              </Container>
              <Space height={4} />
              <Container style={{ padding: 0 }}>
                <CatFilter tag={tag} setTag={setTag} />
              </Container>
            </>
          }
        />
      </div>
      <Screen>
        <Space height={260} />
        <Container>
          {!isLoading && catList?.length === 0 ? (
            <Empty
              top={"30vh"}
              title="لاتوجد منتجات"
              msg={"لاتوجد منتجات في هذا القسم حاليا."}
              screen="CART"
            />
          ) : (
            <ItemGroupList
              catName={catName}
              isLoading={isLoading}
              list={catList}
            />
          )}
        </Container>
        <Space height={catList?.length === 1 ? 0 : "15vh"} />
      </Screen>
      <ItemsFilter
        isCategory={true}
        brandQuery={`?cid=${searchParams.get("id")}`}
        onFilter={(val) => setQueryString(val)}
      />
    </>
  );
};

export default CategoryScreen;
