import "./style.css";
import ConfirmHeader from "./header";
import Space from "../Space";
import Text from "../Text";
import PureButton from "../Button";
export const ConfirmModalSheet = ({
  isOpen = false,
  onClose,
  title,
  msg,
  onConfirm,
  confirmText = "الغاء الطلب",
  cancelText = "عودة",
}) => {
  return (
    <div className={`confirm-modal`}>
      <div
        onClick={(e) => {
          e.stopPropagation();
          onClose(false);
        }}
        className={"confirm-backdroup"}
        style={{ display: isOpen ? "flex" : "none" }}
      ></div>
      <div
        onClick={(e) => e.stopPropagation()}
        id="confirm-sheet-content"
        className={`content ${isOpen ? "confirm-open-modal" : ""}`}
        style={{
          top: "auto",
          height: "auto",
        }}
      >
        <section>
          <Space height={10} />
          <ConfirmHeader title={title} onClose={onClose} />
          <Space height={14} />
          <div className="app-container">
            <Text size={16} color="#666">
              {msg}
            </Text>

            <Space height={40} />
            <div className="actions">
              <PureButton block onClick={(_) => onClose(false)}>
                <Text size={18}> {cancelText}</Text>
              </PureButton>
              <Space width={20} />
              <PureButton block onClick={onConfirm}>
                <Text size={18} color="#ff0000">
                  {confirmText}
                </Text>
              </PureButton>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
