import Screen from "../components/Global/Screen";
import { VoucherList } from "../components/VoucherCenter/VouchersList";
import { useAppStore } from "../store";
import { useEffect } from "react";
import { Empty } from "../components/Global/Empty";
import { useNavigate } from "react-router-dom";
import { GoToLink } from "../helper";
import { AiOutlineSolution } from "react-icons/ai";
import ChildHeader from "../components/Global/ChildHeader";
import Space from "../components/Global/Space";
import ga4 from "react-ga4";
import { VoucherCenterV3 } from "../components/Screens/VoucherCenterV3";
import { Helmet } from "react-helmet";
const VoucherCenter = () => {
  const { user, vouchersAvilable, setVoucherCenterNotification } =
    useAppStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (vouchersAvilable?.length > 0) {
      localStorage.setItem(
        "grocery_vouchers_history",
        JSON.stringify(vouchersAvilable?.map((el) => el.id))
      );
      setVoucherCenterNotification();
    }
    ga4.event("Grocery Voucher Center View", {});
    if (window && window.webengage)
      window.webengage.track("Grocery Voucher Center View", {});
  }, []);

  return (
    <>
      <Helmet>
        <title>مركز القسائم</title>
      </Helmet>
      <ChildHeader title={"مركز القسائم"} />
      <Screen>
        <Space height={16} />
        {user ? (
          // <VoucherList data={vouchersAvilable} />
          <VoucherCenterV3 vouchersList={vouchersAvilable} />
        ) : (
          <Empty
            icon={
              <AiOutlineSolution
                size={60}
                style={{ opacity: 0.4, marginTop: "29vh" }}
              />
            }
            msg={
              <div style={{ textAlign: "center" }}>
                <p>يجب تسجيل الدخــول للمتابعة</p>
                <button
                  className="register-btn tauchable"
                  onClick={() => GoToLink(() => navigate("/login?p=orders"))}
                >
                  تسجيل دخول / تسجيل
                </button>
              </div>
            }
          />
        )}
      </Screen>
      <Space height={60} />
    </>
  );
};

export default VoucherCenter;
