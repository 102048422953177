export const BalyFreeDiscount = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      {...props}
    >
      <path
        fill="#00F"
        d="M32 26.525c0 1.868-.398 3.247-1.195 4.138S28.865 32 27.376 32c-1.489 0-2.642-.446-3.46-1.337-.797-.891-1.195-2.27-1.195-4.138v-2.387c0-1.867.398-3.247 1.195-4.138.818-.891 1.971-1.337 3.46-1.337 1.489 0 2.632.446 3.429 1.337.797.891 1.195 2.27 1.195 4.138v2.387ZM28.76 8.414a.597.597 0 0 1-.126.382L15.55 31.14a2.293 2.293 0 0 1-.346.509c-.084.106-.21.159-.377.159h-3.24c-.23 0-.346-.117-.346-.35 0-.085.031-.191.094-.318L24.42 8.796c.147-.234.273-.393.378-.478a.55.55 0 0 1 .409-.159h3.24c.21 0 .314.085.314.255ZM17.31 15.83c0 1.868-.408 3.247-1.226 4.138-.797.891-1.94 1.337-3.429 1.337-1.489 0-2.642-.446-3.46-1.337C8.398 19.077 8 17.698 8 15.83v-2.387c0-1.867.398-3.236 1.195-4.106C10.013 8.446 11.166 8 12.655 8c1.49 0 2.632.446 3.429 1.337.818.87 1.227 2.239 1.227 4.106v2.387Zm11.545 8.308c0-1.082-.137-1.804-.41-2.165-.251-.36-.608-.54-1.069-.54-.461 0-.828.18-1.1.54-.273.361-.41 1.083-.41 2.165v2.387c0 1.082.137 1.804.41 2.165.272.36.639.54 1.1.54.461 0 .818-.18 1.07-.54.272-.361.409-1.083.409-2.165v-2.387ZM14.134 13.443c0-1.082-.137-1.804-.41-2.165-.25-.36-.607-.54-1.069-.54-.461 0-.828.18-1.1.54-.273.361-.41 1.083-.41 2.165v2.387c0 1.083.137 1.815.41 2.196.272.361.639.542 1.1.542.462 0 .818-.18 1.07-.542.272-.381.409-1.113.409-2.196v-2.387Z"
      />
    </svg>
  );
};
