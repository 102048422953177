import { lazy } from "react";
import "./style.css";
import { useAppStore } from "../../../store";
import { PureMap } from "./map";
import Touchable from "../Ripple";
import { useState, useEffect } from "react";
import { StaticMap } from "./staticMap";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-hot-toast";
import { IoAdd } from "react-icons/io5";

import {
  createAddress,
  getAddress,
  updateAddress,
  deleteAddress,
  checkServiceArea,
} from "../../../services";

import { BalyClose, BalyMarker } from "../../BalyIcons";
import queryClient from "../../../queryClient";
import { PureModalSheet } from "../PureModalSheet";
import PureButton from "../Button";
import BalyPlus from "../../BalyIcons/Plus";
import { Empty } from "../Empty";
import { useNavigate } from "react-router";
import haversine from "haversine";

const PureList = lazy(() => import("./list"));

const ClientMap = () => {
  const {
    isClientMap,
    setIsClientMap,
    user,
    setUser,
    setCurrentLocation,
    setSelectedAddress,
    selectedAddress,
    setIsLogin,
    setOrderData,
    realLocation,
    shouldRefetchVendorId,
    clear,
    setVendorID,
    setAddressPopoverConfirmation,
    currentLocation,
  } = useAppStore();

  const [viewMode, setViewMode] = useState("NEW");
  const [isInput, setIsInput] = useState(false);
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [editItem, setEditItem] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [position, setPosition] = useState([0, 0]);
  const [isNewUser, setIsNewUser] = useState(false);

  const navigate = useNavigate();

  const ArToEn = (numb) => {
    return (numb || "").replace(
      /[\u0660-\u0669]/g,
      (d) => d.charCodeAt() - 1632
    );
  };
  const phoneValidate = () => {
    const regex = /^[\u0621-\u064A\u0660-\u0669]+|07[3-9]\d{1,11}$/;
    const result = regex.exec(ArToEn(phone));
    return result;
  };

  const { isLoading: checkAreaLoading, data: serviceArea } = useQuery(
    ["checkArea", position || ""],
    checkServiceArea,
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled:
        !!position && !!position[0] && isClientMap && shouldRefetchVendorId,
    }
  );

  const {
    isLoading: getLoading,
    data,
    refetch,
  } = useQuery("getAddress", getAddress, {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!user,
    onSuccess: (data) => {
      if (data?.data === null) {
        setIsNewUser(true);
      }
    },
  });

  useEffect(() => {
    if (realLocation && realLocation.length > 0) {
      setPosition(realLocation);
    } else {
      setPosition([33.3152, 44.3661]);
    }
  }, [realLocation]);

  useEffect(() => {
    if (data && data?.data == null) {
      setIsNewUser(true);
    } else {
      setIsNewUser(false);
    }
  }, [data]);

  useEffect(() => {
    if (user) {
      refetch();
    }
  }, [user]);

  const { mutate, isLoading } = useMutation(
    editItem ? updateAddress : createAddress,
    {
      onSuccess: (data) => {
        if (data.success) {
          toast.success(data.msg);
          queryClient.invalidateQueries("getAddress");
          refetch();
          setAddress("");
          setPhone("");
          setViewMode("LIST");
          setEditItem(null);
          setIsEdit(false);
          setSelectedAddress(null);
        } else {
          toast.error(data?.err);
        }
      },
      onError: (e) => {
        toast.error(e.message);
      },
    }
  );

  useEffect(() => {
    if (user || viewMode === "INPUTS") {
      setPhone(ArToEn(editItem?.phone));
    }
  }, [user, viewMode]);

  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    deleteAddress,
    {
      onSuccess: (data, variables) => {
        if (data.success) {
          refetch();
          if (selectedAddress && variables === selectedAddress?.id) {
            setSelectedAddress(null);
            localStorage.removeItem("grocery_selectedAddress");
            setVendorID(0);
            clear();
          }
        } else {
          toast.error(data.msg);
        }
      },
      onError: (e) => {
        toast.error(e.message);
      },
    }
  );

  useEffect(() => {
    setIsEdit(false);
    setEditItem(null);
    if (data && data?.success && data?.data) {
      setIsInput(false);
      setEditItem(null);
      setViewMode("LIST");
    } else {
      setViewMode("DEFAULT");
    }
  }, [data, isClientMap]);

  const handleSaveAddress = () => {
    try {
      let dataForm = {
        clientID: user.id,
        address,
        phone: ArToEn(phone),
        lat: position[0],
        lon: position[1],
      };
      mutate(dataForm);
    } catch (err) {
      console.error(`ClientMap handleSaveAddress: ${err}`);
    }
  };
  const handleEditAddress = () => {
    try {
      editItem.address = address;
      editItem.phone = ArToEn(phone);
      editItem.clientID = user.id;
      editItem.lat = position[0];
      editItem.lon = position[1];
      setEditItem(editItem);
      mutate(editItem);
    } catch (err) {
      console.error(`ClientMap handleEditAddress: ${err}`);
    }
  };

  const handleFocus = () => {
    setIsInput(true);
  };

  useEffect(() => {
    if (isEdit) {
      setViewMode("DEFAULT");
      setAddress(editItem.address);
      setPhone(ArToEn(editItem.phone));
    } else {
      setAddress("");
      setPhone("");
      setViewMode("LIST");
      setIsInput(false);
    }
  }, [isEdit]);

  useEffect(() => {
    const selectedUserAddress = localStorage.getItem("grocery_selectedAddress");

    if (
      (data && data?.data == null) ||
      viewMode === "NEW" ||
      !selectedUserAddress
    ) {
      setAddressPopoverConfirmation(false);
    }

    if (currentLocation?.length > 0 && realLocation?.length > 0) {
      const isInRange = haversine(
        { latitude: realLocation[0], longitude: realLocation[1] },
        { latitude: currentLocation[0], longitude: currentLocation[1] },
        { threshold: 100, unit: "meter" }
      );

      if (!isInRange) {
        setAddressPopoverConfirmation(true);
      }
    }
  }, [data, selectedAddress, currentLocation, realLocation, viewMode]);

  return (
    <PureModalSheet
      isOpen={isClientMap}
      onClose={() => {
        setIsClientMap(false);
        setIsInput(false);
      }}
    >
      {isNewUser && (
        <div
          className="app-container"
          style={{
            height: "100%",
          }}
        >
          <div
            className="no_addresses"
            style={{
              height: "100%",
            }}
          >
            <div className="welcome_part">
              <Empty
                screen={"HOME"}
                title={"اهلا وسهلاً بك في بلي مارت"}
                msg={
                  "للأستمرار في استخدام خدمات بلي مارت, قم بتحديد العنوان او الموقع الخاص بك"
                }
                width="270px"
                top={"30%"}
              />
            </div>
            <div className="actions_part">
              <PureButton
                block
                radius={100}
                type="secondary"
                onClick={() => {
                  setIsNewUser(false);
                  setIsClientMap(false);
                  setCurrentLocation(position);
                }}
              >
                <div className="action_btn" onClick={() => setViewMode("LIST")}>
                  <p className="action_btn_text">
                    <span>استخدم الموقع الحالي</span>
                    <BalyMarker size={24} />
                  </p>
                </div>
              </PureButton>
              <PureButton
                block
                radius={100}
                type="primary"
                onClick={() => {
                  setViewMode("DEFAULT");
                  setIsNewUser(false);
                }}
              >
                <div className="action_btn" onClick={() => setViewMode("LIST")}>
                  <p className="action_btn_text">
                    <span>اضافة عنوان</span>
                    <BalyPlus color="white" size={24} />
                  </p>
                </div>
              </PureButton>
            </div>
          </div>
        </div>
      )}

      {!isNewUser && (
        <div className={viewMode !== "LIST" ? "client-map-sheet" : undefined}>
          <div className="sheet-header app-container">
            <div className="app-flex-space">
              <p className={"title"} onClick={() => refetch()}>
                حــدد الموقع
              </p>

              <Touchable>
                <div
                  onClick={() => {
                    setIsClientMap(false);
                    window.history.go(-1);
                  }}
                >
                  <BalyClose size={24} />
                </div>
              </Touchable>
            </div>

            {/* <small>لمشاهدة الاقتراحات الاكثر ملائمة, حدد موقعك اولاَ</small> */}
          </div>

          {viewMode === "LIST" ? (
            <PureList
              selectedItem={selectedAddress}
              handleEdit={(el) => {
                setEditItem(el);
                setIsEdit(true);
              }}
              handleDelete={(id) => deleteMutate(id)}
              loading={deleteLoading}
              address={data && data.data}
              searchComp={
                <div
                  className="search-map"
                  onClick={() => setViewMode("DEFAULT")}
                >
                  <span>اضافة عنوان جديد</span>
                  <IoAdd size={20} />
                </div>
              }
            />
          ) : (
            <>
              <div
                className="map-container"
                style={{
                  flex: isInput ? "none" : viewMode === "INPUTS" ? 4 : 14,
                }}
              >
                {viewMode === "INPUTS" ? (
                  <StaticMap position={position} />
                ) : (
                  <PureMap
                    editItem={editItem}
                    onChange={(val) => setPosition(val)}
                  />
                )}
              </div>
              <div
                className={
                  viewMode === "INPUTS"
                    ? "address-container address-active"
                    : "address-container"
                }
              >
                <div className="app-container">
                  <div className="address-form">
                    <p>عنوانك</p>
                    <input
                      value={address}
                      name="address"
                      onChange={(e) => setAddress(e.target.value)}
                      onFocus={handleFocus}
                      placeholder="ادخل عنوانك هنا"
                    />
                    <p>رقم جهة الاتصال</p>
                    <input
                      value={phone}
                      name="phone"
                      maxLength="11"
                      minLength="11"
                      onChange={(e) => setPhone(e.target.value)}
                      onFocus={handleFocus}
                      placeholder="اكتب رقم هاتفك هنا"
                    />
                  </div>
                </div>
                <div className="map-btn">
                  {viewMode !== "INPUTS" ? (
                    <PureButton
                      type="primary"
                      block
                      disabled={!serviceArea?.data}
                      radius={100}
                      onClick={() => {
                        if (user && viewMode !== "SEARCH") {
                          setViewMode("INPUTS");
                        } else {
                          if (viewMode === "SEARCH") {
                            setSelectedAddress(null);
                            localStorage.removeItem("grocery_selectedAddress");
                          }
                          setIsClientMap(false);
                          setCurrentLocation(position);
                        }
                      }}
                    >
                      {checkAreaLoading ? (
                        <div className="btn-loading"></div>
                      ) : (
                        `${
                          serviceArea?.data
                            ? "تأكيد الموقع"
                            : "خارج المنطقة المدعومة"
                        }`
                      )}
                    </PureButton>
                  ) : (
                    <PureButton
                      type="primary"
                      block
                      disabled={
                        !phoneValidate() ||
                        phone.length !== 11 ||
                        !address ||
                        phoneValidate()?.index !== 0
                      }
                      onClick={editItem ? handleEditAddress : handleSaveAddress}
                      className={
                        !phoneValidate() ||
                        phone.length !== 11 ||
                        !address ||
                        phoneValidate()?.index !== 0
                          ? "disabled-btn"
                          : "primary-btn"
                      }
                    >
                      {isLoading || getLoading ? (
                        <div className="btn-loading"></div>
                      ) : (
                        "التحقق من عنوانك"
                      )}
                    </PureButton>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </PureModalSheet>
  );
};

export default ClientMap;
