import { PureModalSheet } from "../PureModalSheet";
import "./style.css";
import { useRateStore } from "./store";
import BikerRating from "./Biker";
import VendorRating from "./Vendor";
import Finish from "./Finish";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { getReasonsV2 } from "../../../services";
import { getBikerModalHeight } from "../../../helper/biker-review";

const RatingModal = () => {
  const {
    view,
    isRateModal,
    setIsRateModal,
    vendorRate,
    bikerRate,
    setReasons,
    reset,
    order,
    setVendorRate,
    setComment,
    setProducts,
    setProductComment,
    setBikerRate,
    setView,
  } = useRateStore();

  const resetToBiker = () => {
    setVendorRate(null);
    setComment(null);
    setProducts([]);
    setProductComment(null);
    setReasons([]);
    setBikerRate(null);
    // setOrder(null);
    setView({
      step: "BIKER",
      height: bikerRate === false ? 720 : 428,
    });
  };



  // getReasons
  const { data: reasonsData } = useQuery(
    ["getReasonsV2", view?.step || ""],
    getReasonsV2,
    {
      refetchOnWindowFocus: false,
      enabled: !!order,
    }
  );

  useEffect(() => {
    if (!reasonsData || !reasonsData.success) {
      return;
    }

    setReasons(
      reasonsData?.data?.reasons != null ? reasonsData?.data?.reasons : []
    );
  }, [vendorRate, bikerRate, view?.step, reasonsData]);

  const views = {
    BIKER: <BikerRating />,
    VENDOR: <VendorRating />,
    FINISH: <Finish />,
  };

  useEffect(() => {
    if (!bikerRate) {
      setView({
        step: "BIKER",
        height: bikerRate === false ? 720 : 428,
      });
    }
  }, [isRateModal, bikerRate]);

  useEffect(() => {
    if (
      isRateModal &&
      order &&
      Number(localStorage.getItem("pwa_orderId") || 0) !== Number(order?.id)
    )
      resetToBiker();
  }, [isRateModal, order]);

  return (
    <PureModalSheet
      viewHeight={getBikerModalHeight(view)}
      isOpen={isRateModal}
      onClose={() => {
        localStorage.setItem("pwa_orderId", order?.id);
        setIsRateModal(false);
      }}
    >
      <div className="rating-modal-beta">{views[view?.step]}</div>
    </PureModalSheet>
  );
};

export default RatingModal;
