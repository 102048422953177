import Container from "../../Global/Container";
import { ReferralCard } from "./ReferralCard";
import { VoucherCard } from "./VoucherCard/VoucherCard";
import "./index.css";

export const VoucherCenterV3 = ({ vouchersList }) => {
  return (
    <Container>
      {vouchersList.map((voucherInformation, key) => (
        <VoucherCard voucherInformation={voucherInformation} key={key} />
      ))}

      {/* Referral */}
      <ReferralCard />
      {/* Referral */}
    </Container>
  );
};
