import "./style.css";

const ItemsBannerSkeleton = () => {
  return (
    <div className="sk-items-banner">
      <div className="sk-items-banner-title pure-skeleton"></div>
      <div className="sk-items-banner-list">
        {[0, 1, 2, 3].map((el) => (
          <div key={el} className="sk-cat-card">
            <div className="sk-items-banner-card-img pure-skeleton"></div>
            <div className="sk-items-banner-card-text1 pure-skeleton"></div>
            <div className="sk-items-banner-card-text2 pure-skeleton"></div>
            <div className="sk-items-banner-card-text3 pure-skeleton"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemsBannerSkeleton;
