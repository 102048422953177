import Screen from "../components/Global/Screen";
import Space from "../components/Global/Space";
import Container from "../components/Global/Container";
import ChildHeader from "../components/Global/ChildHeader";
import {
  AddressOptions,
  PaymentOptions,
  VoucherInput,
  CheckoutCTA,
} from "../components/Checkout";
import InvoiceInfo from "../components/Global/Invoice";
import { useAppStore } from "../store";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PureButton from "../components/Global/Button";
import Text from "../components/Global/Text";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import { PureModalSheet } from "../components/Global/PureModalSheet";
import Touchable from "../components/Global/Ripple";
import { BalyClose } from "../components/BalyIcons";
import haversineDistance from "haversine-distance";
import { Helmet } from "react-helmet";

const MapZoomUpdater = ({ zoom = 10 }) => {
  const map = useMapEvents({});

  useEffect(() => {
    map.setZoom(zoom);
  }, [zoom]);

  return null;
};

const CheckoutScreen = () => {
  const {
    basket,
    setIsClientMap,
    notSameLocationModal,
    setNotSameLocationModal,
    realLocation,
    currentLocation,
    setIsSameLocation,
    setPlaceOrderWithAddressWarning,
  } = useAppStore();
  const navigate = useNavigate();
  const [mapZoom, setMapZoom] = useState(4);
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    if (basket?.length === 0) {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (realLocation != null && currentLocation != null) {
      setDistance(
        haversineDistance(
          { latitude: realLocation[0], longitude: realLocation[1] },
          { latitude: currentLocation[0], longitude: currentLocation[1] }
        ) / 1000
      );
    }

    // get map zoom based on distance
    let zoom = 15;
    if (distance > 0.5 && distance < 1) {
      zoom = 16;
    }
    if (distance > 1 && distance < 4) {
      zoom = 14;
    }
    if (distance > 4 && distance < 10) {
      zoom = 12;
    }
    if (distance > 10 && distance < 20) {
      zoom = 10;
    }
    if (distance > 20) {
      zoom = 7;
    }

    setMapZoom(zoom);
  }, [notSameLocationModal, currentLocation, realLocation]);

  return (
    <>
      <Helmet>
        <title>تأكيد الطلب</title>
      </Helmet>
      <ChildHeader title={"تفاصيل الطلب"} />
      <Screen>
        <Space height={16} />
        <Container>
          <AddressOptions />
          <Space height={22} />
          <PaymentOptions />
          <Space height={22} />
          <VoucherInput />
          <Space height={12} />
          <Space height={12} />
          <InvoiceInfo isAdd />
        </Container>
        <Space height={60} />
        <CheckoutCTA />
      </Screen>
      <PureModalSheet
        isOpen={notSameLocationModal}
        onClose={() => {
          setNotSameLocationModal(false);
        }}
        top="8%"
      >
        <div className="sheet-header app-container">
          <div className="app-flex-space">
            <p className={"title"}>أكد عنوانك</p>
            <Touchable>
              <div
                onClick={() => {
                  setNotSameLocationModal(false);
                }}
              >
                <BalyClose size={24} />
              </div>
            </Touchable>
          </div>
        </div>
        <div className="confirm_address_modal_container">
          <div
            style={{
              height: "100%",
              flex: 1,
            }}
          >
            <MapContainer
              center={
                realLocation != null && currentLocation != null
                  ? [
                    (parseFloat(realLocation[0]) +
                      parseFloat(currentLocation[0])) /
                    2,
                    (parseFloat(realLocation[1]) +
                      parseFloat(currentLocation[1])) /
                    2,
                  ]
                  : realLocation || currentLocation || [0, 0]
              }
              zoom={mapZoom}
              style={{
                height: "250px",
              }}
              dragging={false}
              zoomControl={false}
            >
              <TileLayer url="https://osrm.baly.dev/osm/{z}/{x}/{y}.png" />
              {realLocation != null && <Marker position={realLocation} />}
              {currentLocation != null && <Marker position={currentLocation} />}
              <MapZoomUpdater zoom={mapZoom || 16} />
            </MapContainer>
          </div>
          <div
            className="modal-sheet-content gap-flex app-container"
            style={{
              paddingTop: 16,
              paddingBottom: 16,
              margin: 0,
            }}
          >
            <div className="app-flex">
              <Text size={18} color="#000">
                موقعك الحالي يختلف عن عنوانك المحدد
              </Text>
              <Text size={14} color="#666666">
                هل تريد الاستمرار في طلبك؟ ام تود تعديل العنوان, والاستمرار
                بالطلب بعدها؟
              </Text>
            </div>
            <div
              className="app-flex"
              style={{
                bottom: 0,
                left: 0,
                padding: 20,
                position: "fixed",
                right: 0,
              }}
            >
              <PureButton
                type="secondary"
                block
                radius={24}
                styles={{
                  marginBottom: 8,
                }}
                onClick={() => {
                  setNotSameLocationModal(false);
                  setIsClientMap(true);
                }}
              >
                <Text size={18}>تعديل العنوان</Text>
              </PureButton>
              <PureButton
                type="primary"
                block
                radius={24}
                onClick={() => {
                  setNotSameLocationModal(false);
                  setIsSameLocation(true);
                  setPlaceOrderWithAddressWarning(true);
                }}
              >
                <Text size={18} color="#fff">
                  العنوان صحيح, اكمل الطلب
                </Text>
              </PureButton>
            </div>
          </div>
        </div>
      </PureModalSheet>
    </>
  );
};

export default CheckoutScreen;
