export const BalyCalendar = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="-2 -2 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="calenderIconPath"
        d="M5.89958 4.83333V1.5M12.5662 4.83333V1.5M5.06624 8.16667H13.3996M3.39958 16.5H15.0662C15.9867 16.5 16.7329 15.7538 16.7329 14.8333V4.83333C16.7329 3.91286 15.9867 3.16667 15.0662 3.16667H3.39958C2.4791 3.16667 1.73291 3.91286 1.73291 4.83333V14.8333C1.73291 15.7538 2.4791 16.5 3.39958 16.5Z"
        stroke={color}
        strokeWidth={size > 18 ? "1.6" : "1.2"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
