import { create } from "zustand";

const STATE = {
  cancelTime: 0,
  cancellationReasons: [],
  selectedCancellationReason: {},
  otherReason: "",
  isCancelModalOpen: false,
  isConfirmModalOpen: false,
  orderId: 0,
  cancellationErrorMessage: null,
};

const ACTIONS = (set) => ({
  setCancelTime: (cancelTime) => set({ cancelTime }),
  setCancellationReasons: (cancellationReasons) => set({ cancellationReasons }),
  setOtherReason: (otherReason) => set({ otherReason }),
  setIsCancelModalOpen: (state) => set({ isCancelModalOpen: state }),
  setIsConfirmModalOpen: (state) => set({ isConfirmModalOpen: state }),
  setOrderId: (orderId) => set({ orderId }),
  setCancellationErrorMessage: (cancellationErrorMessage) =>
    set({ cancellationErrorMessage }),
  setCancellationReasonSelected: (selectedCancellationReason) =>
    set({ selectedCancellationReason }),
});

export const useCancellationStore = create((set) => ({
  ...STATE,
  ...ACTIONS(set),
}));
