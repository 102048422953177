import "./style.css";
import Text from "../Text";
import Touchable from "../Ripple";
import Space from "../Space";
import { FiPlus, FiInfo } from "react-icons/fi";
import Divider from "../Divider";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../../store";
import { useEffect, useState } from "react";
import InvoiceSkeleton from "./Skeleton";
import PureButton from "../Button";
import { BalyClose } from "../../BalyIcons";
import { PureModalSheet } from "../PureModalSheet";
import { useQuery } from "react-query";
import { getServiceFeeInfo } from "../../../services";
import { useCallback } from "react";
import { getServiceFeeValue } from "../../../helper/service-fee";
import { calculateDeliveryFeeDiscount } from "../../../helper/calculateFreeDelivery";

const getVoucherValue = (voucher, total) => {
  try {
    let voucherValue = 0;
    let priceWithVoucher = total;

    if (voucher?.voucherType === "DELIVERY") {
      return { total, voucher: 0 };
    }

    if (voucher && voucher.valueType === "PERCENTAGE") {
      voucherValue = total * (voucher.totalValue / 100);
      if (voucherValue > voucher.maxAmount) {
        voucherValue = voucher.maxAmount;
      }
    } else if (voucher && String(voucher.valueType) === "AMOUNT") {
      if (total > voucher.totalValue) {
        voucherValue = voucher.totalValue;
      } else {
        voucherValue = total;
      }
    }

    voucherValue = voucherValue > total ? total : voucherValue;

    priceWithVoucher = total - voucherValue;

    return {
      total: voucherValue > total ? 0 : priceWithVoucher,
      voucher: voucherValue,
    };
  } catch (err) {
    console.error(`ConfirmVoucher calculateVaucher: ${err}`);
  }
};

const getDiscountCap = (total, vendor) => {
  let totalDiscount = 0;
  if (
    vendor &&
    vendor?.discountCap?.id &&
    total >= vendor?.discountCap?.minamount
  ) {
    totalDiscount = total * (vendor?.discountCap?.totalValue / 100);
    if (totalDiscount > vendor?.discountCap?.maxamount) {
      totalDiscount = vendor?.discountCap?.maxamount;
    }
  }

  totalDiscount = totalDiscount > total ? total : totalDiscount;

  let totalValue = total - totalDiscount;

  return {
    total: totalValue < 0 ? 0 : totalValue,
    discount: totalDiscount,
  };
};

export const getPrices = (itemList, voucher = null, vendor) => {
  let subTotal =
    itemList?.length > 0
      ? itemList
          ?.map((el) => el?.item?.price * el?.qty)
          ?.reduce((a, b) => a + b)
      : 0;

  let total =
    itemList?.length > 0
      ? itemList
          ?.map((el) => el?.item?.endprice * el?.qty)
          ?.reduce((a, b) => a + b)
      : 0;

  let discount = subTotal - total;

  return {
    subTotal: subTotal || 0,
    total:
      getVoucherValue(voucher, getDiscountCap(total, vendor)?.total)?.total ||
      0,
    discount:
      discount +
        getDiscountCap(total, vendor)?.discount +
        getVoucherValue(voucher, getDiscountCap(total, vendor)?.total)
          ?.voucher || 0,

    voucherValue: getVoucherValue(voucher, getDiscountCap(total, vendor).total)
      ?.voucher,
  };
};

const ItemInfo = ({ item, qty }) => {
  return (
    <div className="invoice-item-info">
      <div className="invoice-item-img">
        <img src={item?.imgs[0]} />
      </div>
      <Space width={8} />
      <div className="invoice-item-text">
        <Text size={14}>{`${item?.product} ${item?.name || ""} ${qty}x`}</Text>
        <Text color="#666" size={12}>
          {item?.size}
        </Text>
      </div>
      <Text size={14}>{`${Number(item.price).toLocaleString("en")} د.ع`}</Text>
    </div>
  );
};

const InvoiceInfo = ({
  isAdd = false,
  invoice = [],
  loading = false,
  order = null,
}) => {
  const {
    basket,
    voucher,
    vendor,
    vendorID,
    serviceFeeInformation,
    setServiceFeeInformation,
    deliveryDiscountInfo,
  } = useAppStore();
  const [itemList, setItemList] = useState([]);
  const [serviceFeeModal, setServiceFeeModal] = useState(false);

  let deliveryFee = isAdd ? vendor?.delivery || 0 : order?.deliveryFee || 0;

  let navigate = useNavigate();

  useEffect(() => {
    if (isAdd) {
      setItemList(basket);
    } else {
      setItemList(invoice);
    }
  }, [invoice, basket]);

  let { subTotal, total, discount, voucherValue } = getPrices(
    itemList,
    voucher,
    vendor
  );

  const { data: serviceFeeInfo } = useQuery(
    ["SERVICE_FEE_INFO", vendorID],
    getServiceFeeInfo
  );

  useEffect(() => {
    if (serviceFeeInfo) {
      setServiceFeeInformation(serviceFeeInfo?.data);
    }
  }, [serviceFeeInfo]);

  const calculateServiceFee = useCallback(
    () => getServiceFeeValue(serviceFeeInformation, subTotal),
    [serviceFeeInformation, subTotal]
  );

  // const hasFreeDeliveryFeature =
  //   ((isAdd && deliveryDiscountInfo?.Discount > 0) ||
  //     order?.totalDeliveryDiscount > 0) &&
  //   total >= deliveryDiscountInfo?.Minamount;

  let freeDeliveryValue = calculateDeliveryFeeDiscount(
    deliveryDiscountInfo,
    vendor,
    total + voucherValue,
    voucher
  );

  const hasFreeDeliveryFeature = isAdd
    ? (deliveryDiscountInfo?.Discount > 0 &&
        total + voucherValue >= deliveryDiscountInfo?.Minamount) ||
      voucher?.voucherType === "COMBINED" ||
      (voucher?.voucherType === "DELIVERY" &&
        total + voucherValue >= voucher?.minAmount)
    : order?.totalDeliveryDiscount > 0;

  const orderTotalValue = () => {
    if (["COMBINED", "DISCOUNT"].includes(voucher?.voucherType)) {
      return freeDeliveryValue;
    }

    if (voucher?.voucherType === "DELIVERY") {
      if (freeDeliveryValue == deliveryFee || freeDeliveryValue === 0) {
        return 0;
      } else {
        return deliveryFee - freeDeliveryValue;
      }
    }

    return freeDeliveryValue + voucherValue;
  };

  const getDeliveryDiscount = () => {
    let value = 0;

    if (voucher?.voucherType === "DELIVERY") {
      if (freeDeliveryValue == deliveryFee || freeDeliveryValue === 0) {
        return "مجاني";
      } else {
        return (deliveryFee - freeDeliveryValue).toLocaleString("en") + " د.ع ";
      }
    }

    if (order?.totalDeliveryDiscount) {
      const totalDeliveryDiscount = Number(
        deliveryFee -
          (order?.totalDeliveryDiscount + order?.voucherDeliveryTotalValue)
      );
      return Number(totalDeliveryDiscount) === 0
        ? "مجاني"
        : Number(totalDeliveryDiscount).toLocaleString("en") + " د.ع ";
    }

    if (order?.voucherDeliveryTotalValue) {
      const voucherDeliveryTotalValue = Number(
        deliveryFee - order?.voucherDeliveryTotalValue
      );
      return Number(voucherDeliveryTotalValue) === 0
        ? "مجاني"
        : Number(voucherDeliveryTotalValue).toLocaleString("en") + " د.ع ";
    }

    return freeDeliveryValue + value == 0
      ? "مجاني"
      : freeDeliveryValue.toLocaleString("en") + " د.ع ";
  };

  return (
    <div className="invoice-info">
      <div className="flex-space">
        <Text size={16}>تفاصيل الطلب</Text>
        {isAdd && (
          <Touchable onClick={() => navigate("/")}>
            <Space width={6} />
            <Text size={14} color="#0000ff">
              <div className="flex-center">
                اضافة عناصر
                <Space width={6} />
                <FiPlus size={20} color="#0000ff" />
              </div>
            </Text>
          </Touchable>
        )}
      </div>
      <Space height={10} />
      {loading ? (
        <InvoiceSkeleton />
      ) : (
        <ul>
          {itemList?.map((el, i) => (
            <li key={i}>
              <ItemInfo item={el?.item} qty={el?.qty} />
              <Space height={6} />
            </li>
          ))}
        </ul>
      )}
      <Divider />
      <Space height={12} />
      <div className="flex-space">
        <Text size={14}>المجموع</Text>
        <Text size={14}>{`${Number(
          isAdd ? subTotal : order?.subTotal || 0
        ).toLocaleString("en")} د.ع`}</Text>
      </div>
      <Space height={21} />
      <div className="flex-space">
        <Text size={14}>الشحن</Text>
        <Text size={14} color={deliveryFee ? "#000" : "#01B348"}>
          <div className="delivery-info">
            {(hasFreeDeliveryFeature ||
              order?.voucherDeliveryTotalValue > 0) && (
              <div className="free-delivery">{getDeliveryDiscount()}</div>
            )}
            <div
              className={`normal-delivery ${
                order?.totalDeliveryDiscount > 0 ||
                order?.voucherDeliveryTotalValue > 0
                  ? "discard"
                  : hasFreeDeliveryFeature
                  ? "discard"
                  : null
              }`}
            >
              {deliveryFee
                ? `${Number(deliveryFee).toLocaleString("en")} د.ع`
                : "مجاني"}
            </div>
          </div>
        </Text>
      </div>
      <Space height={21} />
      <div className="flex-space">
        <div className="flex-center" onClick={() => setServiceFeeModal(true)}>
          <Text size={14}>
            <span>عمولة الخدمة</span>
          </Text>
          <FiInfo
            size={20}
            color="#9A9A9A"
            style={{
              marginRight: 5,
            }}
          />
        </div>
        <Text size={14} color={deliveryFee ? "#000" : "#01B348"}>
          {`${Number(calculateServiceFee()).toLocaleString("en")} د.ع`}
        </Text>
      </div>
      <Space height={21} />
      <div className="flex-space">
        <Text size={14}>قيمة الخصم</Text>
        <Text size={14}>{`${Number(
          isAdd
            ? voucher?.voucherType === "DELIVERY"
              ? discount - voucherValue
              : discount
            : order?.discountTotalValue + order?.voucherTotalValue || 0
        ).toLocaleString("en")} د.ع`}</Text>
      </div>
      <Space height={6} />
      <Divider />
      <Space height={10} />
      <div className="flex-space">
        <Text size={16} bold>
          المجموع الكلي
        </Text>
        <Text size={16} bold>{`${Number(
          isAdd
            ? Math.ceil(
                (total +
                  (hasFreeDeliveryFeature ? orderTotalValue() : deliveryFee)) /
                  250
              ) *
                250 +
                calculateServiceFee()
            : order?.totalValue || 0
        ).toLocaleString("en")} د.ع`}</Text>
      </div>
      <PureModalSheet
        isOpen={serviceFeeModal}
        onClose={() => {
          setServiceFeeModal(false);
        }}
        top="65%"
      >
        <div className="sheet-header app-container">
          <div className="app-flex-space">
            <p className={"title"}>ما هي عمولة الخدمة</p>
            <Touchable>
              <div
                onClick={() => {
                  setServiceFeeModal(false);
                }}
              >
                <BalyClose size={24} />
              </div>
            </Touchable>
          </div>
        </div>
        <div className="confirm_address_modal_container">
          <div
            className="modal-sheet-content gap-flex app-container"
            style={{
              paddingTop: 8,
            }}
          >
            <div className="app-flex">
              <Text size={14} color="#000">
                بناءً على قيمة طلبك قبل الخصومات، سيتم احتساب نسبة{" "}
                {serviceFeeInformation?.value}% من إجمالي قيمة المشتريات، سيكون
                الحد الأقصى للرسوم {serviceFeeInformation?.max} دينار عراقي،
                والحد الأدنى {serviceFeeInformation?.min} دينار عراقي، غرضنا من
                هذهِ الرسوم هو تقديم افضل خدمة لك
              </Text>
            </div>
            <div
              className="app-flex"
              style={{
                bottom: 0,
                left: 0,
                padding: 20,
                position: "fixed",
                right: 0,
              }}
            >
              <PureButton
                type="primary"
                block
                radius={24}
                styles={{
                  marginBottom: 8,
                }}
                onClick={() => {
                  setServiceFeeModal(false);
                }}
              >
                <Text size={18} color="#fff">
                  حسناً، فهمت
                </Text>
              </PureButton>
            </div>
          </div>
        </div>
      </PureModalSheet>
    </div>
  );
};

export default InvoiceInfo;
