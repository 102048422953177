import { useRateStore } from "../store";
import "./style.css";

const Reasions = ({
  title,
  list,
  value,
  placeholder = null,
  onChange = () => {},
}) => {
  const {
    setSelectedReasons,
    selectedReasons,
    view,
    setSelectedBikerReasons,
    selectedBikerReasons,
  } = useRateStore();

  const handleToggleSelectReason = (tag) => {
    const reasons = [];

    if (view?.step === "BIKER") {
      reasons.push(...selectedBikerReasons);
    } else {
      reasons.push(...selectedReasons);
    }

    if (reasons.includes(tag)) {
      if (view?.step === "BIKER") {
        setSelectedBikerReasons(
          selectedBikerReasons.filter((el) => el !== tag)
        );
      } else {
        setSelectedReasons(selectedReasons.filter((el) => el !== tag));
      }
    } else {
      if (view?.step === "BIKER") {
        setSelectedBikerReasons([...selectedBikerReasons, tag]);
      } else {
        setSelectedReasons([...selectedReasons, tag]);
      }
    }
  };

  return (
    <div className="rate-reasions">
      <p className="title">{title}</p>
      <div className="detials">
        <textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          rows={3}
          placeholder={
            placeholder != null ? placeholder : "قم باضافة ملاحظاتك ..."
          }
        />
        <div className="reasion-list">
          {list?.map((el, i) => (
            <div
              key={i}
              className={`reasion-tag ${
                (selectedReasons?.includes(el) ||
                  selectedBikerReasons.includes(el)) &&
                "reasion-tag-active"
              }`}
              onClick={() => handleToggleSelectReason(el)}
            >
              {el?.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Reasions;
