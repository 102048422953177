import "./style.css";
import Text from "../../Global/Text";
import { BalyArrowLeft, BalyLocation } from "../../BalyIcons";
import Touchable from "../../Global/Ripple";
import Space from "../../Global/Space";
import PureCard from "../../Global/Card";
import RadioButton from "../../Global/RadioButton";
import { useEffect, useState } from "react";
import { useAppStore } from "../../../store";
import { useMutation } from "react-query";
import { checkAvilableAddress } from "../../../services";
import { useNavigate } from "react-router-dom";
import { AlertMessage } from "../../Global/AlertMessage";
import PureButton from "../../Global/Button";
import haversine from "haversine";

export const AddressOptions = () => {
  const [clientAddress, setClientAddress] = useState(null);
  const [selectedClientAddress, setSelectedClientAddress] = useState(null);
  const [isVendorInRange, setIsVendorInRange] = useState(true);
  const [showOutOfRangeModal, setShowOutOfRangeModal] = useState(false);
  const {
    user,
    selectedAddress,
    setIsClientMap,
    isClientMap,
    isAddressAvilabel,
    setIsAddressAvilabel,
    vendorID,
    clearBasket,
    setShouldRefetchVendorId,
    realLocation,
    currentLocation,
    isSameLocation,
    setIsSameLocation,
  } = useAppStore();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(checkAvilableAddress, {
    onSuccess: (data) => {
      setIsVendorInRange(true);
      if (!data.success || !data?.data?.inrange) {
        setIsAddressAvilabel(false);
        setTimeout(() => {
          setIsVendorInRange(false);
        }, 100);
      }
      setSelectedClientAddress(selectedClientAddress ? null : clientAddress);

      if (selectedClientAddress) {
        setIsAddressAvilabel(false);
      }
    },
    onError: (e) => {},
  });

  const getAddress = () => {
    if (user && selectedAddress) {
      setClientAddress(selectedAddress);
    }
  };

  useEffect(() => {
    if (realLocation && currentLocation) {
      const distance = haversine(
        { latitude: realLocation[0], longitude: realLocation[1] },
        { latitude: currentLocation[0], longitude: currentLocation[1] },
        { threshold: 100, unit: "meter" }
      );

      setIsSameLocation(distance);
    }
  }, [realLocation, currentLocation, selectedAddress]);

  useEffect(() => {
    getAddress();
    if (!isClientMap) {
      setSelectedClientAddress(null);
      setIsAddressAvilabel(true);
    }
  }, [isClientMap]);

  const checkAddressAvilable = () => {
    try {
      setIsAddressAvilabel(true);
      let data = {
        clientAddressID: clientAddress?.id,
        vendorID: vendorID,
      };
      mutate(data);
    } catch (err) {
      setIsAddressAvilabel(false);
      console.error(`ConfirmAddress checkAddressAvilable: ${err}`);
    }
  };

  useEffect(() => {
    if (clientAddress) {
      checkAddressAvilable();
    }
  }, [clientAddress]);

  useEffect(() => {
    if (!isVendorInRange) {
      setShowOutOfRangeModal(true);
    }
  }, [isVendorInRange]);

  return (
    <div className="option-info address-options">
      <div className="flex-space">
        <Text size={16}>العنوان</Text>
        <Touchable
          onClick={() => {
            setShouldRefetchVendorId(false);
            setIsClientMap(true);
          }}
        >
          <Space width={6} />
          <Text size={14} color="#0000ff">
            <div className="flex-center">
              تغيير العنوان
              <Space width={6} />
              <BalyArrowLeft size={30} color="#0000ff" />
            </div>
          </Text>
        </Touchable>
      </div>
      <Space height={10} />
      <PureCard
        style={{
          borderColor:
            selectedClientAddress && !isAddressAvilabel ? "#e91e63" : "#ccc",
          height: 22.2,
        }}
        onClick={() => {
          if (isLoading || !isVendorInRange) return;
          clientAddress ? checkAddressAvilable() : setIsClientMap(true);
        }}
      >
        <div className="flex-center">
          <BalyLocation size={24} />
          <Space width={8} />
          <div style={{ flex: 1 }} className="flex-space">
            <div>
              {clientAddress ? (
                <Text size={18} width="64vw" ellipsis>
                  {clientAddress?.address}
                </Text>
              ) : (
                <Text size={18} color="#ccc">
                  حدد العنوان
                </Text>
              )}
            </div>
            <RadioButton
              id={"address"}
              name={"address"}
              value={selectedClientAddress && isAddressAvilabel}
              checked={selectedClientAddress && isAddressAvilabel}
            />
          </div>
        </div>
      </PureCard>
      {!isSameLocation && (
        <div className="headsup-message">
          <Text size={12}>موقعك الحالي يختلف عن هذا العنوان</Text>
        </div>
      )}
      {isLoading && <Text size={12}>جار المصادقة . . .</Text>}
      {!isLoading && !isVendorInRange && (
        <Text size={14} color="#ff0000">
          المجهز غير متاح في هذا العنوان
        </Text>
      )}
      <AlertMessage
        isOpen={showOutOfRangeModal}
        onClose={() => {
          setShowOutOfRangeModal(false);
        }}
        title="تنبيه"
        msg={
          "المجهز غير متاح في هذا العنوان, سيتم حذف السلة والرجوع للصفحة الرئيسية."
        }
        extra={
          <>
            <Touchable
              onClick={() => {
                setShowOutOfRangeModal(false);
              }}
            >
              <PureButton
                block={true}
                type="primary"
                width="100%"
                onClick={() => {
                  setShowOutOfRangeModal(false);
                  setIsClientMap(true);
                }}
              >
                <Text size={16} color="#ffffff">
                  اختيار عنوان اخر
                </Text>
              </PureButton>
            </Touchable>
            <Touchable
              onClick={() => {
                setShowOutOfRangeModal(false);
              }}
            >
              <PureButton
                onClick={() => {
                  setShouldRefetchVendorId(true);
                  clearBasket();
                  navigate("/");
                }}
              >
                <Text size={16} color="#0000ff">
                  حذف السلة والرجوع
                </Text>
              </PureButton>
            </Touchable>
          </>
        }
      />
    </div>
  );
};

export const PaymentOptions = () => {
  const { setPaymentMethod } = useAppStore();
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    if (checked) {
      setPaymentMethod(1);
    } else {
      setPaymentMethod(null);
    }
  }, [checked]);

  return (
    <div className="option-info payment-options">
      <Text size={16}>طريقة الدفع</Text>
      <Space height={12} />
      <PureCard
        style={{ borderColor: checked ? "" : "#eee", height: 22.2 }}
        onClick={() => setChecked(!checked)}
      >
        <div className="flex-center">
          <BalyLocation size={24} />
          <Space width={8} />
          <div style={{ flex: 1 }} className="flex-space">
            <Text size={18} width="64vw" ellipsis>
              الدفع عند الاستلام
            </Text>
            <RadioButton
              id={"payment"}
              name={"payment"}
              value={checked}
              checked={checked}
            />
          </div>
        </div>
      </PureCard>
    </div>
  );
};
