export const BalyBiker = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.03608 12.3554C8.03608 12.3554 10.196 12.3555 9.31912 12.3554M14.2895 17.0867C15.4066 17.0867 16.0249 16.278 15.9381 14.8447C15.9108 14.2729 15.6194 12.7474 14.7186 10.6627C14.4006 9.92684 14.6864 9.05492 15.405 8.69953V8.69953L16.7094 8.05438M12.3623 17.0867L12.2986 14.4454C12.2986 13.4012 11.3985 12.3554 10.2921 12.3554H9.31912M5.40527 17.0867V17.0867C5.40527 18.5119 6.58379 19.6673 8.00901 19.6673V19.6673C9.43988 19.6673 10.6231 18.5074 10.6231 17.0765V17.0765M10.6231 17.0765H3.6866C3.6866 17.0765 3.23169 12.3554 9.31912 12.3554M10.6231 17.0765H14.5359H21.0576L19.9165 14.5061M18.4487 17.0867V17.0867C18.4487 18.5119 19.6041 19.6673 21.0293 19.6673H21.0859C22.5111 19.6673 23.6665 18.5119 23.6665 17.0867V17.0867C23.6665 15.6615 22.5111 14.5061 21.0859 14.5061H19.9165M19.9165 14.5061L16.7094 8.05438M16.7094 8.05438L17.5791 6.33398H14.9703M9.31912 12.3554H3.6665V6.76408H9.31912V12.3554Z"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
