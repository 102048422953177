import "./style.css";
import { motion } from "framer-motion";
const Screen = ({
  children,
  animatedHeader,
  onScroll = () => {},
  color = "#fff",
  isScroll = true,
}) => {
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0.5 },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ duration: 0.3 }}
      className="screen-container"
      id="scrollableDiv"
      style={{ background: color, overflow: isScroll ? "auto" : "hidden" }}
      onScroll={(e) => {
        if (animatedHeader) {
          let scrollY = e.target.scrollTop;
          onScroll(scrollY);
        }
      }}
    >
      {children}
    </motion.div>
  );
};

export default Screen;
