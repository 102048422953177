export const BalyHome3 = ({ fill, size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1725_25137)">
        <path
          d="M17.0013 6.05572V16.4446C17.0013 17.4878 16.1485 18.3335 15.0965 18.3335H5.57273C4.52076 18.3335 3.66797 17.4878 3.66797 16.4446V3.3335L7.0013 5.22239L10.3346 3.3335L13.668 7.00016L17.0013 6.05572Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.77932 7.28274C8.815 6.60206 9.70085 6.3647 10.0721 6.93635C10.2381 7.192 10.5499 7.31167 10.8443 7.23277C11.5027 7.05636 12.0022 7.82551 11.5732 8.35522C11.3814 8.59212 11.3639 8.9256 11.5299 9.18125C11.9012 9.7529 11.324 10.4656 10.6877 10.2214C10.4031 10.1121 10.0805 10.1985 9.88871 10.4354C9.45975 10.9652 8.60356 10.6365 8.63923 9.95581C8.65519 9.6514 8.47331 9.37134 8.18873 9.2621C7.55239 9.01783 7.60039 8.10198 8.25878 7.92556C8.55322 7.84667 8.76337 7.58715 8.77932 7.28274Z"
          fill={color}
        />
        <rect
          x="5.33398"
          y="13.2485"
          width="3.68421"
          height="3.68421"
          rx="1"
          transform="rotate(-25.4313 5.33398 13.2485)"
          fill={color}
        />
        <rect
          x="12"
          y="1.58203"
          width="3.68421"
          height="3.68421"
          rx="1.84211"
          transform="rotate(-25.4313 12 1.58203)"
          fill={color}
        />
        <path
          d="M14.6723 14.1853L13.2793 12.8384C12.7366 12.3136 11.8304 12.5564 11.6227 13.2823L11.0898 15.1453C10.876 15.8926 11.5592 16.5874 12.31 16.3862L14.236 15.8702C14.9869 15.669 15.2311 14.7257 14.6723 14.1853Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1725_25137">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.333984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
