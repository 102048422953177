/**
 * Truncates a string to a specified length
 * @param {*} text The text to be truncated
 * @param {*} length The length of the truncated text
 * @param {*} endOfString The string to be appended to the truncated text
 * @returns String
 */
export const truncate = (text, length = 25, endOfString = "...") =>
  text
    ? text.length > length
      ? `${text.substring(0, length)}${endOfString}`
      : text
    : text;
