import "./style.css";
import PureCard from "../../Global/Card";
import Text from "../../Global/Text";
import PureButtonIcon from "../../Global/ButtonIcon";
import { BalyPhone, BalyBiker2 } from "../../BalyIcons";
import bikerImg from "../../../assets/images/biker.png";
import bikerEmpty from "../../../assets/images/biker-empty.svg";
import Space from "../../Global/Space";
import { FaRegStar, FaStar } from "react-icons/fa";

export const BikerCard = ({ info }) => {
  return (
    <PureCard style={{ padding: 8, paddingLeft: 10, border: 0 }}>
      <div className="biker-card">
        <div className="flex-center">
          <div className="biker-card-img">
            {info?.img ? (
              <img
                src={info?.img}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = bikerImg;
                }}
              />
            ) : (
              <img src={bikerEmpty} />
            )}
          </div>
          <Space width={8} />
          {info?.name ? (
            <div className="right">
              <div className="biker-name">
                <div>{info.name || "لايوجد اسم!"}</div>
                <div className="line-biker"></div>
                {info?.bikerRating ? (
                  <div className="biker-rate">
                    <span>{info?.bikerRating}</span>
                    <FaStar color="#F0AD01" size={14} />
                  </div>
                ) : (
                  <div className="biker-rate">
                    <span style={{ color: "#666" }}>جديد</span>
                    <FaRegStar color="#F0AD01" size={14} />
                  </div>
                )}
              </div>
              <Space height={6} />
              <p className="phone">
                <BalyPhone size={16} /> {info?.phone || "07XX XXX XXXX"}
              </p>
            </div>
          ) : (
            <p className="empity-text">يتم تعيين السائق ...</p>
          )}
        </div>
        {info?.name && (
          <div>
            <Space height={9} />
            <a className="call-btn" href={`tel:${info.phone}`}>
              <BalyPhone size={24} />
            </a>
          </div>
        )}
      </div>
    </PureCard>
  );
};
