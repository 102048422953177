import Screen from "../components/Global/Screen";
import Space from "../components/Global/Space";
import Container from "../components/Global/Container";
import ChildHeader from "../components/Global/ChildHeader";
import InvoiceInfo from "../components/Global/Invoice";
import PureCard from "../components/Global/Card";
import Text from "../components/Global/Text";
import { Row } from "../components/Global/Grid";
import { BalyCalendar, BalyLocation } from "../components/BalyIcons";
import { useAppStore } from "../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getOrderInfo } from "../services";
import { parseExpectedTime, parseInvoice } from "../helper";
import dayjs from "dayjs";
import { truncate } from "../helper/Strings";
import { Helmet } from "react-helmet";

const InvoiceScreen = () => {
  const { setVoucher } = useAppStore();
  const [order, setOrder] = useState(null);
  const { id } = useParams();

  const { data, isLoading } = useQuery(
    [`order-info`, Number(id)],
    getOrderInfo,
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (data?.data && data?.success) {
      setOrder(data?.data);
      if (data?.data?.voucherCode) {
        setVoucher({
          id: data?.data?.voucherID,
          code: data?.data?.voucherCode,
          maxAmount: data?.data?.voucherMaxAmount,
          minAmount: data?.data?.voucherMinAmount,
          totalValue: data?.data?.voucherTotalValue,
          valueType: data?.data?.voucherDiscountType,
        });
      } else {
        setVoucher(null);
      }
    }
  }, [data]);

  return (
    <>
      <Helmet>
        <title>الفاتورة</title>
      </Helmet>
      <ChildHeader title={"فاتورة الطلب"} />
      <Screen>
        <Space height={20} />
        <Container>
          <PureCard>
            <div className="order-info">
              <Text bold size={18}>
                {parseExpectedTime(order?.expectedDeliveryTime)}
              </Text>
              <Space height={6} />
              <Row>
                <Space width={1} />
                <BalyCalendar size={14} color={"#666"} />
                <Space width={6} />
                <Text size={12} color={"#666"}>
                  {dayjs(order?.expectedDeliveryTime).format("DD-MM-YYYY")}
                </Text>
              </Row>
              <Space height={6} />
              <Row>
                <BalyLocation size={16} />
                <Space width={6} />
                <Text size={12}>
                  {truncate(order?.clientAddress, 50) || ". . . . "}
                </Text>
              </Row>
            </div>
          </PureCard>
          <Space height={22.5} />

          <InvoiceInfo
            loading={isLoading}
            invoice={parseInvoice(order?.orderDetail)}
            order={order}
          />
        </Container>
      </Screen>
    </>
  );
};

export default InvoiceScreen;
