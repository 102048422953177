export const BalySend = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size + 2}
      height={size}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.6667 8L17 15L1 8L17 1L13.6667 8ZM13.6667 8H10"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
