export const BalyVoucherUsage = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.38606 20.3153L6.22788 16.623C6.14209 16.2948 6.17641 15.5891 7 15.3922C7.82359 15.1953 8.20115 15.7204 8.28698 16.0076V16.0076C8.44824 16.4189 9.05909 16.3035 9.05909 15.8617V9.48462C9.05909 9.15642 9.42659 8.5 10.2173 8.5C10.8112 8.5 11.2897 9.15642 11.2468 9.48462V12.8077M11.2468 15.1462V12.8077M11.2468 12.8077C11.3326 12.5616 11.6843 12.0693 12.4049 12.0693C13.1256 12.0693 13.4773 12.5616 13.5631 12.8077V13.3M13.5631 15.1462V13.3M13.5631 13.3C13.606 12.9718 13.8977 12.3154 14.7213 12.3154C15.5449 12.3154 15.8366 12.9718 15.8795 13.3V13.7924M15.8795 15.1462V13.7924M15.8795 13.7924C15.9224 13.4641 16.2141 12.8077 17.0376 12.8077C17.8612 12.8077 18.1529 13.4641 18.1958 13.7924V18.4693C18.1958 19.0847 18.0466 20.8108 16.8112 21.5M10.2173 5.5L10.1953 3M13.1958 6.5L14.1958 4.5M7.19582 6.5L6 4.5"
        stroke="#666666"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};
