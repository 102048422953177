import "./style.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export const PureModalSheet = ({
  isOpen = false,
  onClose,
  bodyStyle,
  children,
  viewHeight,
  borderRadius,
  whithoutBackdrop,
  onSwip = null,
  top = "5vh",
  isTracking = false,
}) => {
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (isOpen && onClose) {
      setModalOpen(true);

      if (!isTracking && !window?.history?.state?.isModal) {
        window.history.pushState(
          { isModal: true },
          null,
          location.pathname + location.search
        );
      }
    } else {
      if (window?.history?.state?.isModal) {
        window.history.replaceState(
          { isModal: false },
          null,
          location.pathname + location.search
        );
      }
    }
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener("popstate", function (event) {
      if (isOpen && onClose && !event?.state?.isModal) {
        onClose();
      }
    });
  }, [isOpen]);

  useEffect(() => {
    if (isTracking) {
      if (viewHeight !== 180) {
        if (isTracking && !window.history.state.isModal) {
          window.history.pushState({ isModal: true }, "", location.pathname);
        }
      } else {
        if (window.history.state.isModal) {
          window.history.replaceState(
            { isModal: false },
            "",
            location.pathname
          );
          window.history.go(-1);
        }
      }
    }
  }, [viewHeight, isTracking]);

  useEffect(() => {
    window.addEventListener("popstate", function (event) {
      if (!event?.state?.isModal) {
        onSwip && onSwip(false);
      }
    });
  }, [viewHeight]);

  return (
    <div className={`pure-modal`}>
      {!whithoutBackdrop && (
        <div
          onClick={() => {
            window.history.go(-1);
          }}
          className={"pure-backdroup"}
          style={{ display: isOpen ? "block" : "none" }}
        ></div>
      )}
      <div
        onClick={(e) => e.stopPropagation()}
        id="sheet-content"
        className={`content ${
          isOpen ? "open-modal" : modalOpen && "close-modal"
        }`}
        style={Object.assign(
          {},
          {
            top: viewHeight ? "auto" : top,
            height: viewHeight ? viewHeight : "auto",
            borderRadius: borderRadius || "16px 16px 0 0",
          },
          bodyStyle
        )}
      >
        {children}
      </div>
    </div>
  );
};
