export const EmptyHomeIcon = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52" cy="52" r="51.5" fill="#F6F6F6" stroke="#F0F0F0" />
    <g clipPath="url(#clip0_761_49978)">
      <path
        d="M59.5 55.877H72.6702C73.4502 55.877 74.2016 56.2043 74.7343 56.7723L88.7741 71.6847C90.5053 73.5235 89.2211 76.5656 86.71 76.5656H18.2898C15.7786 76.5656 14.4945 73.5235 16.2257 71.6847L30.2655 56.7723C30.8077 56.2043 31.5496 55.877 32.3296 55.877"
        stroke="#CCCCCC"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.52 55.877L39.5304 65.6292C39.8633 66.0143 40.2914 66.3031 40.767 66.4668L70.4921 76.5656"
        stroke="#CCCCCC"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.6883 55.877L58.2024 61.0756L51.249 65.9084C50.9542 66.1106 50.6974 66.3705 50.4976 66.6786L48.814 69.2009"
        stroke="#CCCCCC"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.489 61.8169L89.0785 75.0734"
        stroke="#CCCCCC"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.0439 66.2163L15.9214 75.2754"
        stroke="#CCCCCC"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.6437 54.6446C54.1883 56.1753 51.1445 59.3715 49.0518 61.5761C47.8343 62.8565 45.8748 62.8565 44.6573 61.5761C42.6027 59.41 39.6159 56.2716 38.0654 54.6446C33.2048 49.5422 33.2048 41.2629 38.0654 36.1605C42.9261 31.0581 50.7925 31.0581 55.6532 36.1605C60.5138 41.2629 60.5138 49.5422 55.6532 54.6446H55.6437Z"
        stroke="#CCCCCC"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.2016 45.961C51.2016 48.3966 49.2516 50.3702 46.845 50.3702C44.4385 50.3702 42.4885 48.3966 42.4885 45.961C42.4885 43.5253 44.4385 41.5518 46.845 41.5518C49.2516 41.5518 51.2016 43.5253 51.2016 45.961Z"
        stroke="#CCCCCC"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.3498 29.5563C62.3498 25.9365 59.4772 23.0291 55.9006 23.0291C59.4772 23.0291 62.3498 20.1217 62.3498 16.502C62.3498 20.1217 65.2224 23.0291 68.799 23.0291C65.27 23.0291 62.3498 25.9365 62.3498 29.5563Z"
        fill="#CCCCCC"
      />
      <path
        d="M43.5921 19.727C43.5921 17.532 41.8514 15.7702 39.6826 15.7702C41.8514 15.7702 43.5921 14.0085 43.5921 11.8135C43.5921 14.0085 45.3328 15.7702 47.5015 15.7702C45.3328 15.7702 43.5921 17.532 43.5921 19.727Z"
        fill="#CCCCCC"
      />
      <path
        d="M75.9329 36.0932C75.9329 33.8982 74.1922 32.1364 72.0234 32.1364C74.1922 32.1364 75.9329 30.3747 75.9329 28.1797C75.9329 30.3747 77.6736 32.1364 79.8423 32.1364C77.6736 32.1364 75.9329 33.8982 75.9329 36.0932Z"
        fill="#CCCCCC"
      />
      <path
        d="M21.7144 54.3847C21.7144 52.1897 19.9737 50.4279 17.8049 50.4279C19.9737 50.4279 21.7144 48.6662 21.7144 46.4712C21.7144 48.6662 23.4551 50.4279 25.6238 50.4279C23.4551 50.4279 21.7144 52.1897 21.7144 54.3847Z"
        fill="#CCCCCC"
      />
      <path
        d="M27.7924 35.8813C27.7924 32.9292 25.4777 30.5864 22.5608 30.5864C25.4777 30.5864 27.7924 28.2437 27.7924 25.2915C27.7924 28.2437 30.1072 30.5864 33.024 30.5864C30.1072 30.5864 27.7924 32.9969 27.7924 35.8813Z"
        fill="#CCCCCC"
      />
      <path
        d="M68.6942 44.5457C68.6942 43.2039 67.642 42.139 66.3162 42.139C67.642 42.139 68.6942 41.0741 68.6942 39.7322C68.6942 41.0741 69.7463 42.139 71.0722 42.139C69.7463 42.139 68.6942 43.2346 68.6942 44.5457Z"
        fill="#CCCCCC"
      />
      <path
        d="M77.255 52.2475C77.255 50.3689 75.782 48.878 73.9258 48.878C75.782 48.878 77.255 47.3872 77.255 45.5085C77.255 47.3872 78.728 48.878 80.5842 48.878C78.728 48.878 77.255 50.412 77.255 52.2475Z"
        fill="#CCCCCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_761_49978">
        <rect width="77" height="71" fill="white" transform="translate(14 7)" />
      </clipPath>
    </defs>
  </svg>
);
