import "./style.css";

export const Skeleton = () => {
  return (
    <div className="comments-skeleton">
      <div className="app-container">
        <ul>
          {[0, 1, 2, 3].map((el) => (
            <li key={el} className="item">
              <div className="flex-space">
                <div className="pure-skeleton date"></div>
                <div className="pure-skeleton rate"></div>
              </div>
              <div className="pure-skeleton text"></div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
