import "./style.css";
import { AiOutlineClose } from "react-icons/ai";
import Touchable from "../../Global/Ripple";
import Space from "../../Global/Space";
import Text from "../Text";

const ConfirmHeader = ({ onClose, title }) => {
  return (
    <div className={"header"}>
      <div className="app-container app-flex-space">
        <Text bold size={18}>{title}</Text>
        <Space width={10} />
        <Touchable style={{ padding: 10 }} onClick={() => onClose(false)}>
          <AiOutlineClose size={20} />
        </Touchable>
      </div>
    </div>
  );
};

export default ConfirmHeader;
