export const BalyArrowTop = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`-2 -3 16 18`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 10.4444L6 5M6 5L11 10.4444M6 5L6 14"
        stroke="#666666"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 1H11"
        stroke="#666666"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};
