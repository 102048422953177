export const ErrorIcon = ({ size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0608 3.93883L3.93896 16.0607M18.5714 9.9999C18.5714 14.7338 14.7339 18.5713 10 18.5713C5.26615 18.5713 1.42859 14.7338 1.42859 9.9999C1.42859 5.26603 5.26615 1.42847 10 1.42847C14.7339 1.42847 18.5714 5.26603 18.5714 9.9999Z"
        stroke="#FF0000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
