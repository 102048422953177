import { IoClose, IoInformationCircle } from "react-icons/io5";
import "./style.css";
import Touchable from "../../Global/Ripple";
import Text from "../Text";
import Space from "../Space";

export const AlertMessage = ({ isOpen, onClose, title, msg, extra }) => {
  return (
    <div
      className="alert-msg"
      style={{ display: isOpen ? "block" : "none" }}
      onClick={() => onClose(false)}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`content app-container ${isOpen ? "open-close-modal" : ""}`}
      >
        <div className="header">
          <div className="title">
            <IoInformationCircle size={24} />
            <Space width={8} />
            <Text bold size={20} color="#e91e63">
              {title}
            </Text>
          </div>
          <Touchable onClick={() => onClose(false)}>
            <IoClose size={24} />
          </Touchable>
        </div>
        <Text size={18}>{msg}</Text>
        {
          extra && <div className="extra">{extra}</div>
        }
      </div>
    </div>
  );
};
