import Screen from "../components/Global/Screen";
import Space from "../components/Global/Space";
import Container from "../components/Global/Container";
import ChildHeader from "../components/Global/ChildHeader";
import { useNavigate } from "react-router-dom";
import { BalyFilter } from "../components/BalyIcons";
import PureButtonIcon from "../components/Global/ButtonIcon";
import { useAppStore } from "../store";
import ItemGrid from "../components/Global/ItemGrid";
import { useQuery } from "react-query";
import { getFaivorates } from "../services";
import { useEffect } from "react";
import { useState } from "react";
import CatSkeleton from "../components/Categories/Skeleton";
import { Empty } from "../components/Global/Empty";
import { GoToLink } from "../helper";
import ItemsFilter from "../components/Items/Filter";
import AddCTA from "../components/favorites/AddCTA";
import { Helmet } from "react-helmet";

const FaivorateScreen = () => {
  const { setIsFilter, vendorID } = useAppStore();
  const [productList, setProductList] = useState([]);
  const [queryString, setQueryString] = useState("");
  const { addFav } = useAppStore();

  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery(
    [`faivorates-${queryString}${vendorID}`, queryString, vendorID],
    getFaivorates,
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  useEffect(() => {
    if (vendorID) {
      refetch();
    }
  }, [addFav, vendorID, queryString]);

  useEffect(() => {
    if (data?.data && data?.success) {
      setProductList(data?.data);
    } else if (data?.data === null && data?.success) {
      setProductList([]);
    }
  }, [data]);

  return (
    <>
      <Helmet>
        <title>المفضلات</title>
      </Helmet>
      <ChildHeader
        title={"المفضلات"}
        actions={[
          <PureButtonIcon onClick={() => setIsFilter(true)}>
            <BalyFilter size={26} />
          </PureButtonIcon>,
        ]}
      />
      <Screen>
        <Space height={16} />
        <Container>
          {isLoading ? (
            <CatSkeleton />
          ) : productList.length > 0 ? (
            <ItemGrid list={productList} />
          ) : (
            <Empty
              top={"35vh"}
              action={() => GoToLink(() => navigate("/"))}
              actionText="تسوق الان"
              screen="FAIV"
              title="لا توجد منتجات مفضلة"
              msg={
                "قم باضافة المنتجات الى قائمة المفضلات لكي تصل لها في اي وقت"
              }
            />
          )}
        </Container>
      </Screen>
      <AddCTA products={productList} />
      <ItemsFilter
        brandQuery={``}
        isFav
        onFilter={(val) => setQueryString(val)}
      />
    </>
  );
};

export default FaivorateScreen;
