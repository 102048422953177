import { LazyLoadImage } from "react-lazy-load-image-component";
import "./style.css";
import { imgResolution } from "../../../services";
import Touchable from "../Ripple";

export const BannerCard = ({ onClick, img }) => {
  return (
    <div className={"bannar-card"} >
      <Touchable onClick={onClick}>
        <LazyLoadImage
          alt={"bannar-image"}
          height={"100%"}
          src={img?.replace("upload", `upload/w_600/q_${imgResolution}`)} // use normal <img> attributes as props
          width={"100%"}
          effect="opacity"
        />
      </Touchable>
    </div>
  );
};
