import { create } from "zustand";

export const useAppStore = create((set) => ({
  //..........
  isLogin: false,
  isReferralAvailable: false,
  user: null,
  isClientMap: false,
  isMenu: false,
  scrollPosition: 0,
  isSelectProduct: false,
  isCheckVoucher: false,
  isConfirmVaucher: false,
  isFinishModal: true,
  isSplash: true,
  isUserModal: false,
  isAskLogin: false,
  appLoading: true,
  isError: null,
  isOutOfServes: false,
  device: "",
  isSuperApp: false,
  isSuperAppLoading: false,
  isFilter: false,
  isItemModal: false,
  itemDetials: null,
  basket: [],
  isClose: false,
  subCatList: [],
  isAddressAvilabel: false,
  paymentMethod: 1,
  voucher: null,
  vendor: null,
  discountCap: null,
  isPreview: false,
  //search
  querySearch: "",
  searchHistory: [],
  isResult: false,
  //Review
  isReview: false,
  reviewData: null,
  rate: 0,
  reason: [],
  productComment: "",
  trashList: [],
  addFav: false,
  Comment: "",
  isAddFav: false,
  cartAvalibleQTY: [],
  PageLocation: "",
  orderStatus: null,
  serviceFeeInformation: null,
  deepVoucherInfo: null,
  deliveryDiscountInfo: null,
  // Dynamic Vender Id
  vendorID: null,
  orderStatusList: [],
  orderData: [],

  canUserProccedToCheckout: true,
  setCanUserProccedToCheckout: (status) =>
    set({ canUserProccedToCheckout: status }),
  setOrderData: (orderData) => set({ orderData }),
  setOrderStatusList: (orderStatusList) => set({ orderStatusList }),
  setVendorID: (vendorID) => set({ vendorID }),
  setIsReferralAvailable: (status) => set({ isReferralAvailable: status }),
  setDeliveryDiscountInfo: (info) => set({ deliveryDiscountInfo: info }),
  setServiceFeeInformation: (serviceFeeInformation) =>
    set({ serviceFeeInformation }),
  setOrderStatus: (orderStatus) => set({ orderStatus }),
  SetPageLocation: (PageLocation) => set({ PageLocation }),
  setCartAvalibleQTY: (cartAvalibleQTY) => set({ cartAvalibleQTY }),
  setIsAddFav: (isAddFav) => set({ isAddFav }),
  setComment: (Comment) => set({ Comment }),
  setAddFav: (addFav) => set({ addFav }),
  setTrashList: (trashList) => set({ trashList }),
  setProductComment: (productComment) => set({ productComment }),
  setReason: (reason) => set({ reason }),
  setReviewData: (reviewData) => set({ reviewData }),
  setIsReview: (isReview) => set({ isReview }),
  setIsResult: (isResult) => set({ isResult }),
  setSearchHistory: (searchHistory) => set({ searchHistory }),
  setQuerySearch: (querySearch) => set({ querySearch }),
  //-------
  setIsPreview: (isPreview) => set({ isPreview }),
  setDiscountCap: (discountCap) => set({ discountCap }),
  setVendor: (vendor) => set({ vendor }),
  setVoucher: (voucher) => set({ voucher }),
  setPaymentMethod: (paymentMethod) => set({ paymentMethod }),
  setIsAddressAvilabel: (isAddressAvilabel) => set({ isAddressAvilabel }),
  setSubCatList: (subCatList) => set({ subCatList }),
  setIsClose: (isClose) => set({ isClose }),
  setBasket: (basket) => set({ basket }),
  clearBasket: () => set({ basket: [] }),
  setItemDetials: (itemDetials) => set({ itemDetials }),
  setIsItemModal: (isItemModal) => set({ isItemModal }),
  setIsFilter: (isFilter) => set({ isFilter }),
  setIsSuperApp: (isSuperApp) => set({ isSuperApp }),
  setIsSuperAppLoading: (isSuperAppLoading) => set({ isSuperAppLoading }),
  setDevice: (device) => set({ device }),
  setIsMenu: (isMenu) => set({ isMenu }),
  setIsClientMap: (isClientMap) => set({ isClientMap }),
  setIsLogin: (isLogin) => set({ isLogin }),
  //...........
  setQueryStringFilter: (queryStringFilter) => set({ queryStringFilter }),
  setIsOutOfServes: (isOutOfServes) => set({ isOutOfServes }),
  setRate: (rate) => set({ rate }),
  setDeepVoucher: (deepVoucher) => set({ deepVoucher }),
  setDeepVoucherInfo: (deepVoucherInfo) => set({ deepVoucherInfo }),
  setAppLoading: (appLoading) => set({ appLoading }),
  setIsAskLogin: (isAskLogin) => set({ isAskLogin }),
  setUser: (user) => set({ user }),
  setIsSplash: (isSplash) => set({ isSplash }),
  setIsUserModal: (isUserModal) => set({ isUserModal }),
  setIsFinishModal: (isFinishModal) => set({ isFinishModal }),
  setIsConfirmVaucher: (isConfirmVaucher) => set({ isConfirmVaucher }),
  setIsCheckVoucher: (isCheckVoucher) => set({ isCheckVoucher }),
  setIsSelectProduct: (isSelectProduct) => set({ isSelectProduct }),
  setScrollPosition: (scrollPosition) => set({ scrollPosition }),

  setIsError: (isError) => set({ isError }),
  //data Store
  appClose: false,
  appCloseMsg: "بلي راجعلكم",
  currentLocation: null,
  selectedAddress: null,
  isFreeLocation: false,
  realLocation: null,
  setRealLocation: (realLocation) => set({ realLocation }),
  setAppClose: (appClose) => set({ appClose }),
  setAppCloseMsg: (appCloseMsg) => set({ appCloseMsg }),
  setIsFreeLocation: (isFreeLocation) => set({ isFreeLocation }),
  setSelectedAddress: (selectedAddress) => set({ selectedAddress }),
  setCurrentLocation: (currentLocation) => set({ currentLocation }),

  //voucher center
  isVoucherCenterModal: false,
  setIsVoucherCenterModal: (isVoucherCenterModal) =>
    set({ isVoucherCenterModal }),
  voucherCenterInfo: null,
  setVoucherCenterInfo: (voucherCenterInfo) => set({ voucherCenterInfo }),
  isVisitVoucherCenter: false,
  setIsVisitVoucherCenter: (isVisitVoucherCenter) =>
    set({ isVisitVoucherCenter }),

  vouchersAvilable: [],
  setVouchersAvilable: (vouchersAvilable) => set({ vouchersAvilable }),
  voucherCenterNotification: false,
  setVoucherCenterNotification: (vouchersIDs = []) => {
    let value = false;
    let vouchersHistory = window.localStorage.getItem(
      "grocery_vouchers_history"
    );
    if (vouchersHistory) {
      vouchersHistory = JSON.parse(vouchersHistory);
    } else {
      vouchersHistory = [];
    }

    let isID;
    vouchersIDs?.map((voucherID) => {
      isID = vouchersHistory?.find((id) => id === voucherID);
      if (!isID) {
        value = true;
      }
    });

    set({ voucherCenterNotification: value });
  },

  //logout
  setLogout: () =>
    set({
      isLogin: false,
      selectedAddress: null,
      user: null,
      currentLocation: null,
      basket: null,
    }),

  shouldRefetchVendorID: true,
  setShouldRefetchVendorId: (shouldRefetchVendorID) =>
    set({ shouldRefetchVendorID }),

  clear: () => {
    set({
      basket: [],
      currentLocation: null,
      Comment: "",
    });
  },

  isSameLocation: false,
  setIsSameLocation: (isSameLocation) => set({ isSameLocation }),

  notSameLocationModal: false,
  setNotSameLocationModal: (notSameLocationModal) => {
    set({ notSameLocationModal });
  },

  addressPopoverConfirmation: true,
  setAddressPopoverConfirmation: (addressPopoverConfirmation) => {
    set({ addressPopoverConfirmation });
  },

  placeOrderWithAddressWarning: false,
  setPlaceOrderWithAddressWarning: (placeOrderWithAddressWarning) => {
    set({ placeOrderWithAddressWarning });
  },
}));
