export const parseInvoice = (orderDetail) => {
  let items =
    orderDetail?.map((el) => {
      return {
        qty: el.qty,
        id: el?.id,
        item: {
          id: el?.product?.id,
          vID: el?.product?.id,
          name: el?.product?.name,
          product: el?.product?.product,
          price: el?.product?.price || 0,
          endprice: el?.price,
          imgs: JSON.parse(el?.product?.img),
          size: `${el?.product?.size} ${
            !!el?.product?.unit ? el?.product?.unit : ""
          }`,
        },
      };
    }) || [];
  return items;
};
