import "./style.css";

export const Row = ({ children, gap = 0, alignItems = "start" }) => {
  return (
    <div className="pure-row" style={{ gap, alignItems }}>
      {children}
    </div>
  );
};

export const Col = ({ children, flex = 1 }) => {
  return (
    <div className="pure-col" style={{ flex: flex }}>
      {children}
    </div>
  );
};
