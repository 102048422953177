/**
 * Calculate service fee price
 * @param {Object} serviceFeeInformation an object contains service fee information (value, max, min)
 * @param {*} total Order total price
 * @returns {Number} service fee price
 */
export const getServiceFeeValue = (serviceFeeInformation, total) => {
  let serviceFeeValue = 0;
  if (serviceFeeInformation) {
    serviceFeeValue = serviceFeeInformation?.value;
  }

  let serviceFeePrice = Math.ceil(((total * serviceFeeValue) / 100) / 250) * 250;

  if (serviceFeePrice > serviceFeeInformation?.max) {
    serviceFeePrice = serviceFeeInformation?.max;
  } else if (serviceFeePrice < serviceFeeInformation?.min) {
    serviceFeePrice = serviceFeeInformation?.min;
  }

  return serviceFeePrice;
};
