import "./style.css";
import { PureModalSheet } from "../../Global/PureModalSheet";
import PureButtonIcon from "../../Global/ButtonIcon";
import { BalyClose, BalyHeart } from "../../BalyIcons";
import Container from "../../Global/Container";
import Space from "../../Global/Space";
import { useAppStore } from "../../../store";
import Slider from "../../Global/Slider";
import bagImg from "../../../assets/images/empty2.jpg";
import { getProduct, addFaivorate } from "../../../services";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Text from "../../Global/Text";
import { Row } from "../../Global/Grid";
import ItemBannerList from "../../Global/ItemBannerList";
import PureButton from "../../Global/Button";
import QtButton from "../../Global/QtButton";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import ItemsBannerSkeleton from "../../Global/ItemBannerList/Skeleton";
import { toast } from "react-hot-toast";
import queryClient from "../../../queryClient";
import ga4 from "react-ga4";
import { WebengageForAddRemove } from "../../../helper/AddRemoveItem";
import { getPrices } from "../../Global/Invoice";
export const handleBasket = ({ basket, item, qty, isNew = false, cb }) => {
  let basketObj = basket?.find((el) => el?.item?.vID === item?.vID);
  let basketIndex = basket?.findIndex((el) => el?.item?.vID === item?.vID);
  if (basketObj) {
    if (qty === 0) {
      cb(basket?.filter((el) => el?.item?.vID !== item?.vID));
    } else {
      let newBasket = [...basket];
      newBasket?.splice(basketIndex, 1, {
        ...basketObj,
        qty: isNew ? basketObj.qty + qty : qty,
      });
      cb(newBasket);
    }
  } else {
    if (qty === 0) {
      cb(basket?.filter((el) => el?.item?.vID !== item?.vID));
    } else {
      cb([
        ...basket,
        {
          item,
          qty,
        },
      ]);
    }
  }
};

const ItemHeartIcon = ({ id, productVariationID, fav }) => {
  const { setAddFav, itemDetials, setItemDetials, isLogin } = useAppStore();
  useEffect(() => {
    setAddFav(fav);
  }, [fav]);

  const { mutate, isLoading } = useMutation(addFaivorate, {
    onSuccess: (result) => {
      if (result.success) {
        queryClient.invalidateQueries(`product-${id}`);
        if (window.location.pathname === "/faivorate") {
          queryClient.invalidateQueries("faivorates");
        }
        setItemDetials({
          ...itemDetials,

          favItem: !itemDetials?.favItem,
        });
      } else {
        toast.error(result.err);
      }
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const handleFaiv = () => {
    mutate({
      fav_product: String(productVariationID),
    });
  };

  return (
    <div className="">
      <div className={`item-image-fav ${isLoading ? "loading-icon" : null}`}>
        {isLogin && (
          <PureButtonIcon onClick={handleFaiv}>
            {fav ? (
              <BalyHeart fill color={"#ff0000"} size={20} />
            ) : (
              <BalyHeart color={"#808080"} size={20} />
            )}
          </PureButtonIcon>
        )}
      </div>
    </div>
  );
};

const ItemImg = ({ img }) => {
  return (
    <div className="item-image">
      <LazyLoadImage
        alt={"bannar-image"}
        height={"100%"}
        src={img}
        width={"100%"}
        effect="opacity"
      />
    </div>
  );
};

const ButtonCTA = ({ disabled = false, qty, setQty }) => {
  const { setIsItemModal, setItemDetials, itemDetials, basket, setBasket } =
    useAppStore();

  const getTotalPrice = () => {
    let totalPrice = itemDetials?.price;
    let discount = 0;
    if (itemDetials?.discount) {
      discount = (totalPrice * itemDetials?.discount) / 100;
    }
    let totalWithDiscount = totalPrice - discount;
    return `اضافة - ${Number(totalWithDiscount * qty).toLocaleString(
      "en"
    )} د.ع`;
  };

  return (
    <div className="item-modal-cta">
      <Container>
        <PureButton
          block
          type="primary"
          radius={24}
          onClick={() => {
            handleBasket({
              isNew: true,
              basket,
              item: itemDetials,
              qty,
              cb: (newBasket) => {
                setBasket(newBasket);
                localStorage.setItem(
                  "grocery_basket",
                  JSON.stringify(newBasket)
                );
              },
            });
            setQty(1);
            setIsItemModal(false);
            setItemDetials(null);
            window.history.go(-1);
          }}
          disabled={disabled}
        >
          <Text bold color="#fff" size={18}>
            {getTotalPrice()}
          </Text>
        </PureButton>
      </Container>
    </div>
  );
};
const QtyComp = ({ qty, setQty }) => {
  const { itemDetials, basket, appClose, isLogin, isSuperApp, PageLocation } =
    useAppStore();
  const { subTotal } = getPrices(basket);
  return (
    <div className="item-modal-qty-comp">
      <Container>
        <QtButton
          value={qty}
          disabledDecrease={qty === 1}
          disabledIncrease={appClose}
          onChange={(val, option) => {
            setQty(val);
            let currentQty =
              basket?.find((el) => el?.item?.vID === itemDetials?.vID) || 0;
            const newQty = val + currentQty?.qty;
            if (currentQty !== 0) {
              if (newQty > itemDetials?.availableQty) {
                setQty(qty);
                toast.error("عذرا، نفذت الكمية!");
              } else {
                setQty(val);
              }
            } else {
              if (val > itemDetials?.availableQty) {
                toast.error("عذرا، نفذت الكمية!");
                setQty(qty);
              } else {
                setQty(val);
              }
            }
            let addReomve = option === "-" ? "remove" : "add";
            WebengageForAddRemove(
              itemDetials,
              addReomve,
              val,
              isLogin,
              isSuperApp,
              subTotal,
              PageLocation
            );
          }}
        />
      </Container>
    </div>
  );
};

const ItemDetials = () => {
  const {
    isItemModal,
    setIsItemModal,
    itemDetials,
    setItemDetials,
    appClose,
    vendorID,
  } = useAppStore();
  const [similarItems, setSimilarItems] = useState([]);
  const [variation, setVariation] = useState([]);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [qty, setQty] = useState(1);

  const { data, isLoading } = useQuery(
    [`product-${itemDetials?.id}`, itemDetials?.id, vendorID],
    getProduct,
    {
      refetchOnWindowFocus: false,
      enabled: !!itemDetials,
    }
  );

  useEffect(() => {
    if (!isItemModal) {
      setQty(1);
    }
  }, [isItemModal]);

  useEffect(() => {
    if (data?.err === "unauthorized") {
      setSimilarItems([]);
      setVariation([]);
      setSelectedVariation(null);
    } else if (data?.data && data?.success) {
      setSimilarItems(data?.data?.similar);
      setVariation(data?.data?.variation);
      let enalbledVar = data?.data?.variation?.filter((el) => el?.status);

      setSelectedVariation(
        enalbledVar.find((el) => el?.id === itemDetials?.id) || null
      );
    }
  }, [data]);

  useEffect(() => {
    if (selectedVariation) {
      setItemDetials({
        ...itemDetials,
        name: selectedVariation.name,
        productVariationID: selectedVariation.productVariationID,
        vID: selectedVariation?.id,
        size: String(
          `${!!selectedVariation?.size ? selectedVariation?.size : ""} ${
            !!selectedVariation?.unit ? selectedVariation?.unit : ""
          }`
        ).trim(),
        price: selectedVariation?.price,
        discount: selectedVariation?.discount,
        discountType: selectedVariation?.discountType,
        endprice: selectedVariation?.endprice,
        imgs: selectedVariation?.img ? JSON.parse(selectedVariation?.img) : [],
        favItem: selectedVariation?.fav,
      });
    }
  }, [selectedVariation]);

  return (
    <PureModalSheet
      isOpen={isItemModal}
      onClose={() => {
        setIsItemModal(false);
        setItemDetials(null);
        setSelectedVariation(null);
      }}
    >
      <div className="item-detials">
        <Space height={20} />
        <Container>
          <div className="flex-space">
            <Space width={10} />
            <PureButtonIcon
              onClick={() => {
                setIsItemModal(false);
                setItemDetials(null);
                window.history.go(-1);
              }}
            >
              <BalyClose size={24} />
            </PureButtonIcon>
          </div>
        </Container>
        <div className="item-scroll">
          <div className="item-header-overlay"></div>
          <Space height={10} />
          {itemDetials?.imgs?.length === 0 ? (
            <ItemImg img={bagImg} />
          ) : (
            <Slider
              banners
              items={itemDetials?.imgs?.map((img) => (
                <ItemImg img={img} />
              ))}
            />
          )}
          <Space height={16} />
          <Container>
            <div className="item-icon-text">
              <div>
                <Text bold size={16}>
                  {`${itemDetials?.product} ${itemDetials?.name || ""}`}
                </Text>
              </div>
              <div>
                <ItemHeartIcon
                  id={itemDetials?.id}
                  productVariationID={itemDetials?.productVariationID}
                  fav={itemDetials?.favItem}
                />
              </div>
            </div>

            <div className="detials-price">
              <Text paddingLeft={5} size={16}>{`${Number(
                itemDetials?.endprice
              ).toLocaleString("en")} د.ع`}</Text>
              {itemDetials?.price > itemDetials?.endprice && (
                <Row alignItems="center">
                  <Text lineThrough color="#666" size={16}>{`${Number(
                    itemDetials?.price
                  ).toLocaleString("en")} د.ع`}</Text>
                  <Space width={8} />
                  <div className="detials-discount">
                    {`% ${itemDetials?.discount}`}
                  </div>
                </Row>
              )}
            </div>
            <Space height={4} />
            <Text color="#666" size={14}>
              {itemDetials?.description}
            </Text>
            <Space height={16} />
            <Row alignItems="center">
              <div className="row-item-detail">
                <Text size={14}>اختر الفئة</Text>
                <Space width={8} />
                {isLoading ? (
                  <div className="detials-cat-tags">
                    {[60, 80, 70].map((el) => (
                      <div
                        key={el}
                        className="detials-cat-tag-sk pure-skeleton"
                        style={{ width: el }}
                      ></div>
                    ))}
                  </div>
                ) : (
                  <div className="detials-cat-tags">
                    {variation?.map((el, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          el?.status && setSelectedVariation(el);

                          if (
                            !selectedVariation?.productVariationID ||
                            el?.productVariationID !=
                              selectedVariation?.productVariationID
                          ) {
                            setQty(1);
                          }

                          ga4.event("Grocery Select Variation", {
                            productID: el?.id,
                            category: el?.category,
                            itemName: el?.product,
                            price: el?.endprice,
                            vendorID: vendorID,
                          });
                          if (window && window.webengage)
                            window.webengage.track("Grocery Select Variation", {
                              productID: el?.id,
                              category: el?.category,
                              itemName: el?.product,
                              price: el?.endprice,
                              vendorID: vendorID,
                            });
                        }}
                        className={`detials-cat-tag 
                      ${
                        selectedVariation?.id === el?.id &&
                        "detials-cat-tag-active"
                      }
                      ${!el?.status && "detials-cat-disable"}
                      `}
                      >
                        {`${el.name} ${el?.size} ${!!el?.unit ? el?.unit : ""}`}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Row>

            <Space height={16} />
            <QtyComp qty={qty} setQty={setQty} />

            <Space height={16} />
          </Container>
          <Space height={8} />
          {isLoading ? (
            <Container>
              <ItemsBannerSkeleton />
            </Container>
          ) : (
            similarItems.length > 0 && (
              <ItemBannerList title="منتجات مشابهه" list={similarItems} />
            )
          )}
          <Space height={104} />
          <div className="item-footer-overlay"></div>
        </div>
        <ButtonCTA
          disabled={!selectedVariation || appClose}
          qty={qty}
          setQty={setQty}
        />
      </div>
    </PureModalSheet>
  );
};

export default ItemDetials;
