export const BalyHeart = ({ fill=false, size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.31802 2.31802C0.56066 4.07538 0.56066 6.9246 2.31802 8.682L10.0001 16.364L17.682 8.682C19.4393 6.9246 19.4393 4.07538 17.682 2.31802C15.9246 0.56066 13.0754 0.56066 11.318 2.31802L10.0001 3.63609L8.682 2.31802C6.92462 0.56066 4.07538 0.56066 2.31802 2.31802Z"
        stroke={color}
        fill={fill ? color : "transparent"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
