import "./style.css";
import { useEffect, useState } from "react";
import PureButtonIcon from "../../Global/ButtonIcon";
import { BalyFilter } from "../../BalyIcons";
import { useAppStore } from "../../../store";
import { ScrollTo } from "../../../helper";

export const CatFilter = ({ tag, setTag }) => {
  const { setIsFilter, subCatList } = useAppStore();
  const [selected, setSelected] = useState(subCatList[0]?.id);

  useEffect(() => {
    setSelected(subCatList[0]?.id);
  }, [subCatList]);

  useEffect(() => {
    if (tag) setSelected(tag);
  }, [tag]);

  const handleSelecteTag = (id) => {
    let container = document.getElementsByClassName("screen-container")[0];
    let elem = document.querySelector(`#group-${id}`);
    if (elem) {
      var topPos = elem.offsetTop;
      setTag(null);
      setSelected(id);
      ScrollTo(container, topPos - 160, 400);
    }
  };

  return (
    <div className="cat-filter">
      <div className="cat-filter-action">
        <PureButtonIcon
          background="#eee"
          radius={"50%"}
          margin={0}
          onClick={() => setIsFilter(true)}
        >
          <BalyFilter size={20} />
        </PureButtonIcon>
      </div>
      <div className="cat-filter-list">
        {subCatList?.map((el, i) => (
          <div
            key={i}
            id={`#tag-${el?.id}`}
            className={`cat-filter-tag ${
              selected === el?.id ? "cat-filter-tag-active" : ""
            }`}
            onClick={() => handleSelecteTag(el?.id)}
          >
            {el?.name}
          </div>
        ))}
      </div>
    </div>
  );
};
