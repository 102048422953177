export const EmptyFaivIcon = () => (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="52" cy="52" r="51.5" fill="#F6F6F6" stroke="#F0F0F0" />
        <path d="M74.4492 29.0544C74.4492 25.4346 71.5765 22.5272 68 22.5272C71.5765 22.5272 74.4492 19.6198 74.4492 16C74.4492 19.6198 77.3218 22.5272 80.8983 22.5272C77.3694 22.5272 74.4492 25.4346 74.4492 29.0544Z" fill="#CCCCCC" />
        <path d="M44.5911 20.7271C44.5911 18.5321 42.8504 16.7703 40.6816 16.7703C42.8504 16.7703 44.5911 15.0086 44.5911 12.8136C44.5911 15.0086 46.3318 16.7703 48.5005 16.7703C46.3318 16.7703 44.5911 18.5321 44.5911 20.7271Z" fill="#CCCCCC" />
        <path d="M42.9095 35.9135C42.9095 33.7185 41.1687 31.9567 39 31.9567C41.1687 31.9567 42.9095 30.195 42.9095 28C42.9095 30.195 44.6502 31.9567 46.8189 31.9567C44.6502 31.9567 42.9095 33.7185 42.9095 35.9135Z" fill="#CCCCCC" />
        <path d="M22.7141 55.3847C22.7141 53.1897 20.9734 51.4279 18.8047 51.4279C20.9734 51.4279 22.7141 49.6662 22.7141 47.4712C22.7141 49.6662 24.4548 51.4279 26.6236 51.4279C24.4548 51.4279 22.7141 53.1897 22.7141 55.3847Z" fill="#CCCCCC" />
        <path d="M28.7922 36.8813C28.7922 33.9292 26.4774 31.5864 23.5605 31.5864C26.4774 31.5864 28.7922 29.2437 28.7922 26.2915C28.7922 29.2437 31.1069 31.5864 34.0238 31.5864C31.1069 31.5864 28.7922 33.9969 28.7922 36.8813Z" fill="#CCCCCC" />
        <g clipPath="url(#clip0_2804_8765)">
            <path d="M55.7856 27.5022C53.5607 28.8543 52.8533 31.754 54.2054 33.979L60.1162 43.7046L69.8418 37.7939C72.0666 36.4417 72.7741 33.5419 71.422 31.3171C70.0698 29.0922 67.1701 28.3847 64.9451 29.7369L63.2766 30.7511L62.2624 29.0824C60.9102 26.8575 58.0105 26.15 55.7856 27.5022Z" stroke="#CCCCCC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <path d="M34.378 69.8136C34.378 68.4717 33.3258 67.4068 32 67.4068C33.3258 67.4068 34.378 66.3419 34.378 65C34.378 66.3419 35.4302 67.4068 36.756 67.4068C35.4302 67.4068 34.378 68.5024 34.378 69.8136Z" fill="#CCCCCC" />
        <path d="M78.255 53.2474C78.255 51.3688 76.782 49.8779 74.9258 49.8779C76.782 49.8779 78.255 48.3871 78.255 46.5084C78.255 48.3871 79.728 49.8779 81.5842 49.8779C79.728 49.8779 78.255 51.4119 78.255 53.2474Z" fill="#CCCCCC" />
        <path d="M70.6673 49.0384V78.1272C70.6673 81.0482 68.2796 83.4161 65.334 83.4161H38.6673C35.7218 83.4161 33.334 81.0482 33.334 78.1272V41.4161L42.6673 46.705L52.0007 41.4161L61.334 51.6828L70.6673 49.0384Z" stroke="#CCCCCC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M47.8032 49.493C47.8539 48.5256 49.1129 48.1883 49.6405 49.0007L51.6506 52.0959C51.8865 52.4593 52.3296 52.6294 52.7481 52.5172L56.3129 51.562C57.2487 51.3113 57.9585 52.4044 57.3489 53.1573L55.0263 56.0254C54.7537 56.3621 54.7288 56.8361 54.9648 57.1994L56.9749 60.2946C57.5025 61.1071 56.6822 62.12 55.7778 61.7728L52.3323 60.4502C51.9279 60.295 51.4694 60.4178 51.1968 60.7545L48.8742 63.6227C48.2646 64.3755 47.0477 63.9084 47.0984 62.941L47.2916 59.2554C47.3143 58.8228 47.0558 58.4248 46.6513 58.2695L43.2058 56.9469C42.3014 56.5997 42.3697 55.2981 43.3054 55.0474L46.8703 54.0922C47.2887 53.9801 47.5874 53.6112 47.6101 53.1786L47.8032 49.493Z" fill="#CCCCCC" />
        <rect x="38" y="69.1791" width="10.3158" height="10.3158" rx="1" transform="rotate(-25.4313 38 69.1791)" fill="#CCCCCC" />
        <path d="M66.434 74.0116L58.0913 65.9449C57.5486 65.4201 56.6424 65.663 56.4348 66.3888L53.2431 77.5461C53.0293 78.2934 53.7125 78.9882 54.4633 78.7871L65.9977 75.6964C66.7486 75.4952 66.9928 74.5519 66.434 74.0116Z" fill="#CCCCCC" />
        <defs>
            <clipPath id="clip0_2804_8765">
                <rect width="25.142" height="25.142" fill="white" transform="translate(52.959 20) rotate(13.7111)" />
            </clipPath>
        </defs>
    </svg>

);
