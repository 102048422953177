import Screen from "../components/Global/Screen";
import MainHeader from "../components/Global/MainHeader";
import SearchBox from "../components/Global/SearchBox";
import Space from "../components/Global/Space";
import Container from "../components/Global/Container";
import { CategoryList } from "../components/Categories";
import { useQuery } from "react-query";
import { getCatogeries } from "../services";
import CatSkeleton from "../components/Categories/Skeleton";
import { useAppStore } from "../store";
import { Helmet } from "react-helmet";

const CategoriesScreen = () => {
  const { vendorID } = useAppStore();

  const { data, isLoading } = useQuery(
    ["categories", vendorID],
    getCatogeries,
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <Helmet>
        <title>التصنيفات</title>
      </Helmet>
      <MainHeader />
      <Screen>
        <Space height={16} />
        <Container>
          <SearchBox />
          <Space height={26} />
          {isLoading ? (
            <CatSkeleton />
          ) : (
            <CategoryList list={data?.data || []} />
          )}
        </Container>

        <Space height={80} />
      </Screen>
    </>
  );
};

export default CategoriesScreen;
