import "./style.css";
import Text from "../../Global/Text";
import PureButton from "../../Global/Button";
import Container from "../../Global/Container";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../../store";
import ProgressBar from "../../Global/ProgressBar";
import { getPrices } from "../../Global/Invoice";
import ga4 from "react-ga4";
import { IoInformationCircleOutline } from "react-icons/io5";
import { PureModalSheet } from "../../Global/PureModalSheet";
import { useState } from "react";
import { InfoModal } from "../../Global/InfoModal/infoModal";

export const CartCTA = () => {
  const [showDeliveryDiscountInfo, setShowDeliveryDiscountInfo] =
    useState(false);
  let navigate = useNavigate();
  const {
    user,
    setIsUserModal,
    setIsAskLogin,
    selectedAddress,
    setIsClientMap,
    vendor,
    basket,
    voucher,
    appClose,
    deliveryDiscountInfo,
  } = useAppStore();

  let { total, voucherValue } = getPrices(basket, voucher, vendor);

  const handleContinue = () => {
    if (user) {
      if (user?.firstName) {
        if (selectedAddress) {
          navigate("/checkout");
        } else {
          setIsClientMap(true);
        }
      } else {
        setIsUserModal(true);
      }
    } else {
      setIsAskLogin(true);
    }

    ga4.event("Grocery Submit Cart", {
      total_cart_value: total,
      total_cart_items: basket?.length,
    });
    if (window && window.webengage)
      window.webengage.track("Grocery Cart Review", {
        total_cart_value: total,
        total_cart_items: basket?.length,
      });
  };

  const showInfoModal = () => {
    setShowDeliveryDiscountInfo(true);
  };

  return (
    <>
      <div className="cart-cta">
        {deliveryDiscountInfo?.Discount > 0 && (
          <div
            style={{
              borderTop: "1px solid #F2F2F2",
              width: "100%",
              padding: "8px 24px 4px 24px ",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 4,
              }}
              onClick={showInfoModal}
            >
              <IoInformationCircleOutline size={24} color="#9A9A9A" />
              {total + voucherValue < deliveryDiscountInfo?.Minamount ? (
                <Text size={16}>
                  {deliveryDiscountInfo?.Discount < 100
                    ? `اضف المزيد بقيمة ${Number(
                        deliveryDiscountInfo?.Minamount - (total + voucherValue)
                      ).toLocaleString("en")} د.ع لخصم التوصيل`
                    : `اضف المزيد بقيمة ${Number(
                        deliveryDiscountInfo?.Minamount - (total + voucherValue)
                      ).toLocaleString("en")} د.ع للتوصيل المجاني`}
                </Text>
              ) : (
                <Text size={16}>
                  {deliveryDiscountInfo?.Discount < 100
                    ? `حصلت على خصم ${deliveryDiscountInfo?.Discount}% من قيمة التوصيل 🎉`
                    : `حصلت على توصيل مجاني 🎉`}
                </Text>
              )}
            </div>
            <ProgressBar
              value={total + voucherValue}
              max={deliveryDiscountInfo?.Minamount}
            />
          </div>
        )}
        <Container>
          {vendor?.discountCap?.minamount !== 0 &&
            total < vendor?.discountCap?.minamount && (
              <div style={{ padding: 8 }}>
                <p className="discount-msg">{`اضف المزيد بقيمة ${Number(
                  vendor?.discountCap?.minamount
                ).toLocaleString("en")} د.ع لتحصل على الخصم`}</p>
                <ProgressBar
                  value={total}
                  max={vendor?.discountCap?.minamount}
                />
              </div>
            )}
          <PureButton
            disabled={appClose}
            type="primary"
            block
            radius={24}
            onClick={handleContinue}
          >
            <Text bold size={18} color="#fff">
              {appClose ? "التطبيق مغلق حالياَ" : "متابعة"}
            </Text>
          </PureButton>
        </Container>
      </div>
      <InfoModal
        isOpen={showDeliveryDiscountInfo}
        onClose={() => setShowDeliveryDiscountInfo(false)}
        title={
          deliveryDiscountInfo?.Discount < 100
            ? `حصل خصم ${deliveryDiscountInfo.Discount}٪ على التوصيل`
            : `احصل على توصيل مجاني`
        }
        height={70}
      >
        <Text size={14}>
          ضيف طلبات الك ولأحبابك حتى توصل{" "}
          {Number(deliveryDiscountInfo?.Minamount).toLocaleString("en")} د.ع
          وحصل على{" "}
          {deliveryDiscountInfo?.Discount < 100
            ? " خصم  " + deliveryDiscountInfo?.Discount + "% من قيمة التوصيل "
            : " توصيل مجاني "}
          من بلي مارت
        </Text>
      </InfoModal>
    </>
  );
};
