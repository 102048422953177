import "./style.css";
import { EmptyIcon } from "./icons";
import { EmptyCartIcon } from "./cartIcon";
import { EmptyHomeIcon } from "./homeIcon";
import { EmptyCommentsIcon } from "./comments";
import { EmptySearchIcon } from "./searchIcon";
import { EmptyFaivIcon } from "./faivIcon";
import Space from "../Space";
import PureButton from "../Button";

export const Empty = ({
  title,
  msg,
  icon,
  action,
  actionText,
  screen = "DEFAULT",
  top = 0,
  width = "auto",
}) => {
  const icons = {
    DEFAULT: <EmptyIcon />,
    CART: <EmptyCartIcon />,
    HOME: <EmptyHomeIcon />,
    FAIV: <EmptyFaivIcon />,
    COMMENTS: <EmptyCommentsIcon />,
    SEARCH: <EmptySearchIcon />,
  };
  return (
    <div className="empty" style={{ marginTop: top, width }}>
      {icon || icons[screen]}
      {title && (
        <p
          className="title"
          style={{
            maxWidth: width !== "auto" ? width : 220,
          }}
        >
          {title}
        </p>
      )}
      <Space height={8} />
      {msg ? (
        <p
          style={{
            maxWidth: width !== "auto" ? width : 220,
          }}
        >
          {msg}
        </p>
      ) : (
        <p>لم يتم العثور على نتائج لهذا البحث.</p>
      )}
      <Space height={22} />
      {action && (
        <PureButton type="secondary" onClick={action}>
          {actionText}
        </PureButton>
      )}
    </div>
  );
};
