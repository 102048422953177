import { useAppStore } from "../../../store";
import "./style.css";
import { useNavigate } from "react-router-dom";
const Text = ({
  children,
  size = 14,
  color = "#000",
  bold = false,
  lineThrough = false,
  link = null,
  ellipsis = false,
  width,
  height,
  paddingLeft,
  whiteSpace = "nowrap",
  refetchVendorOnRedirect = true,
}) => {
  const navigate = useNavigate();
  const { setShouldRefetchVendorId } = useAppStore();

  const ellipsisStyle = {
    textOverflow: "ellipsis",
    whiteSpace,
    overflow: "hidden",
    width,
    maxWidth: 500,
  };

  return link ? (
    <a
      className="pure-text pure-text-link responsive-text"
      style={{
        fontSize: size,
        color,
        fontWeight: bold ? "bold" : "normal",
        textDecorationLine: lineThrough ? "line-through" : "none",
      }}
      onClick={() => {
        setShouldRefetchVendorId(refetchVendorOnRedirect);
        navigate(link);
      }}
    >
      {children}
    </a>
  ) : (
    <p
      className="pure-text responsive-text"
      style={{
        fontSize: size,
        color: color,
        fontWeight: bold ? "bold" : "normal",
        textDecorationLine: lineThrough ? "line-through" : "none",
        ...(ellipsis && { ...ellipsisStyle }),
        paddingLeft: paddingLeft ? paddingLeft : 0,
        height: height ? height : "auto",
      }}
    >
      {children}
    </p>
  );
};

export default Text;
