import "./style.css";
import Touchable from "../Ripple";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Text from "../Text";
import Space from "../Space";
import bagImg from "../../../assets/images/empty2.jpg";
import QtButton from "../QtButton";
import { useAppStore } from "../../../store";
import { handleBasket } from "../../Items/Detials";
import { toast } from "react-hot-toast";
import { WebengageForAddRemove } from "../../../helper/AddRemoveItem";
import ga4 from "react-ga4";
import Truncate from "react-truncate";
import { getPrices } from "../Invoice";

const ItemCard = ({
  id,
  productID,
  imgs,
  name,
  description,
  size,
  price,
  endprice,
  discount = 0,
  count,
  product,
  availableQty,
  width = "100%",
  status,
}) => {
  const {
    setIsItemModal,
    setItemDetials,
    basket,
    setBasket,
    appClose,
    isLogin,
    isSuperApp,
    PageLocation,
  } = useAppStore();
  const item = {
    id,
    productID,
    imgs,
    name,
    product,
    description,
    size,
    price,
    endprice,
    discount,
    count,
    availableQty,
    status,
  };
  const { subTotal } = getPrices(basket);
  const getInfoFromBasket = () => {
    let totalQty = 0;
    let lastQty = 0;
    let vID = null;
    let basketObj = basket?.filter((el) => el?.item?.id === id);
    if (basketObj?.length > 0) {
      totalQty = basketObj?.map((el) => el?.qty).reduce((a, b) => a + b, 0);
      lastQty = basketObj[basketObj?.length - 1]?.qty;
      vID = basketObj[basketObj?.length - 1]?.item?.vID;
    }
    return { totalQty, lastQty, vID };
  };

  const checkItemExist = () => {
    let basketObj = basket?.find((el) => el?.item?.id === id);
    return basketObj ? true : false;
  };
  return (
    <div
      className="item-card"
      style={
        availableQty === 0
          ? { opacity: "0.5", width, pointerEvents: "none" }
          : { width }
      }
    >
      <div className="item-card-img">
        {discount !== 0 && (
          <div className="discount-label">{`${discount} %`}</div>
        )}
        <div className="qty-card">
          <QtButton
            small
            disabledIncrease={appClose}
            value={getInfoFromBasket().lastQty}
            label={getInfoFromBasket().totalQty}
            increaseColor={"blue"}
            onChange={(value, option) => {
              if (value > item?.availableQty) {
                toast.error("عذرا، نفذت الكمية!");
              } else if ((option === "-" && checkItemExist()) || count === 1) {
                item.vID = checkItemExist() ? getInfoFromBasket()?.vID : id;
                handleBasket({
                  basket,
                  item,
                  qty: value,
                  cb: (newBasket) => {
                    setBasket(newBasket);
                    localStorage.setItem(
                      "grocery_basket",
                      JSON.stringify(newBasket)
                    );
                  },
                });
              } else {
                setIsItemModal(true);
                setItemDetials(item);
              }
              let addReomve = option === "-" ? "remove" : "add";
              WebengageForAddRemove(
                item,
                addReomve,
                value,
                isLogin,
                isSuperApp,
                subTotal,
                PageLocation
              );
            }}
          />
        </div>
        <Touchable
          onClick={() => {
            setIsItemModal(true);
            setItemDetials(item);
          }}
        >
          <LazyLoadImage
            alt={"bannar-image"}
            height={"100%"}
            src={imgs[0] || bagImg}
            width={"100%"}
            effect="opacity"
          />
        </Touchable>
      </div>
      <Space height={6} />
      <div className="card-info">
        <div>
          <div className="size-info">
            {size && (
              <>
                <div className="size-tag">
                  <span>{size}</span>
                </div>
                <Space width={8} />
              </>
            )}
            {count > 1 && <div className="size-tag">{`${count - 1}+`}</div>}
          </div>
          <Space height={4} />

          <Text height={36} color="#666" size={12}>
            <Truncate lines={2} ellipsis={<span>...</span>}>
              {`${product} ${name || ""}`}
            </Truncate>
          </Text>
        </div>
        <div className="price-info">
          {endprice < price && (
            <Text lineThrough size={12} color="#666">{`${Number(
              price
            ).toLocaleString("en")} د.ع`}</Text>
          )}

          <Text bold size={14}>{`${Number(endprice).toLocaleString(
            "en"
          )} د.ع`}</Text>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
