// import Sheet from "react-modal-sheet";
import "./style.css";
import { useAppStore } from "../../../store";

import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { updateUser } from "../../../services";
import { PureModalSheet } from "../PureModalSheet";
import PureButton from "../Button";

export const UserModal = () => {
  const { isUserModal, setIsUserModal, user, setUser } = useAppStore();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const { mutate, isLoading } = useMutation(updateUser, {
    onSuccess: (data) => {
      if (data.success) {
        localStorage.setItem("grocery_user", JSON.stringify(data.data));
        setUser(data.data);
        setFirstName(null);
        setLastName(null);
        setIsUserModal(false);
      } else {
        toast.error(data.msg);
      }
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const handleSaveInfo = () => {
    try {
      let data = {
        ...user,
        firstName,
        lastName,
      };

      mutate(data);
    } catch (err) {
      console.error(`UserModal handleSaveInfo: ${err}`);
    }
  };

  return (
    <PureModalSheet isOpen={isUserModal} onClose={() => setIsUserModal(false)}>
      <div className="user-modal">
        <div className="app-container">
          <div className="form">
            <form autoComplete="off">
              <input
                value={firstName || ""}
                name="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="الاسم"
              />
              <input
                value={lastName || ""}
                name="lastName"
                onChange={(e) => setLastName(e.target.value)}
                placeholder="اسم العائلة"
              />
            </form>
          </div>
        </div>
        <div className="register-btn">
          <div className="app-container">
            <PureButton
              disabled={!firstName || !lastName}
              type={"primary"}
              block
              onClick={() => {
                handleSaveInfo();
              }}
            >
              {isLoading ? (
                <div className="btn-loading"></div>
              ) : (
                "حفظ المعلومات"
              )}
            </PureButton>
          </div>
        </div>
      </div>
    </PureModalSheet>
  );
};
