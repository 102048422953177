import "./style.css";
import Text from "../../Global/Text";
import Space from "../../Global/Space";
import { Row, Col } from "../../Global/Grid";
import { BalyVoucher } from "../../BalyIcons";
import PureButton from "../../Global/Button";
import { useAppStore } from "../../../store";
import { useEffect, useState } from "react";
import { checkVoucher } from "../../../services";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { getUDID } from "../../../helper/GetUDID";
import { getPrices } from "../../Global/Invoice";
import ga4 from "react-ga4";
import { calculateDeliveryFeeDiscount } from "../../../helper/calculateFreeDelivery";

export const VoucherInput = () => {
  const {
    deepVoucher,
    user,
    selectedAddress,
    basket,
    setVoucher,
    voucher,
    device,
    discountCap,
    vendorID,
    vendor,
    deliveryDiscountInfo,
  } = useAppStore();

  let { voucherValue, total } = getPrices(basket, voucher, vendor);
  const [voucherInput, setVoucherInput] = useState("");

  useEffect(() => {
    if (deepVoucher) {
      setVoucherInput(deepVoucher);
      chack(deepVoucher);
    }
  }, [deepVoucher]);

  const { mutate, isLoading } = useMutation(checkVoucher, {
    onSuccess: (data) => {
      if (data.success) {
        if (
          deliveryDiscountInfo?.Discount > 0 &&
          total + voucherValue >= deliveryDiscountInfo?.Minamount &&
          data?.data?.voucherType === "DELIVERY"
        ) {
          toast.error("لا يمكن تطبيق عند وجود خصم على التوصيل");
          setVoucher(null);
          return;
        }

        setVoucher(data?.data);
      } else {
        toast.error(data?.err);
      }
    },
    onError: (e) => {
      toast.error("خطأ بالخادم!");
    },
  });

  const chack = (code) => {
    mutate({
      UDID: getUDID(),
      caid: selectedAddress?.id,
      cid: user?.id,
      code,
      orderDetail: basket?.map((el) => {
        return {
          productID: el?.item?.vID,
          qty: el?.qty,
        };
      }),
      vnid: vendorID,
      discountCapID: discountCap?.id ? discountCap?.id : 0,
      platform: device,
    });
    ga4.event("Grocery Apply Voucher", {
      voucherTitle: code,
      voucherCode: code || "",
    });
    if (window && window.webengage)
      window.webengage?.user?.setAttribute({
        voucherTitle: code,
        voucherCode: code || "",
      });
  };

  useEffect(() => {
    if (!voucherInput) {
      setVoucher(null);
      return;
    }

    // chack();
  }, [voucherInput]);

  const getDeliveryFee = () => {
    const deliveryFee = calculateDeliveryFeeDiscount(
      deliveryDiscountInfo,
      vendor,
      total + voucherValue,
      voucher
    );

    if (deliveryFee === 0) {
      return vendor?.delivery;
    }

    return Number(deliveryFee);
  };

  return (
    <div className="voucher-input">
      <Text size={16}>الرمز الترويجي</Text>
      <Space height={12} />
      <Row>
        <Col flex={1}>
          <div className="voucher-input-field flex-center">
            <BalyVoucher size={24} />
            {voucher ? (
              <input
                value={`تم خصم ${voucher?.voucherType === "DELIVERY"
                    ? Number(getDeliveryFee()).toLocaleString("en")
                    : voucherValue.toLocaleString("en")
                  } د.ع`}
                readOnly
              />
            ) : (
              <input
                value={voucherInput}
                onChange={(e) => setVoucherInput(e?.target?.value)}
                placeholder={"ادخل الرمز الترويجي"}
              />
            )}
          </div>
        </Col>
        <Space width={8} />
        {voucher ? (
          <PureButton
            onClick={() => {
              setVoucher(null);
              setVoucherInput(voucherInput);
            }}
          >
            <Text size={18} color="#ff0000">
              الغاء
            </Text>
          </PureButton>
        ) : (
          <PureButton
            disabled={isLoading || voucherInput?.length < 2}
            type="black"
            onClick={() => chack(voucherInput)}
          >
            ادخال
          </PureButton>
        )}
      </Row>
    </div>
  );
};
