import { useQuery } from "react-query";
import PureCard from "../../../Global/Card";

import "./index.css";
import { getReferalcode } from "../../../../services";
import { useEffect, useState } from "react";
import Space from "../../../Global/Space";
import PureButton from "../../../Global/Button";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../../../store";

export const ReferralCard = () => {
  const navigate = useNavigate();
  const { setIsReferralAvailable } = useAppStore();
  const [settings, setSettings] = useState(null);

  const { data, isLoading } = useQuery("referralCode", getReferalcode, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data && data?.success) {
      setSettings(data?.data?.referralSettings);
      setIsReferralAvailable(data?.data?.referralSettings);
    }
  }, [data]);

  return (
    settings?.id !== 0 && (
      <PureCard
        style={{
          background: "#e8e8fc",
          border: 0,
        }}
      >
        <div className="referral_card">
          <div className="referral-info_v2">
            <p className="title">
              {`اعزم صديقك وحصل  ${Number(
                settings?.referrerVoucher?.maxAmount * settings?.maxUse
              ).toLocaleString("en")} د.ع`}
            </p>
            <Space height={10} />
            <p className="desc">
              {`ستحصل على ${
                Number(settings?.referredVoucher?.maxAmount).toLocaleString(
                  "en"
                ) || 0
              } د.ع على طلبك عند كل دعوة, واصدقائك سيحصلون على خصم ${
                Number(settings?.referrerVoucher?.maxAmount).toLocaleString(
                  "en"
                ) || 0
              } د.ع على طلبهم الاول عند تسجيلهم في بلي مارت.`}
            </p>
          </div>
          <Space height={16} />
          <PureButton
            small
            type="accent"
            radius={30}
            styles={{
              background: "#CCCCFA",
            }}
            onClick={() => {
              navigate("/referral");
            }}
          >
            شارك الدعوة
          </PureButton>
        </div>
      </PureCard>
    )
  );
};
