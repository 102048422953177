export const BalyBasket = ({ isFill, size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFill ? color : "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {!isFill ? (
        <path
          d="M3 3.5H5L5.4 5.5M7 13.5H17L21 5.5H5.4M7 13.5L5.4 5.5M7 13.5L4.70711 15.7929C4.07714 16.4229 4.52331 17.5 5.41421 17.5H17M17 17.5C15.8954 17.5 15 18.3954 15 19.5C15 20.6046 15.8954 21.5 17 21.5C18.1046 21.5 19 20.6046 19 19.5C19 18.3954 18.1046 17.5 17 17.5ZM9 19.5C9 20.6046 8.10457 21.5 7 21.5C5.89543 21.5 5 20.6046 5 19.5C5 18.3954 5.89543 17.5 7 17.5C8.10457 17.5 9 18.3954 9 19.5Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 3.5C2 2.94772 2.44772 2.5 3 2.5H5C5.47668 2.5 5.8871 2.83646 5.98058 3.30388L6.2198 4.5H21C21.3466 4.5 21.6684 4.67945 21.8507 4.97427C22.0329 5.26909 22.0494 5.63723 21.8944 5.94721L17.8944 13.9472C17.725 14.286 17.3788 14.5 17 14.5H7.41422L5.41423 16.5L17 16.5C18.6569 16.5 20 17.8431 20 19.5C20 21.1569 18.6569 22.5 17 22.5C15.3431 22.5 14 21.1569 14 19.5C14 19.1494 14.0602 18.8128 14.1707 18.5H9.8293C9.93985 18.8128 10 19.1494 10 19.5C10 21.1569 8.65685 22.5 7 22.5C5.34315 22.5 4 21.1569 4 19.5C4 19.0239 4.11091 18.5737 4.30831 18.1738C3.3292 17.5234 3.04056 16.0453 3.99999 15.0858L5.91446 13.1713L4.42347 5.71636C4.42066 5.70365 4.4181 5.69084 4.41579 5.67795L4.1802 4.5H3C2.44772 4.5 2 4.05228 2 3.5ZM7 18.5C6.44771 18.5 6 18.9477 6 19.5C6 20.0523 6.44771 20.5 7 20.5C7.5523 20.5 8 20.0523 8 19.5C8 18.9477 7.5523 18.5 7 18.5ZM17 18.5C16.4477 18.5 16 18.9477 16 19.5C16 20.0523 16.4477 20.5 17 20.5C17.5523 20.5 18 20.0523 18 19.5C18 18.9477 17.5523 18.5 17 18.5Z"
          fill={color}
        />
      )}
    </svg>
  );
};
