import Screen from "../components/Global/Screen";
import MainHeader from "../components/Global/MainHeader";
import Container from "../components/Global/Container";
import Divider from "../components/Global/Divider";
import Text from "../components/Global/Text";
import Space from "../components/Global/Space";
import ItemsFilter from "../components/Items/Filter";
import { BannerCard } from "../components/Global/BannerCard";
import Slider from "../components/Global/Slider";
import PureButton from "../components/Global/Button";
import { Row, Col } from "../components/Global/Grid";
import SearchBox from "../components/Global/SearchBox";
import BasketBtn from "../components/Global/BasketBtn";
import { CategoryList } from "../components/Categories";
import QtButton from "../components/Global/QtButton";
import { useState } from "react";
import ItemCard from "../components/Global/ItemCard";
import ItemBannerList from "../components/Global/ItemBannerList";
import PureButtonIcon from "../components/Global/ButtonIcon";
import { BalyFilter } from "../components/BalyIcons";
import { useAppStore } from "../store";

export const catlist = [
  {
    id: 1,
    image: "https://app.baly.dev/content/TPLeqc0URzV82A064jEuajpeg",
    label: "المفضلات",
  },
  {
    id: 2,
    image: "https://app.baly.dev/content/C38eST8FhyKoIENcVU6MMpng",
    label: "المنظفات",
  },
  {
    id: 3,
    image: "https://app.baly.dev/content/JJCduDDidKgbXsVnIdnnmpng",
    label: "المشروبات",
  },
  {
    id: 4,
    image: "https://app.baly.dev/content/TmzMJpiq7eLd0h6mgoCiHjpeg",
    label: "اشياء",
  },
  {
    id: 5,
    image: "https://app.baly.dev/content/83lRxcSh13myU4SwwZSrrpng",
    label: "كلاوات العيد",
  },
  {
    id: 6,
    image: "https://app.baly.dev/content/q23OVVbE8ysXimY0BgJp0jfif",
    label: "مستلزمات الاكل",
  },
  {
    id: 7,
    image: "https://app.baly.dev/content/3YNmqQQLRa8lLzPJrMSzFjfif",
    label: "مجمدات",
  },
  {
    id: 8,
    image: "https://app.baly.dev/content/TmzMJpiq7eLd0h6mgoCiHjpeg",
    label: "مجمدات",
  },
  {
    id: 9,
    image: "https://app.baly.dev/content/C38eST8FhyKoIENcVU6MMpng",
    label: "مجمدات",
  },
];

export const items = [
  {
    id: 1,
    name: "جبن بارمزان معتق 10 اشهر",
    size: "12 لتر",
    img: '["https://app.baly.dev/content/83lRxcSh13myU4SwwZSrrpng","https://app.baly.dev/content/83lRxcSh13myU4SwwZSrrpng"]',
    price: 1500,
    endprice: 1500,
    discount: 0,
  },
  {
    id: 2,
    name: "جبن بارمزان معتق 10 اشهر",
    size: "12 لتر",
    img: '["https://app.baly.dev/content/83lRxcSh13myU4SwwZSrrpng","https://app.baly.dev/content/83lRxcSh13myU4SwwZSrrpng"]',
    price: 1000,
    endprice: 900,
    discount: 10,
  },
  {
    id: 3,
    name: "جبن بارمزان معتق 10 اشهر",
    size: "12 لتر",
    img: '["https://app.baly.dev/content/83lRxcSh13myU4SwwZSrrpng","https://app.baly.dev/content/83lRxcSh13myU4SwwZSrrpng"]',
    price: 2500,
    endprice: 2500,
    discount: 0,
  },
  {
    id: 4,
    name: "جبن بارمزان معتق 10 اشهر",
    size: "12 لتر",
    img: '["https://app.baly.dev/content/83lRxcSh13myU4SwwZSrrpng","https://app.baly.dev/content/83lRxcSh13myU4SwwZSrrpng"]',
    price: 2500,
    endprice: 2500,
    discount: 0,
  },
  {
    id: 5,
    name: "جبن بارمزان معتق 10 اشهر",
    size: "12 لتر",
    img: '["https://app.baly.dev/content/83lRxcSh13myU4SwwZSrrpng","https://app.baly.dev/content/83lRxcSh13myU4SwwZSrrpng"]',
    price: 1200,
    endprice: 1200,
    discount: 0,
  },
  {
    id: 6,
    name: "جبن بارمزان معتق 10 اشهر",
    size: "12 لتر",
    img: '["https://app.baly.dev/content/83lRxcSh13myU4SwwZSrrpng","https://app.baly.dev/content/83lRxcSh13myU4SwwZSrrpng"]',
    price: 5500,
    endprice: 5500,
    discount: 0,
  },
];

const UiScreen = () => {
  const [qty, setQty] = useState(2);
  const { setIsFilter, setIsItemModal, appClose } = useAppStore();

  return (
    <>
      <MainHeader />
      <Screen>
        <Container>
          <Space height={20} />
          <Text bold size={18}>
            عناصر التصميم
          </Text>
          <Space height={8} />
          <Text size={14}>
            هذه الصفحة مخصصه لمكونات التصميم الرئيسيه ولاتظهر للمستخدم.
          </Text>
          <Divider />
          <Space height={8} />
          <SearchBox />
          <Space height={16} />
          <BannerCard img="https://app.food.baly.iq/content/j9eoadvud9nyyyc1zldm.png" />
          <Space height={8} />
          <Divider />
        </Container>
        <Space height={8} />
        <Slider
          banners
          items={[
            <BannerCard img="https://app.baly.dev/content/lQnzq_rmA-HGyLNnNuQnZpng" />,
            <BannerCard img="https://app.food.baly.iq/content/j9eoadvud9nyyyc1zldm.png" />,
            <BannerCard img="https://app.food.baly.iq/content/j9eoadvud9nyyyc1zldm.png" />,
          ]}
        />
        <Space height={8} />
        <Container>
          <Divider />
          <Space height={8} />

          <Row>
            <Col flex={1}>
              <PureButton block type="primary">
                أضغط هنا
              </PureButton>
            </Col>
            <Space width={10} />
            <Col flex={1}>
              <PureButton block type="secondary">
                أضغط هنا
              </PureButton>
            </Col>
            <Space width={10} />
            <Col flex={1}>
              <PureButton block>أضغط هنا</PureButton>
            </Col>
          </Row>
          <Space height={10} />
          <Row>
            <Col flex={1}>
              <PureButton small block type="primary">
                أضغط هنا
              </PureButton>
            </Col>
            <Space width={10} />
            <Col flex={1}>
              <PureButton small block type="secondary">
                أضغط هنا
              </PureButton>
            </Col>
            <Space width={10} />
            <Col flex={1}>
              <PureButton small block>
                أضغط هنا
              </PureButton>
            </Col>
          </Row>
        </Container>
        <Space height={16} />
        <BasketBtn count={4} />
        <Space height={8} />
        <Container>
          <CategoryList isHome list={catlist} />
          <Space height={16} />
          <Divider />
          <Space height={16} />
          <CategoryList list={catlist} />
          <Divider />

          <Space height={16} />
          <Row>
            <Col flex={1}>
              <QtButton
                disabledIncrease={appClose}
                value={qty}
                onChange={setQty}
              />
            </Col>
            <Col flex={1}>
              <QtButton
                disabledIncrease={appClose}
                small
                value={qty}
                onChange={setQty}
              />
            </Col>
          </Row>

          <Space height={16} />
          <Divider />
          <Space height={16} />
          <Row>
            <ItemCard
              imgs={JSON.parse(items[0].img)}
              name="حليب لوز من بلو دايموند"
              size="12 لتر"
              price={1420}
              endprice={1000}
              discount={20}
              width={112}
            />
            <Space width={10} />
            <ItemCard
              imgs={JSON.parse(items[0].img)}
              name="جبن بارمزان معتق 10 اشهر"
              size="12 لتر"
              price={1000}
              endprice={1000}
              width={112}
            />
            <Space width={10} />
            <ItemCard
              imgs={JSON.parse(items[0].img)}
              name="جبن بارمزان معتق 10 اشهر"
              size="100 غم"
              price={1000}
              endprice={1000}
              width={112}
            />
          </Row>
          <Space height={16} />
          <Divider />
        </Container>
        <Space height={16} />
        <ItemBannerList title="افضل المنتجات" link={"/"} list={items} />
        <Container>
          <Space height={16} />
          <Divider />
          <Space height={16} />

          <PureButtonIcon onClick={() => setIsFilter(true)}>
            <BalyFilter size={25} />
          </PureButtonIcon>
        </Container>
        <Space height={150} />
        <ItemsFilter brandQuery={`?bid=${11}`} />
      </Screen>
    </>
  );
};

export default UiScreen;
