import "./style.css";
import { useState, useEffect } from "react";

const ProgressBar = ({ value = 0, max = 100 }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    setPercentage((value / max) * 100);
  }, [value]);

  return (
    <div className="progress-bar">
      <div
        className="progress-bar-value"
        style={{
          width: `${percentage}%`,
          background: percentage >= 100 ? "#01B248" : null,
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
