import create from "zustand";

// const [isLike, setIsLike] = useState(false);
// const [isDisLink, setIsDisLike] = useState(false);
export const useRateStore = create((set) => ({
  isRateModal: false,
  isLike: false,
  isDisLik: false,
  bikerRate: null,
  order: null,
  comment: "",
  productComment: "",
  view: {
    step: "BIKER",
    height: 428,
  },
  vendorRate: null,
  products: [],
  reasons: [],
  selectedReasons: [],
  selectedBikerReasons: [],
  shouldRefreshAList: false,

  setProducts: (products) => set({ products }),
  setComment: (comment) => set({ comment }),
  setProductComment: (productComment) => set({ productComment }),
  setVendorRate: (vendorRate) => set({ vendorRate }),
  setBikerRate: (bikerRate) => set({ bikerRate }),
  setOrder: (order) => set({ order }),
  setView: (view) => set({ view }),
  setIsRateModal: (isRateModal) => set({ isRateModal }),
  setIsLike: (isLike) => set({ isLike }),
  setIsDisLike: (isDisLik) => set({ isDisLik }),
  setReasons: (reasons) => set({ reasons }),
  setSelectedReasons: (selectedReasons) => set({ selectedReasons }),
  setSelectedBikerReasons: (selectedBikerReasons) =>
    set({ selectedBikerReasons }),
  setShouldRefreshAList: (shouldRefreshAList) => set({ shouldRefreshAList }),

  reset: () =>
    set({
      isRateModal: false,
      isLike: false,
      isDisLik: false,
      bikerRate: null,
      order: null,
      comment: "",
      productComment: "",

      vendorRate: null,
      products: [],
      reasons: [],
      selectedReasons: [],
      selectedBikerReasons: [],
      shouldRefreshAList: false,
    }),
}));
