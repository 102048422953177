export const EmptySearchIcon = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52" cy="52" r="51.5" fill="#F6F6F6" stroke="#F0F0F0" />
    <path
      d="M70.6945 58.6272H74.3325C75.5831 58.6272 76.6631 57.7366 76.9095 56.505H76.8905L80.3865 38.6937C80.4812 38.2484 80.1307 37.8315 79.6759 37.8315H75.0999C74.7304 37.8315 74.4178 38.1063 74.3799 38.4758C74.2757 39.4516 73.3472 41.4885 71.7082 42.7391"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.7993 37.8315H57.5823C57.1276 37.8315 56.777 38.2484 56.8718 38.6937L60.3677 56.505C60.6045 57.7177 61.6562 58.5893 62.8783 58.6177"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.033 33.5965L65.3698 23.3171L60.7749 23.554L61.0875 29.7216"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.8723 26.3585L70.6852 26.1311L70.8083 29.5418L71.3862 42.8339"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.9717 36.9504L75.6117 29.3237L70.8083 29.5511"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.5999 37.2916L79.202 21.053L74.0007 21.1572L74.285 29.1628"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.5302 61.8106H49.6528C50.0791 61.8106 50.4486 61.4885 50.4676 61.0527C50.5813 59.2621 50.4676 55.0745 47.3695 51.3797C43.2957 46.491 37.4028 46.51 36.6354 46.5194C36.3701 46.5194 35.4985 46.5384 34.2953 46.7658"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M24.9349 52.9333C22.9169 56.1451 22.7653 59.4326 22.8221 61.0337C22.8411 61.4695 23.2106 61.8106 23.6369 61.8106H28.6676"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M45.5596 66.3296H48.7618"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M24.5557 66.3296H30.0601"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M48.5915 80.806H24.7073C23.6557 80.806 22.8125 80.0671 22.8125 79.1481V77.6607C22.8125 76.7417 23.6557 76.0027 24.7073 76.0027H48.5915C49.6431 76.0027 50.4863 76.7417 50.4863 77.6607V79.1481C50.4863 80.0671 49.6431 80.806 48.5915 80.806Z"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M24.5557 71.0098C26.9716 71.0098 26.9716 71.5024 29.3875 71.5024C31.8033 71.5024 31.8128 71.0098 34.2192 71.0098C35.3561 71.0098 35.9625 71.1235 36.5404 71.2371"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M26.3085 58.5513L26.4695 58.2197"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M35.4698 51.6354L35.9057 51.5217"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M40.946 51.5122L41.2776 51.787"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M44.906 54.7051L45.1334 55.0367"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.306 57.8599H41.8082"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M72.9874 74.193L63.1817 64.3874M68.8188 50.271C68.8188 61.1851 59.97 70.0339 49.0559 70.0339C38.1417 70.0339 29.3024 61.1756 29.3024 50.2615C29.3024 39.3473 38.1606 30.4985 49.0653 30.4985C59.97 30.4985 68.8283 39.3473 68.8283 50.2615L68.8188 50.271Z"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.9881 82.3403C60.5038 78.8188 58.912 74.8215 55.3905 73.3058C58.912 74.8215 62.9094 73.2297 64.4251 69.7082C62.9094 73.2297 64.5011 77.2271 68.0226 78.7428C64.5011 77.2271 60.469 78.8996 58.9881 82.3403Z"
      fill="#CCCCCC"
    />
    <path
      d="M47.2629 45.5471C47.2629 42.7435 45.038 40.5185 42.2344 40.5185C45.038 40.5185 47.2629 38.2936 47.2629 35.49C47.2629 38.2936 49.4878 40.5185 52.2915 40.5185C49.4878 40.5185 47.2629 42.8078 47.2629 45.5471Z"
      fill="#CCCCCC"
    />
    <path
      d="M78.2286 69.4072C78.2286 67.4446 76.6712 65.8872 74.7086 65.8872C76.6712 65.8872 78.2286 64.3297 78.2286 62.3672C78.2286 64.3297 79.786 65.8872 81.7486 65.8872C79.786 65.8872 78.2286 67.4896 78.2286 69.4072Z"
      fill="#CCCCCC"
    />
    <path
      d="M51.6794 27.08C51.6794 23.1549 48.5645 20.04 44.6394 20.04C48.5645 20.04 51.6794 16.9251 51.6794 13C51.6794 16.9251 54.7943 20.04 58.7194 20.04C54.7943 20.04 51.6794 23.2449 51.6794 27.08Z"
      fill="#CCCCCC"
    />
  </svg>
);
