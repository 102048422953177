import "./style.css";
import { IoChevronForward } from "react-icons/io5";
import { useNavigate, useNavigation } from "react-router-dom";
import { GoToLink } from "../../../helper";
import Space from "../Space";
import Text from "../Text";
import Container from "../Container";

const ChildHeader = (props) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if(window.location.pathname.startsWith("/order/") || window.location.pathname.startsWith("/check")) {
      GoToLink(() => navigate("/"));
      return;
    }
    GoToLink(() => navigate(-1));
  };

  return (
    <div {...props} className="child-header">
      <Container>
        <div className="flex-space" style={{ justifyContent: "flex-start" }}>
          {!props?.withoutBack && (
            <div className="tauchable" onClick={() => handleBack()}>
              <IoChevronForward size={24} />
            </div>
          )}
          {props?.backAction && (
            <div className="tauchable" onClick={props?.backAction}>
              {props?.backIcon}
            </div>
          )}
          {!props?.customeTitle ? (
            <div className="content">
              {props?.title && (
                <Text bold size={18}>
                  {props?.title}
                </Text>
              )}
              {props?.search && props?.search}
            </div>
          ) : (
            props?.customeTitle
          )}
          {props?.actions && (
            <div className="flex-center">
              {props?.actions?.map((el, i) => (
                <div key={i}>
                  <Space width={8} />
                  {el}
                </div>
              ))}
            </div>
          )}
        </div>
      </Container>

      {props?.bottom}
    </div>
  );
};

export default ChildHeader;
