import "./style.css";
import { FaStar, FaRegStar } from "react-icons/fa";
import PureCard from "../../Global/Card";
import Text from "../../Global/Text";
import { BalyCalendar, BalyColck, BalyLocation } from "../../BalyIcons";
import { Row, Col } from "../../Global/Grid";
import Space from "../../Global/Space";
import ImgGrid from "../../Global/ImgGrid";
import { StatusBar } from "../../Global/StatusBar";
import { Rating } from "react-simple-star-rating";
import PureButton from "../../Global/Button";
import {
  GoToLink,
  parseExpectedTime,
  parseInvoice,
  parsePreviousETA,
} from "../../../helper";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useAppStore } from "../../../store";
import { useMutation } from "react-query";
import { checkAvilableAddress } from "../../../services";
import { useState } from "react";
import Touchable from "../../Global/Ripple";
import { AlertMessage } from "../../Global/AlertMessage";
import { useRateStore } from "../../Global/RatingModal/store";

const parseImages = (orderDetail) => {
  let images =
    orderDetail?.map(
      (el) => el?.product?.img && JSON.parse(el?.product?.img)[0]
    ) || [];
  return images;
};

export const OrderCard = ({ history = false, review = false, order }) => {
  const navigate = useNavigate();
  const {
    setBasket,
    setIsReview,
    setReviewData,
    selectedAddress,
    clearBasket,
    canUserProccedToCheckout,
    setCanUserProccedToCheckout,
  } = useAppStore();

  const { setIsRateModal, setOrder, setView } = useRateStore();

  const { mutate, isLoading } = useMutation(checkAvilableAddress, {
    onSuccess: (data) => {
      setCanUserProccedToCheckout(true);
      if (!data.success || !data?.data?.inrange) {
        setTimeout(() => {
          setCanUserProccedToCheckout(false);
        }, 100);
      }
    },
    onError: (e) => {},
  });

  const handleReOrder = () => {
    mutate({
      vendorID: order?.vendorID,
      addressID: selectedAddress?.id,
    });

    if (!isLoading && !canUserProccedToCheckout) {
      let newBasket = parseInvoice(order?.orderDetail);
      setBasket(newBasket);
      navigate(`/cart`);
    }
  };

  return (
    <PureCard
      style={{
        padding: review ? 0 : 16,
        borderRadius: 16,
        marginTop: 16,
        borderWidth: review ? 0 : 1,
      }}
      onClick={!history ? () => navigate(`/order/${order?.id}`) : undefined}
    >
      <div className="order-card">
        <section className="main-info">
          <div className="items-img">
            <ImgGrid images={parseImages(order?.orderDetail)} />
          </div>
          <div className="order-info">
            <Text bold size={16}>
              {history
                ? parsePreviousETA(
                    order?.expectedDeliveryTime,
                    "dddd - YYYY/MM/DD"
                  )
                : parseExpectedTime(order?.expectedDeliveryTime)}
            </Text>
            <Row>
              <Space width={1} />
              {history ? (
                <BalyColck
                  outline
                  size={14}
                  color={"#666"}
                  className="responsive-icon"
                />
              ) : (
                <BalyCalendar
                  size={14}
                  color={"#666"}
                  className="responsive-icon"
                />
              )}
              <Space width={6} />
              <Text size={12} color={"#666"}>
                {history
                  ? parsePreviousETA(order?.expectedDeliveryTime, "hh:mm A")
                  : dayjs(order?.expectedDeliveryTime).format("DD-MM-YYYY")}
              </Text>
            </Row>
            <Row>
              <BalyLocation size={16} />
              <Space width={6} />
              <div className="address-resposive">
                <Text size={12}>
                  <p className="order-card-address">
                    {order?.clientAddress.replace("، ", "")}
                  </p>
                </Text>
              </div>
            </Row>
          </div>
        </section>
        <Space height={16} />
        {!review && !history ? (
          <StatusBar
            status={order?.lastStatus || "PENDING"}
            orderId={order?.id}
            onChange={(statusMsg) => {}}
          />
        ) : (
          !review && (
            <div>
              {order?.rate?.rate ? (
                <PureCard
                  style={{
                    background: "#fff",
                    padding: "10px 12px",
                    border: "none",
                  }}
                >
                  <div className="flex-space rate-star">
                    <Text size={16}>تقييمك للطلب</Text>
                    <Rating
                      allowFraction
                      fillIcon={<FaStar size={18} />}
                      emptyIcon={<FaRegStar size={18} />}
                      transition={true}
                      rtl={false}
                      emptyColor="#ccc"
                      readonly
                      initialValue={order?.rate?.rate}
                    />
                  </div>
                </PureCard>
              ) : (
                <PureCard
                  style={{ background: "#F6F6F6", padding: "10px 12px" }}
                  onClick={() => {
                    // setReviewData(order);
                    // setIsReview(true);
                    setView({ step: "BIKER", height: 428 });
                    setIsRateModal(true);
                    setOrder(order);
                  }}
                >
                  <div className="flex-space rate-star">
                    <Text size={16}>كيف كانت تجربتك ؟</Text>
                    <Rating
                      allowFraction
                      fillIcon={<FaStar size={18} />}
                      emptyIcon={<FaRegStar size={18} />}
                      transition={true}
                      rtl={false}
                      emptyColor="#ccc"
                      readonly
                      initialValue={0}
                    />
                  </div>
                </PureCard>
              )}
              <Space height={13} />
              <div className="flex-space">
                <Text size={14}>السعر الاجمالي</Text>
                <Text size={14}>{`${Number(order?.totalValue).toLocaleString(
                  "en"
                )} د.ع`}</Text>
              </div>
              <Space height={20} />
              <Row>
                <Col flex={1}>
                  <PureButton
                    onClick={() =>
                      GoToLink(() => navigate(`/invoice/${order?.id}`))
                    }
                    styles={{ padding: "0 8px" }}
                    block
                    small
                  >
                    عرض الفاتورة
                  </PureButton>
                </Col>
                <Space width={8} />
                <Col flex={1}>
                  <PureButton
                    block
                    small
                    type="primary"
                    onClick={handleReOrder}
                    styles={{ padding: "0 8px" }}
                    disabled={isLoading}
                  >
                    اطلب مجدداً
                  </PureButton>
                </Col>
              </Row>
            </div>
          )
        )}
      </div>
    </PureCard>
  );
};
