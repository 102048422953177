import "./style.css";
import Touchable from "../../Global/Ripple";
import Text from "../../Global/Text";
import Space from "../../Global/Space";
import bagImg from "../../../assets/images/empty2.jpg";

export const CategoryCard = ({ img, label, onClick = () => {} }) => {
  return (
    <div className="cat-card">
      <div className="cat-card-img">
        <Touchable onClick={onClick}>
          <img
            alt={"bannar-image"}
            height={"100%"}
            src={img}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = bagImg;
            }}
            width={"100%"}
          />
        </Touchable>
      </div>
      <Space height={4} />
      <Text size={14}>{label}</Text>
    </div>
  );
};
