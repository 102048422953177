export const EmptyCartIcon = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52" cy="52" r="51.5" fill="#F6F6F6" stroke="#F0F0F0" />
    <path
      d="M29 32.5996H34.2L35.24 37.7996L39.4 58.5996M39.4 58.5996H65.4L75.8 37.7996M39.4 58.5996L33.4385 64.5611C31.8006 66.1991 32.9606 68.9996 35.2769 68.9996H65.4M65.4 68.9996C62.528 68.9996 60.2 71.3276 60.2 74.1996C60.2 77.0716 62.528 79.3996 65.4 79.3996C68.272 79.3996 70.6 77.0716 70.6 74.1996C70.6 71.3276 68.272 68.9996 65.4 68.9996ZM44.6 74.1996C44.6 77.0716 42.2719 79.3996 39.4 79.3996C36.5281 79.3996 34.2 77.0716 34.2 74.1996C34.2 71.3276 36.5281 68.9996 39.4 68.9996C42.2719 68.9996 44.6 71.3276 44.6 74.1996Z"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="45.4004" cy="51" r="4" fill="#CCCCCC" />
    <circle cx="68.4004" cy="36" r="4" fill="#CCCCCC" />
    <circle cx="58.4004" cy="31" r="6" fill="#CCCCCC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.3692 43.7385C48.8869 43.7385 51.7385 40.8869 51.7385 37.3692C51.7385 33.8516 48.8869 31 45.3692 31C41.8516 31 39 33.8516 39 37.3692C39 40.8869 41.8516 43.7385 45.3692 43.7385ZM48.8388 35.9259C49.2141 35.4755 49.1533 34.8061 48.7029 34.4308C48.2525 34.0555 47.5832 34.1163 47.2078 34.5667L44.4849 37.8343L43.5311 36.6898C43.1558 36.2394 42.4864 36.1786 42.0361 36.5539C41.5857 36.9292 41.5248 37.5986 41.9001 38.049L43.6694 40.172C43.8711 40.4141 44.1698 40.554 44.4849 40.554C44.7999 40.554 45.0987 40.4141 45.3004 40.172L48.8388 35.9259Z"
      fill="#CCCCCC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.068 54.3537C63.465 54.3537 67.0295 50.7892 67.0295 46.3922C67.0295 41.9952 63.465 38.4307 59.068 38.4307C54.6709 38.4307 51.1064 41.9952 51.1064 46.3922C51.1064 50.7892 54.6709 54.3537 59.068 54.3537ZM60.1291 41.8428C60.1291 41.2566 59.6538 40.7813 59.0675 40.7813C58.4813 40.7813 58.006 41.2566 58.006 41.8428V45.3307H54.5181C53.9318 45.3307 53.4566 45.806 53.4566 46.3923C53.4566 46.9785 53.9318 47.4538 54.5181 47.4538H58.006V50.9417C58.006 51.528 58.4813 52.0033 59.0675 52.0033C59.6538 52.0033 60.1291 51.528 60.1291 50.9417V47.4538H63.617C64.2033 47.4538 64.6785 46.9785 64.6785 46.3923C64.6785 45.806 64.2033 45.3307 63.617 45.3307H60.1291V41.8428Z"
      fill="#CCCCCC"
    />
  </svg>
);
