import "./style.css";
import Text from "../../Global/Text";
import { useAppStore } from "../../../store";

export const CartNote = () => {
  const { Comment, setComment } = useAppStore();

  return (
    <div className="cart-note">
      <Text size={16}>ملاحظات اضافية</Text>
      <input
        value={Comment}
        type="text"
        placeholder="ملاحظات اضافية يجب ان نلاحظها"
        onChange={(e) => setComment(e.target.value)}
      />
    </div>
  );
};
