import "./App.css";
import { CustomErrorBoundary } from "./components/Global/CustomErrorBoundry";
import { Routes, Route, useLocation, useSearchParams } from "react-router-dom";
import { BottomNavBar } from "./components/Global/BottomNavBar";
import AppMenu from "./components/Global/AppMenu";
import ClientMap from "./components/Global/ClientMapModal";
import LoginScreen from "./screens/loginScreen";
import VerifyScreen from "./screens/verifyScreen";
import UiScreen from "./screens/ui";
import HomeScreen from "./screens/homeScreen";
import ItemDetials from "./components/Items/Detials";
import BasketBtn from "./components/Global/BasketBtn";
import { useAppStore } from "./store";
import UserProfileScreen from "./screens/userProfileScreen";
import AppWrapper from "./components/AppWrapper";
import { useEffect } from "react";
import GroupScreen from "./screens/groupScreen";
import CategoriesScreen from "./screens/categoriesScreen";
import CategoryScreen from "./screens/categoryScreen";
import SearchScreen from "./screens/searchScreen";
import CartScreen from "./screens/cartScreen";
import CheckoutScreen from "./screens/checkout";
import OrdersScreen from "./screens/ordersScreen";
import InvoiceScreen from "./screens/invoiceScreen";
import FaivorateScreen from "./screens/faivorateScreen";
import VoucherCenter from "./screens/voucherCenter";

import { useQuery } from "react-query";
import {
  VoucherAvailable,
  getVendorInfo,
  startEventWatching,
  getLastOrder,
  VoucherAvailableV3,
  getOrderStatus,
} from "./services";
import { UserModal } from "./components/Global/UserModal";
import OrderDetialsScreen from "./screens/OrderDetialsScreen";
import ReferralScreen from "./screens/referralScreen";
import CommentsScreen from "./screens/commentsScreen";
import { AlertMessage } from "./components/Global/AlertMessage";
import MobileDetect from "mobile-detect";
import ga4 from "react-ga4";
import { useRateStore } from "./components/Global/RatingModal/store";
import LoginByPassScreen from "./screens/loginByPassScreen";
import { ConfirmationCancelModal } from "./components/Screens/OrderCancellation/CancellationModal/ConfirmationCancelModal";
import { BalyAlertMessage } from "./components/Global/BalyAlertMessage";
import { useCancellationStore } from "./components/Screens/OrderCancellation/store";
import { ConfirmationCancelModalReasons } from "./components/Screens/OrderCancellation/CancellationModal/ConfirmationCancelModalReasons";
import { VoucherModalContainer } from "./components/Screens/VoucherCenterV3/VoucherModal/voucherModalContainer/voucherModalContainer";

function App() {
  const location = useLocation();
  const {
    basket,
    setBasket,
    setDevice,
    setVouchersAvilable,
    setVoucherCenterNotification,
    currentLocation,
    setLogout,
    setVendor,
    vendor,
    setDiscountCap,
    isClose,
    setIsClose,
    appCloseMsg,
    device,
    SetPageLocation,
    isLogin,
    PageLocation,
    setOrderStatus,
    vendorID,
    setDeliveryDiscountInfo,
    setOrderStatusList,
    canUserProccedToCheckout,
    setCanUserProccedToCheckout,
  } = useAppStore();

  const { cancellationErrorMessage, setCancellationErrorMessage } =
    useCancellationStore();

  const { setIsRateModal, setOrder } = useRateStore();

  const [searchParams] = useSearchParams();

  const initBasket = () => {
    let localBasket = localStorage.getItem("grocery_basket");
    if (localBasket) setBasket(JSON.parse(localBasket));
  };

  const checkDevice = () => {
    let os = window?.navigator?.userAgentData?.platform; //WEB
    let md = new MobileDetect(window.navigator.userAgent); // MOB
    if (window.navigator.userAgent.includes("PSA")) {
      if (md.os() === "AndroidOS") setDevice("SHELL_ANDROID");
      else if (md.os() === "iOS") setDevice("SHELL_IOS");
    } else {
      if (md.os() === "AndroidOS") setDevice("WEB_ANDROID");
      else if (md.os() === "iOS") setDevice("WEB_IOS");
      else if (os === "macOS") setDevice("WEB_MACOS");
      else if (os === "Windows") setDevice("WEB_WINDOWS");
      else setDevice("OTHER");
    }
  };

  useEffect(() => {
    if (!!device) localStorage.setItem("deviceType", device);
  }, [device]);

  useEffect(() => {
    initBasket();
    let savedDeviceType = localStorage.getItem("deviceType");
    if (!!savedDeviceType) return setDevice(savedDeviceType);
    let paltform = searchParams.get("os_type");
    switch (paltform) {
      case "ios":
        setDevice("SUPERAPP_IOS");
        break;
      case "android":
        setDevice("SUPERAPP_ANDROID");
        break;

      default:
        // DETECT DEVICE TYPE
        // SHELL_ANDROID (window.navigator.userAgent includes PSA )
        // SHELL_IOS (window.navigator.userAgent includes PSA )
        // WEB_ (IOS,ANDROID,WINDOWS,MACOS)
        // @WAEL add to ENUM
        checkDevice();
        break;
    }
  }, []);

  const { data: voucherCenterData } = useQuery(
    [`VoucherAvailable`, 1, 100, device],
    VoucherAvailableV3,
    {
      refetchOnWindowFocus: false,
      enabled: !!isLogin,
    }
  );

  const { data: orderStatusData } = useQuery(
    [`getOrderStatus`, isLogin ? 1 : 0],
    getOrderStatus,
    {
      refetchOnWindowFocus: false,
      enabled: !!isLogin,
    }
  );

  useEffect(() => {
    setOrderStatusList(orderStatusData?.data);
  }, [orderStatusData]);

  useEffect(() => {
    if (
      voucherCenterData &&
      voucherCenterData?.success &&
      voucherCenterData?.data?.records != null &&
      voucherCenterData?.data?.records?.length !== 0
    ) {
      setVouchersAvilable(voucherCenterData?.data?.records);
      setVoucherCenterNotification(
        voucherCenterData?.data?.records?.map((el) => el.id)
      );
    } else {
      setVouchersAvilable([]);
    }
  }, [voucherCenterData]);

  useEffect(() => {
    if (!isLogin) return;
    startEventWatching((event, data) => {
      if (event === "ORDER_STATUS") {
        setOrderStatus(JSON.parse(data));
      }
    });
  }, [isLogin]);

  const { data } = useQuery(
    [
      `vendorInfo-${currentLocation && currentLocation[0]}`,
      currentLocation,
      vendorID,
    ],
    getVendorInfo,
    {
      refetchOnWindowFocus: false,
      enabled: !!currentLocation && !!vendorID,
    }
  );

  useEffect(() => {
    if (data?.data && data?.success) {
      setVendor(data?.data);
      setDeliveryDiscountInfo(data?.data?.delieveryDiscount);
    } else {
      setVendor(null);
    }
  }, [data]);

  useEffect(() => {
    if (vendor?.discountCap?.id) {
      setDiscountCap(vendor?.discountCap);
    } else {
      setDiscountCap(null);
    }
  }, [vendor]);

  useEffect(() => {
    SetPageLocation(location?.pathname);
    ga4.event("Page View", {
      items: [
        {
          PageView: location?.pathname,
        },
      ],
    });
    if (window && window.webengage)
      window.webengage.track("Page View", {
        PageView: location?.pathname,
      });
  }, [location]);

  useEffect(() => {
    getLastOrder().then((res) => {
      if (res.success) {
        setOrder(res.data);
        setIsRateModal(false);
      }
    });
  }, [isLogin]);

  return (
    <CustomErrorBoundary>
      <AppWrapper>
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<HomeScreen />} />
          <Route path="/components" element={<UiScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/profile" element={<UserProfileScreen />} />
          <Route path="/group/:id/:title" element={<GroupScreen />} />
          <Route path="/search" element={<SearchScreen />} />
          <Route path="/cart" element={<CartScreen />} />
          <Route path="/checkout" element={<CheckoutScreen />} />
          <Route path="/categories" element={<CategoriesScreen />} />
          <Route path="/category" element={<CategoryScreen />} />
          <Route path="/order/:id" element={<OrderDetialsScreen />} />
          <Route path="/orders" element={<OrdersScreen />} />
          <Route path="/referral" element={<ReferralScreen />} />
          <Route path="/comments" element={<CommentsScreen />} />
          <Route path="/invoice/:id" element={<InvoiceScreen />} />
          <Route path="/faivorate" element={<FaivorateScreen />} />
          <Route path="/verify/:type/:phone" element={<VerifyScreen />} />
          <Route path="voucher-center" element={<VoucherCenter />} />
          <Route path="/login-by-password" element={<LoginByPassScreen />} />
        </Routes>

        <BottomNavBar page={location.pathname} />
        <AppMenu />
        <ClientMap />
        <AlertMessage
          isOpen={isClose}
          onClose={() => {
            setIsClose(false);
          }}
          title="مغلق مؤقتاَ."
          msg={appCloseMsg}
        />
        <ItemDetials />
        <BasketBtn
          count={basket?.map((el) => el?.qty).reduce((a, b) => a + b, 0)}
        />
        <UserModal />
      </AppWrapper>
      <ConfirmationCancelModal />
      <ConfirmationCancelModalReasons />
      <BalyAlertMessage
        message={cancellationErrorMessage}
        type={"error"}
        isOpen={!!cancellationErrorMessage}
        onClose={() => {
          setCancellationErrorMessage(null);
        }}
      />
      <AlertMessage
        isOpen={!canUserProccedToCheckout}
        onClose={() => {
          setCanUserProccedToCheckout(true);
        }}
        title="تنبيه"
        msg={"المجهز غير متاح في العنوان الحالي"}
      />
      <VoucherModalContainer />
    </CustomErrorBoundary>
  );
}

export default App;
