import Screen from "../components/Global/Screen";
import ChildHeader from "../components/Global/ChildHeader";
import { Helmet } from "react-helmet";
import Text from "../components/Global/Text";
import Space from "../components/Global/Space";
import { BalyColck, BalySend } from "../components/BalyIcons";
import Container from "../components/Global/Container";
import { StatusBar } from "../components/Global/StatusBar";
import PureCard from "../components/Global/Card";
import PureButtonIcon from "../components/Global/ButtonIcon";
import { BikerCard } from "../components/OrderDetials";
import InvoiceInfo from "../components/Global/Invoice";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getOrderInfo } from "../services";
import { parseExpectedTime, parseInvoice } from "../helper";
import { CancellationCTA } from "../components/Screens/OrderCancellation/CancellationCTA/CancellationCTA";
import { useCancellationStore } from "../components/Screens/OrderCancellation/store";
import { BalyAlertMessage } from "../components/Global/BalyAlertMessage";

const OrderDetialsScreen = () => {
  const [order, setOrder] = useState(null);
  const [statusMsg, setStatusMsg] = useState("طلبك قيد الموافقة.");
  const { id } = useParams();
  const { setOrderId, cancellationErrorMessage, setCancellationErrorMessage } =
    useCancellationStore();

  const { data, isLoading, refetch } = useQuery(
    [`order-info`, Number(id)],
    getOrderInfo,
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (data?.data && data?.success) {
      setOrder(data?.data);
    }
  }, [data]);

  useEffect(() => {
    setOrderId(id);
  }, [id]);

  useEffect(() => {
    let intervalId = setInterval(() => {
      refetch();
    }, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>تتبع و تفاصيل الطلب</title>
      </Helmet>
      <ChildHeader style={{ zIndex: 9999999 }} title={"متابعة الطلب"} />
      <Screen>
        <Space height={16} />
        <Container>
          <div className="flex-center">
            <BalyColck size={14} outline color="#9A9A9A" />
            <Space width={8} />
            <Text size={12} color="#9A9A9A">
              سيصلك الطلب خلال
            </Text>
          </div>
          <Space height={8} />
          <Text size={24} bold>
            {parseExpectedTime(order?.expectedDeliveryTime)}
          </Text>
          <Space height={8} />
          <div className="flex-space">
            <Text size={16} color="#666">
              {statusMsg}
            </Text>
            <Text size={16} color="#666">
              {order?.trackNo && `رمز التتبع: ${order?.trackNo}`}
            </Text>
          </div>
          <Space height={6} />
          <StatusBar
            orderId={order?.id}
            status={order?.lastStatus || "PENDING"}
            onChange={(msg) => setStatusMsg(msg)}
          />
          <Space height={16} />
          <PureCard style={{ paddingLeft: 10 }}>
            <div className="flex-space">
              <Text size={18}>تحدث مع مركز الدعم</Text>
              <PureButtonIcon
                onClick={() => {
                  window?.$crisp?.push([
                    "set",
                    "session:data",
                    [
                      [
                        [
                          "order",
                          `https://${
                            process.env.REACT_APP_IS_DEV
                              ? "back.baly.dev"
                              : "back.food.baly.iq"
                          }/order/${order?.id}`,
                        ],
                        ["vendor", order?.vendor],
                        ["user_id", order?.clientID],
                        ["biker_phone", order?.biker_phone || ""],
                        [
                          "item_name",
                          order?.orderDetail
                            ?.map((el) => el?.product?.product)
                            .join(", ") || "",
                        ],
                        ["sub_total", order?.subTotal || ""],
                        [
                          "total",
                          order?.totalValue -
                            order?.discountTotalValue -
                            order?.voucherTotalValue || "",
                        ],
                        ["discount", order?.discountTotalValue || ""],
                        ["voucher_code", order?.voucher_code || ""],
                        [
                          "order_status",
                          JSON.parse(order?.status)?.pop().arTitle || "",
                        ],
                        [
                          "estimated_delivery_time",
                          order?.expectedDeliveryTime || "",
                        ],
                      ],
                    ],
                  ]);
                  window?.$crisp?.push(["do", "chat:open"]);
                }}
              >
                <BalySend />
              </PureButtonIcon>
            </div>
          </PureCard>
          <Space height={16} />
          <BikerCard
            info={{
              name: order?.biker_name,
              img: order?.biker_img,
              phone: order?.biker_phone,
            }}
          />
          <Space height={22} />
          <InvoiceInfo
            loading={isLoading}
            invoice={parseInvoice(data?.data?.orderDetail)}
            order={order}
          />

          <div className="floating-cancellation-button">
            {!!data?.data?.CreatedAt && (
              <CancellationCTA
                orderCreatedTime={data?.data?.CreatedAt}
                order={data?.data}
              />
            )}
          </div>
        </Container>
        <Space height={60} />
      </Screen>
    </>
  );
};
export default OrderDetialsScreen;
