export const BalyDots = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size / 5}
      viewBox="0 0 25 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="2.45463" cy="2.45463" r="2.45463" fill={color} />
      <circle cx="11.3638" cy="2.45463" r="2.45463" fill={color} />
      <circle cx="20.2732" cy="2.45463" r="2.45463" fill={color} />
    </svg>
  );
};
