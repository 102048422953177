export const BalyFilter = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 6L16 6"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 18L16 18"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 12L20 12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 12L8 12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="18" cy="6" r="2" stroke={color} strokeWidth="2" />
      <circle cx="18" cy="18" r="2" stroke={color} strokeWidth="2" />
      <circle cx="10" cy="12" r="2" stroke={color} strokeWidth="2" />
    </svg>
  );
};
