import Screen from "../components/Global/Screen";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GoToLink } from "../helper";
import { Toaster, toast } from "react-hot-toast";
import { register } from "../services";
import { useState } from "react";
import { useMutation } from "react-query";
import copy from "copy-to-clipboard";
import { Helmet } from "react-helmet";
import PureButton from "../components/Global/Button";
import ChildHeader from "../components/Global/ChildHeader";
import ga4 from "react-ga4";
import { useAppStore } from "../store";

const LoginScreen = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [searchParams] = useSearchParams();
  const { device } = useAppStore();
  const ArToEn = (numb) => {
    return numb.replace(/[\u0660-\u0669]/g, (d) => d.charCodeAt() - 1632);
  };

  const { mutate, isLoading } = useMutation(register, {
    onSuccess: (data) => {
      if (data.success) {
        if (data.data.hasPass) {
          navigate(`/login-by-password/?phone=${ArToEn(phone)}`, {
            replace: true,
            state: { otp: data.data.otp },
          });
          handleWebengage(data?.data?.type, data?.data?.user, data?.data?.os);
        } else {
          copy(data.data.otp);
          searchParams
            ? GoToLink(() =>
                navigate(
                  `/verify/${data.data.status}/${ArToEn(
                    phone
                  )}?${searchParams}`,
                  {
                    replace: true,
                    state: { otp: data.data.otp },
                  }
                )
              )
            : GoToLink(() =>
                navigate(`/verify/${data.data.status}/${ArToEn(phone)}`, {
                  replace: true,
                  state: { otp: data.data.otp },
                })
              );
        }
      } else {
        toast.error(data.msg);
      }
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const phoneValidate = () => {
    const regex = /^[\u0621-\u064A\u0660-\u0669]+|07[3-9]\d{1,11}$/;
    const result = regex.exec(ArToEn(phone));
    return result;
  };

  const handleRegister = async () => {
    try {
      if (phoneValidate()) {
        window.grecaptcha.enterprise.ready(async () => {
          const token = await window.grecaptcha.enterprise.execute(
            process.env.REACT_APP_RECAPTCHA_SITE_KEY,
            { action: "LOGIN" }
          );
          mutate({ phone: ArToEn(phone), stoken: token });
        });
      } else {
        toast.error("رقم الهاتف غير صحيح!");
      }
    } catch (err) {
      console.error(`LoginScreen handleRegister: ${err}`);
    }
  };
  const handleWebengage = (type, user, OS) => {
    if (!type || !user) return;
    if (type === "login") {
      ga4.event("Grocery Login", {
        items: [
          {
            channel: "SSO",
            source: device,
            OS: OS,
          },
        ],
      });
      if (window && window.webengage)
        window.webengage.track("Grocery Login", {
          channel: "SSO",
          source: device,
          OS: OS,
        });
    }
    if (type === "register") {
      ga4.event("Grocery Signup", {
        items: [
          {
            channel: "SSO",
            source: device,
            OS: OS,
          },
        ],
      });
      if (window && window.webengage)
        window.webengage.track("Grocery Signup", {
          channel: "SSO",
          source: device,
          OS: OS,
        });
    }
  };
  return (
    <>
      <Helmet>
        <title>انشاء حساب</title>
      </Helmet>
      <ChildHeader></ChildHeader>
      <Screen>
        <Toaster position="top-center" reverseOrder={false} />
        <div className="login app-container">
          <section className="card">
            <p className="title">أدخل رقم الهاتف</p>
            <span className="desc">تأكد من ادخال رقم هاتف فعال للاستمرار</span>
            <div className="phone-input-wrapper">
              <input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="tel"
                placeholder="07xxxxxxxxx"
                maxLength="11"
                minLength="11"
                pattern="/^07[3-9]"
                className="phone-input"
              />
            </div>
          </section>
          <section className="confirm-box app-container">
            <PureButton
              type={
                !phoneValidate() ||
                phone.length !== 11 ||
                phoneValidate()?.index !== 0
                  ? "secondary"
                  : "primary"
              }
              block
              disabled={
                !phoneValidate() ||
                phone.length !== 11 ||
                phoneValidate()?.index !== 0
              }
              onClick={handleRegister}
              style={{ width: "100%" }}
              className={"primary-btn"}
            >
              {isLoading ? (
                <div className="btn-loading"></div>
              ) : (
                "تأكيد رقم الهاتف"
              )}
            </PureButton>
          </section>
        </div>
      </Screen>
    </>
  );
};

export default LoginScreen;
