import "./style.css";
import { Empty } from "../../Global/Empty";
import { useNavigate } from "react-router-dom";
import { GoToLink } from "../../../helper";
import CommentCard from "../CommentCard";

export const CommentsList = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className="faivorate-list my-comments">
      <div className="app-container">
        <ul>
          {data && data?.length > 0 ? (
            data &&
            data?.map((el) => (
              <li key={el.id}>
                <CommentCard item={el} />
              </li>
            ))
          ) : (
            <div style={{ marginTop: "38vh" }}>
              <Empty
                screen={"COMMENTS"}
                action={() => GoToLink(() => navigate("/"))}
                actionText="اذهب الى الرئيسية"
                title="لايوجد تعليقات"
                msg={"قم بوضع طلب جديد, وقيمه لكي يظهر تعليقك في هذه الصفحة"}
              />
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};
