import Ripples from "react-ripples";
import "./style.css";

const PureButtonIcon = ({
  children,
  radius = 8,
  disabled = false,
  background = "transparent",
  margin = "-5px 0px",
  onClick = () => {},
}) => {
  return (
    <div
      className="btn-icon-container"
      style={{ borderRadius: radius, background, margin }}
    >
      <Ripples>
        <button
          disabled={disabled}
          className={`pure-btn-icon`}
          style={{ borderRadius: radius }}
          onClick={onClick}
        >
          {children}
        </button>
      </Ripples>
    </div>
  );
};

export default PureButtonIcon;
