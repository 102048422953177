export const BalyStarFill = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 12 13`}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.44519 1.70746C6.61984 1.17002 7.38016 1.17002 7.55481 1.70746L8.44066 4.43382C8.51871 4.67416 8.74271 4.83689 8.99541 4.83689H11.8621C12.4272 4.83689 12.6621 5.56 12.205 5.89213L9.88575 7.57715C9.68129 7.72566 9.59578 7.98898 9.67388 8.22931L10.5597 10.9557C10.7343 11.4931 10.1192 11.94 9.66204 11.6079L7.34288 9.92291C7.13843 9.77433 6.86158 9.77433 6.65712 9.92291L4.33794 11.6079C3.88078 11.94 3.26566 11.4931 3.44028 10.9557L4.32613 8.22931C4.40423 7.98898 4.31867 7.72566 4.11423 7.57715L1.79505 5.89213C1.33788 5.56 1.57283 4.83689 2.13792 4.83689H5.00459C5.2573 4.83689 5.48127 4.67416 5.55937 4.43382L6.44519 1.70746Z"
        fill={color}
      />
    </svg>
  );
};
