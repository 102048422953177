import "./style.css";
import Text from "../../Global/Text";
import QtButton from "../../Global/QtButton";
import { useAppStore } from "../../../store";
import { handleBasket } from "../../Items/Detials";
import Truncate from "react-truncate";
import Checkbox from "../../Global/Checkbox";
import bagImg from "../../../assets/images/empty2.jpg";
import { toast } from "react-hot-toast";
import { WebengageForAddRemove } from "../../../helper/AddRemoveItem";
import { getPrices } from "../../Global/Invoice";
export const CartItems = ({ isDelete }) => {
  const {
    basket,
    setBasket,
    trashList,
    setTrashList,
    appClose,
    isLogin,
    isSuperApp,
    cartAvalibleQTY,
    voucher,
    PageLocation,
  } = useAppStore();
  const { total, subTotal } = getPrices(basket, voucher);
  const handleSelecte = (index) => {
    let isTrash = trashList?.find((item) => `item-${index}` === item);
    if (isTrash) {
      setTrashList(trashList?.filter((item) => item !== `item-${index}`));
    } else {
      setTrashList([...trashList, `item-${index}`]);
    }
  };
  return (
    <div className="cart-items-list">
      {basket?.map((el, i) => (
        <div className="cart-item" key={i}>
          {isDelete && (
            <Checkbox
              checked={
                trashList?.find((item) => `item-${i}` === item) ? true : false
              }
              onChange={() => handleSelecte(i)}
            />
          )}
          <div
            className={`cart-item-img ${
              isDelete ? "cart-item-img-trash" : "cart-item-img-trash-close"
            }`}
          >
            <img
              src={el?.item?.imgs?.length > 0 ? el?.item?.imgs[0] : bagImg}
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = bagImg;
              }}
            />
          </div>
          <div className="cart-item-info">
            <div
              className={`info ${isDelete ? "info-trash" : "info-trash-close"}`}
            >
              <Text size={18}>
                <Truncate lines={1} ellipsis={<span>...</span>}>
                  {`${el?.item?.product} ${el?.item?.name || ""} ${
                    el?.item?.size ? el?.item?.size : ""
                  }`}
                </Truncate>
              </Text>
              {el?.item?.price > el?.item?.endprice && (
                <Text color="#666" lineThrough size={14}>
                  {`${Number(el?.item?.price * el?.qty).toLocaleString(
                    "en"
                  )} د.ع`}
                </Text>
              )}
              <Text bold size={16}>
                {`${Number(el?.item?.endprice * el?.qty).toLocaleString(
                  "en"
                )} د.ع`}
              </Text>
            </div>
            {!isDelete && (
              <QtButton
                value={el?.qty}
                disabledIncrease={appClose}
                small
                onChange={(value, option) => {
                  let LiveAvalibleQTY = cartAvalibleQTY
                    ?.filter((item) => item?.id === el?.item?.id)
                    .map((item) => item?.availableQty);

                  if (value > LiveAvalibleQTY[0]) {
                    toast.error("عذرا، نفذت الكمية!");
                    handleBasket({
                      basket,
                      item: el?.item,
                      qty: LiveAvalibleQTY[0],
                      cb: (newBasket) => {
                        setBasket(newBasket);
                        localStorage.setItem(
                          "grocery_basket",
                          JSON.stringify(newBasket)
                        );
                      },
                    });
                  } else {
                    handleBasket({
                      basket,
                      item: el?.item,
                      qty: value,
                      cb: (newBasket) => {
                        setBasket(newBasket);
                        localStorage.setItem(
                          "grocery_basket",
                          JSON.stringify(newBasket)
                        );
                      },
                    });
                  }
                  let addReomve = option === "-" ? "remove" : "add";
                  WebengageForAddRemove(
                    el?.item,
                    addReomve,
                    value,
                    isLogin,
                    isSuperApp,
                    total,
                    subTotal,
                    PageLocation
                  );
                }}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
