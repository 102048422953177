import { BalyCalendar, BalyLocation } from "../../../BalyIcons";
import { TiStarFullOutline, TiStarOutline } from "react-icons/ti";
import { BsClock } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import Text from "../../Text";
import { Rating } from "react-simple-star-rating";
import "./style.css";
import Reasions from "../Reasions";
import { useRateStore } from "../store";
import bikerImg from "../../../../assets/images/biker-empty.svg";
import {
  AiOutlineLike,
  AiOutlineDislike,
  AiFillDislike,
  AiFillLike,
} from "react-icons/ai";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { submitVendorReview } from "../../../../services";
import { getBikerModalHeight } from "../../../../helper/biker-review";
import queryClient from "../../../../queryClient";

const ProductCard = ({ product }) => {
  const { setProducts, products } = useRateStore();

  const handleRate = (rate) => {
    let obj = {
      productID: product?.id,
      rate,
    };

    let index = products?.findIndex((el) => el?.productID === product?.id);
    if (index === -1) setProducts([...products, obj]);
    else {
      if (products[index]?.rate === rate)
        setProducts(
          products?.filter((el) => el?.productID !== products[index]?.productID)
        );
      else {
        products[index] = obj;
        setProducts(products);
      }
    }
  };

  const isDilike = () => {
    let status = false;
    let data = products?.find((el) => el?.productID === product?.id);

    if (data && data?.rate === 0) status = true;

    return status;
  };

  const islike = () => {
    let status = false;
    let data = products?.find((el) => el?.productID === product?.id);

    if (data && data?.rate === 5) status = true;

    return status;
  };

  return (
    <div className="product-card">
      <div className="img">
        <img
          src={(product?.img != null && JSON.parse(product?.img)[0]) || ""}
        />
      </div>
      <div className="review">
        <button onClick={() => handleRate(0)}>
          {isDilike() ? (
            <AiFillDislike
              size={16}
              color="#FF0000"
              className="__dislike-icon"
            />
          ) : (
            <AiOutlineDislike size={16} className="__dislike-icon" />
          )}
        </button>
        <span />
        <button onClick={() => handleRate(5)}>
          {islike() ? (
            <AiFillLike size={16} color="#01B248" />
          ) : (
            <AiOutlineLike size={16} />
          )}
        </button>
      </div>
      <p style={{ display: "block", marginTop: 8 }}>
        {product?.product} {product?.name}
      </p>
    </div>
  );
};

let reasonsList = [];

const VendorRating = () => {
  const {
    vendorRate,
    setVendorRate,
    setView,
    view,
    bikerRate,
    order,
    productComment,
    setProductComment,
    products,
    reasons,
    selectedReasons,
    setIsRateModal,
    setOrder,
    setComment,
    setSelectedReasons,
  } = useRateStore();

  useEffect(() => {
    const reasonsType = reasons?.filter(
      (el) => parseInt(el.rating) === vendorRate
    );
    reasonsList = reasonsType[0]?.reasons;
  }, [vendorRate, reasons]);

  let ETA_DATE = `${dayjs(order?.CreatedAt).format("DD-MM-YYYY")}`;
  let ETA_TIME = `${dayjs(order?.CreatedAt).add(10, "m").format("hh:mm A")}`;

  const { mutate, isLoading } = useMutation(submitVendorReview, {
    onSuccess: async (data) => {
      if (data.success) {
        setView({ step: "FINISH", height: 428 });
        setOrder(null);
        setVendorRate(null);
        setComment(null);
        setProductComment(null);
        setSelectedReasons([]);
        await queryClient.invalidateQueries("order-history");
        localStorage.removeItem("pwa_orderId");
        localStorage.removeItem("pwa_hidden_orderId");
      }
    },
  });

  const handelSubmit = () => {
    mutate({
      vendorID: order?.vendorID,
      orderID: order?.id,
      comment: productComment,
      rate: vendorRate,
      reason: JSON.stringify(selectedReasons),
      products,
    });
  };

  return (
    <div
      className="vendor"
      style={{ height: getBikerModalHeight(view), overflow: "auto" }}
    >
      <section className="head">
        <p className="title">ما هو رأيك بالطعام؟</p>
        <button className="skip-btn" onClick={() => setIsRateModal(false)}>
          تخطي
        </button>
      </section>
      <div className="header-shadow"></div>

      <section className="body">
        <div className="avatars">
          <img src={"./logo.png"} />
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = bikerImg;
            }}
            src={order?.biker_img || bikerImg}
          />
        </div>
        <b>بلي مارت</b>
        <div className="info">
          <div className="item">
            <BalyCalendar size={14} color="#A9A9A9" style={{ marginLeft: 8 }} />
            <Text size={12} color="#A9A9A9">
              {ETA_DATE}
            </Text>
          </div>
          <div className="item">
            <BsClock size={14} color="#A9A9A9" style={{ marginLeft: 8 }} />
            <Text size={12} color="#A9A9A9">
              <span
                style={{
                  textAlign: "left",
                }}
              >
                <span style={{ display: "block", direction: "ltr" }}>
                  {ETA_TIME}
                </span>
              </span>
            </Text>
          </div>
          <div className="item">
            <BalyLocation size={16} color="#A9A9A9" style={{ marginLeft: 8 }} />
            <Text ellipsis width={80} size={12} color="#A9A9A9">
              {order?.clientAddress}
            </Text>
          </div>
        </div>
        <div className="rate-star">
          <Rating
            allowHalfIcon={true}
            fullIcon={<TiStarFullOutline size={40} />}
            emptyIcon={<TiStarOutline size={40} />}
            transition={true}
            rtl={false}
            emptyColor="#ccc"
            ratingValue={vendorRate / 0.5}
            initialValue={vendorRate}
            onClick={(val) => {
              setVendorRate(val);
              setView({ ...view, height: val === null ? 428 : 720 });
            }}
          />
        </div>

        {vendorRate !== null && (
          <Reasions
            value={productComment}
            onChange={setProductComment}
            title={
              vendorRate <= 4
                ? "ما الذي يمكننا تحسينه؟"
                : "ماذا اعجبك, شاركنا تجربتك؟"
            }
            placeholder={
              vendorRate <= 4 ? "قم باضافة ملاحظاتك ..." : "أكتب تقييمك للطلب"
            }
            list={reasonsList}
          />
        )}
      </section>
      {vendorRate !== null && (
        <section className="products">
          {order?.orderDetail?.map((el, i) => {
            return <ProductCard key={i} product={el?.product} />;
          })}
        </section>
      )}

      <section className="actions">
        <button
          onClick={() =>
            setView({
              step: "BIKER",
              height: bikerRate === false ? 720 : 428,
            })
          }
          className="back-btn"
        >
          <IoIosArrowForward size={24} />
        </button>
        {vendorRate !== null && (
          <button
            onClick={handelSubmit}
            className="primary"
            disabled={isLoading}
          >
            {isLoading ? "جاري الأرسال" : "ارسال التقييم"}
          </button>
        )}
      </section>
    </div>
  );
};

export default VendorRating;
