import Ripples from "react-ripples";
import "./style.css";
const Touchable = (props) => {
  return (
    <div className="touchable-ripple" >
      <Ripples {...props}>{props.children}</Ripples>
    </div>
  );
};

export default Touchable;
