import "./style.css";

const ImgGrid = ({ images = [] }) => {
  const gridSize = images?.length >= 4 ? 4 : images?.length;
  return (
    <div className="img-grid-container">
      <div className={`img-grid img-grid-${gridSize}`}>
        {gridSize < 3 ? (
          <div className="img">
            {gridSize > 1 && (
              <div className="img-grid-overlay">{images?.length - 1}+</div>
            )}
            {images[0] && <img src={images[0]} alt="product images" />}
          </div>
        ) : (
          [0, 1, 2, 3].map((_, i) => {
            return (
              images[i] && (
                <div className="img" key={i}>
                  {i === 3 && images?.length > 4 && (
                    <div className="img-grid-overlay">
                      {images?.length - 3}+
                    </div>
                  )}
                  {images[i] && <img src={images[i]} alt="product images" />}
                </div>
              )
            );
          })
        )}
      </div>
    </div>
  );
};

export default ImgGrid;
