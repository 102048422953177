import ga4 from "react-ga4";

export const WebengageForAddRemove = (
  item,
  addReomve,
  value,
  isLogin,
  isSuperApp,
  PageLocation
) => {
  let checkValue = value >= 0 ? true : false;
  if (isLogin && !isSuperApp && item && checkValue && addReomve === "add") {
    ga4.event("shopping_cart_add_product", {
      Page: PageLocation,
      ProductID: item?.id,
      Category: item?.category,
      Price: item?.price,
      VendorID: item?.vID,
      VendorName: item?.vendorName,
      //fix this :  missing vendor name and category
    });

    if (window && window.webengage)
      window.webengage.track("Grocery Add To Cart", {
        Page: PageLocation,
        ProductID: item?.id,
        Category: item?.category,
        Price: item?.price,
        VendorID: item?.vID,
        VendorName: item?.vendorName,
      });
  }

  if (isLogin && !isSuperApp && item && checkValue && addReomve === "remove") {
    ga4.event("shopping_cart_remove_product", {
      Page: PageLocation,
      ProductID: item?.id,
      Category: item?.category,
      Price: item?.price,
      VendorID: item?.vID,
      VendorName: item?.vendorName,
    });

    if (window && window.webengage)
      window.webengage.track("Grocery Remove From Cart", {
        Page: PageLocation,
        ProductID: item?.id,
        Category: item?.category,
        Price: item?.price,
        VendorID: item?.vID,
        VendorName: item?.vendorName,
      });
  }
};
