import "./style.css";
import Text from "../../Global/Text";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getCatogeries } from "../../../services";
import CatAvatarSkeleton from "./Skeleton";
import bagImg from "../../../assets/images/empty2.jpg";
import { useAppStore } from "../../../store";
import { Helmet } from "react-helmet";

export const CatHorizontalList = ({ onSelect, value }) => {
  const [selected, setSelected] = useState(value);
  const [selectedPageTitle, setSelectedPageTitle] = useState("");
  const { vendorID } = useAppStore();

  useEffect(() => {
    const currentCategory = data?.data?.find((el) => el.id === selected);
    selected && onSelect(currentCategory);
    setSelectedPageTitle(currentCategory?.name);
  }, [selected]);

  const { data, isLoading } = useQuery(
    ["categories", vendorID],
    getCatogeries,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (value) {
      let elemFilter = document?.getElementById(`cat-${value}`);
      elemFilter?.scrollIntoView({
        block: "nearest",
      });
    }
  }, [value]);

  return isLoading ? (
    <CatAvatarSkeleton />
  ) : (
    <>
      <Helmet>
        <title>التصنيف - {selectedPageTitle}</title>
      </Helmet>
      <div className="cat-h-list-scroll">
        <div className="list">
          {data?.data?.map((el, i) => (
            <div
              id={`cat-${el?.id}`}
              key={i}
              className={`cat-h-item ${
                selected === el?.id && "cat-h-item-active"
              }`}
              onClick={() => setSelected(el?.id)}
            >
              <Text size={14} color={selected === el?.id ? "#0000ff" : "#000"}>
                {el?.name}
              </Text>
            </div>
          ))}
        </div>
      </div>
      <div className="cat-h-list">
        <div className="list">
          {data?.data?.map((el, i) => (
            <div
              id={`cat-${el?.id}`}
              key={i}
              className="cat-h-item"
              onClick={() => setSelected(el?.id)}
            >
              <div className="cat-h-avatar">
                <img
                  src={el?.image}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = bagImg;
                  }}
                />
              </div>
              <Text size={14} color={selected === el?.id ? "#0000ff" : "#000"}>
                {el?.name}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
