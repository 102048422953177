import { useMutation } from "react-query";
import { BalyClose } from "../../../BalyIcons";
import PureButton from "../../../Global/Button";
import { PureModalSheet } from "../../../Global/PureModalSheet";
import Space from "../../../Global/Space";
import Text from "../../../Global/Text";
import { useCancellationStore } from "../store";
import "./cancelModal.css";
import { declineReason } from "../../../../services";
import RadioButton from "../../../Global/RadioButton";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

export const ConfirmationCancelModalReasons = () => {
  const navigate = useNavigate();
  const {
    setCancellationErrorMessage,
    setCancellationReasons,
    isCancelModalOpen,
    setIsCancelModalOpen,
    cancellationReasons,
    setOtherReason,
    otherReason,
    orderId,
    setCancellationReasonSelected,
    selectedCancellationReason,
  } = useCancellationStore();

  const otherInputRef = useRef(null);

  const scrollToOtherInput = () => {
    setTimeout(() => {
      otherInputRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const onModalClose = () => {
    setIsCancelModalOpen(false);
    navigate("/");
  };

  const { mutate, isLoading } = useMutation(declineReason, {
    onSuccess: (data) => {
      if (!data?.success) {
        setIsCancelModalOpen(false);
        setCancellationErrorMessage(data.err);
        setOtherReason("");
        return;
      }

      setCancellationReasons(data?.data);
      setIsCancelModalOpen(false);
      setCancellationReasonSelected({});
      setOtherReason("");
      navigate("/");
    },
  });

  const handleDeclineReasonChange = (reason) => {
    setCancellationReasonSelected(reason);

    if (reason?.id === 0) {
      scrollToOtherInput();
    }
  };

  const onSubmitReason = () => {
    mutate({
      id: parseInt(orderId),
      declineReasonID: selectedCancellationReason?.id,
      declineReasonComment:
        selectedCancellationReason?.id === 0 ? otherReason : null,
    });
  };

  return (
    <>
      <PureModalSheet
        isOpen={isCancelModalOpen}
        onClose={onModalClose}
        viewHeight={572}
      >
        <div
          className="cancel-modal-content"
          style={{
            boxSizing: "border-box",
          }}
        >
          <div className="cancel-modal-header">
            <Text size={18} bold>
              تم الغاء الطلب, ساعدنا في تحسين التجربة
            </Text>
            <PureButton
              onClick={onModalClose}
              styles={{ background: "#ffffff", border: 0, padding: "0px 8px" }}
            >
              <BalyClose size={24} />
            </PureButton>
          </div>
          <Space height={8} />
          <div className="cancel-modal-description reasons-list">
            {cancellationReasons &&
              cancellationReasons.map((reason, key) => (
                <div key={key} className="radio-button-list">
                  <RadioButton
                    id={"declineReasons-" + key}
                    name={"declineReasons"}
                    text={reason?.arTitle}
                    onChange={() => {
                      handleDeclineReasonChange(reason);
                    }}
                    value={reason?.id}
                    checked={reason?.id === selectedCancellationReason?.id}
                    textSize={16}
                    labelStyle={{
                      height: 24,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                </div>
              ))}
            <div className="radio-button-list other">
              <RadioButton
                id={"declineReasons-others"}
                name={"declineReasons"}
                text={"اخرى"}
                onChange={() => {
                  handleDeclineReasonChange({
                    id: 0,
                    declineReasonID: 0,
                  });
                }}
                value={0}
                checked={selectedCancellationReason?.id === 0}
              />
              {selectedCancellationReason?.id === 0 && (
                <input
                  className="decline-reason-other-input"
                  placeholder="سبب الغاء الطلب"
                  onChange={(e) => setOtherReason(e.target.value)}
                  value={otherReason}
                  ref={otherInputRef}
                  autoFocus={selectedCancellationReason?.id === 0}
                />
              )}
            </div>
          </div>
          <div className="cancel-modal-actions reason">
            <PureButton
              styles={{
                minWidth: 170,
              }}
              radius={33}
              onClick={onSubmitReason}
              block
              type="primary"
              disabled={
                !("id" in selectedCancellationReason) ||
                (selectedCancellationReason?.id === 0 && otherReason === "")
              }
            >
              ارسال السبب
            </PureButton>
            <Space height={24} />
          </div>
        </div>
      </PureModalSheet>
    </>
  );
};
