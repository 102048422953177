import PureButton from "../../../Global/Button";
import { Copy } from "../../../BalyIcons";
import "./voucherModalItem.css";
import Text from "../../../Global/Text";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";

export const VoucherModalItem = ({
  icon,
  title,
  value,
  hasCopy,
  shouldAlignCenter = false,
}) => {
  return (
    !!value && (
      <div
        className={`voucher_modal_item ${shouldAlignCenter && "align-center"}`}
      >
        <div className="title_side">
          {icon}{" "}
          <div className="title">
            <Text size={16} color="#666666">
              {title}
            </Text>
          </div>
        </div>
        <div className="value_side">
          <Text bold size={16}>
            {value}
          </Text>
          {hasCopy ? (
            <PureButton
              type="accent"
              small
              radius={30}
              className={"hasCopy"}
              onClick={() => {
                copy(value);
                toast.success("تم نسخ رمز الخصم");
              }}
            >
              <Copy color="#0000FF" size={12} />
              <div> نسخ الرمز</div>
            </PureButton>
          ) : null}
        </div>
      </div>
    )
  );
};
