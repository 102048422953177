import "./style.css";
import { BalyClose } from "../../BalyIcons";
import bikerImg from "../../../assets/images/biker-empty.svg";
import { useRateStore } from "../../Global/RatingModal/store";
import { useQuery } from "react-query";
import { getLastOrder } from "../../../services";
import { useEffect } from "react";

const RatingCard = ({ item }) => {
  const { setIsRateModal, isRateModel, setOrder } = useRateStore();

  const hiddenReview = () => {
    localStorage.setItem("pwa_hidden_orderId", item?.id);
    setIsRateModal(false);
    setOrder(null);
  };

  const {data} = useQuery("order.last", getLastOrder, {
    enabled: isRateModel
  });

  useEffect(() => {
    if(data && data.success) {
      setOrder(data.data);
    }
  }, [data]);

  return (
    item && (
      <div className="order-rating-card">
        <div className="close-card" onClick={hiddenReview}>
          <BalyClose color="#4A5568" />
        </div>
        <section className="images-review">
          <div className="img">
            <img src={"./logo.png"} />
          </div>
          <div className="img">
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = bikerImg;
              }}
              src={item?.biker_img || bikerImg}
            />
          </div>
        </section>
        <b
          className="title"
          style={{
            marginBottom: 8,
          }}
        >
          كيف كانت تجربتك ؟
        </b>
        <button
          onClick={() => {
            setOrder(item);
            setIsRateModal(true);
          }}
        >
          قيم تجربة الطلب
        </button>
      </div>
    )
  );
};

export default RatingCard;
