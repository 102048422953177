import { lazy, Suspense } from "react";
import ScreenContainer from "../components/Global/Screen";
import { useAppStore } from "../store";
import { Empty } from "../components/Global/Empty";
import { useNavigate } from "react-router-dom";
import ChildHeader from "../components/Global/ChildHeader";
import { Helmet } from "react-helmet";

const ReferralView = lazy(() => import("../components/Refferal/View"));

const ReferralScreen = () => {
  const { user } = useAppStore();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>مشاركة بلي</title>
      </Helmet>
      <ChildHeader title="شارك مع اصدقائك" />

      <ScreenContainer>
        <Suspense fallback={<div className="loader"></div>}>
          <div className="faivorate-screen">
            {user ? (
              <ReferralView />
            ) : (
              <Empty
                top={"35vh"}
                screen="HOME"
                title={"لم تقم بتسجيل دخولك"}
                msg={"يجب تسجيل الدخــول للمتابعة"}
                actionText={"تسجيل دخول / تسجيل"}
                action={() => navigate("/login?p=referral")}
              />
            )}
          </div>
        </Suspense>
      </ScreenContainer>
    </>
  );
};

export default ReferralScreen;
