export const BalyPhone = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.5 4.66667C2.5 3.74619 3.24619 3 4.16667 3H6.89937C7.25806 3 7.57651 3.22952 7.68993 3.56981L8.93808 7.31434C9.06925 7.70777 8.89117 8.13776 8.52025 8.32322L6.63917 9.26375C7.55771 11.301 9.199 12.9423 11.2362 13.8608L12.1767 11.9797C12.3622 11.6088 12.7922 11.4307 13.1857 11.5619L16.9302 12.8101C17.2705 12.9235 17.5 13.2419 17.5 13.6007V16.3333C17.5 17.2538 16.7538 18 15.8333 18H15C8.09644 18 2.5 12.4036 2.5 5.5V4.66667Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
