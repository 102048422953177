export const WarningIcon = ({ size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2866_11970)">
        <path
          d="M10 6.19043V9.99995M10 13.8095H10.0095"
          stroke="#F0AD01"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.26795 2C9.03775 0.666667 10.9623 0.666667 11.7321 2L18.6603 14C19.4301 15.3333 18.4678 17 16.9282 17H3.0718C1.5322 17 0.569946 15.3333 1.33975 14L8.26795 2Z"
          stroke="#F0AD01"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_2866_11970">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
