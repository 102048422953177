import "./style.css";
import { PureModalSheet } from "../../Global/PureModalSheet";
import Text from "../../Global/Text";
import PureButtonIcon from "../../Global/ButtonIcon";
import { BalyClose, BalyArrowDown } from "../../BalyIcons";
import Container from "../../Global/Container";
import Space from "../../Global/Space";
import PureCard from "../../Global/Card";
import { SwitchButton } from "../../Global/SwitchButton";
import { useState } from "react";
import RangeSlider from "../../Global/RangSlider";
import { Row, Col } from "../../Global/Grid";
import PureButton from "../../Global/Button";
import { useAppStore } from "../../../store";
import { useQuery } from "react-query";
import { getBrands, getBrandsFilter } from "../../../services";

const objToQueryString = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (!obj[key]) {
      delete obj[key];
    }
  });

  let _queryString = Object.keys(obj)
    .map((key) => key + "=" + obj[key])
    .join("&");
  return _queryString;
};

const TagRadio = ({ children, active = false, ...props }) => {
  return (
    <div className={`tag-radio ${active && "tag-radio-active"}`} {...props}>
      {children}
    </div>
  );
};

const ItemsFilter = ({
  isCategory = false,
  isFav = false,
  brandQuery = null,
  onFilter,
}) => {
  const [isCollaps, setIsCollaps] = useState(true);
  const [value, setValue] = useState({ min: 250, max: 50000 });
  const [discount, setDiscount] = useState(false);
  const [brands, setBrands] = useState([]);
  const { isFilter, setIsFilter, vendorID } = useAppStore();

  const { data } = useQuery(
    [
      `brands-${brandQuery}`,
      `${
        brandQuery ? `${brandQuery}&` : "?"
      }vendor-id=${vendorID}&min=0&max=1000000`,
    ],
    isCategory ? getBrandsFilter : getBrands,
    {
      refetchOnWindowFocus: false,
      enabled: (!!brandQuery || isFav) && !!vendorID,
    }
  );

  const handleBrand = (brandID) => {
    let brand = brands.find((_id) => _id === brandID);
    if (brand) {
      setBrands(brands?.filter((_id) => _id !== brandID));
    } else {
      setBrands([...brands, brandID]);
    }
  };

  const handleReset = () => {
    setBrands([]);
    setDiscount(false);
    setValue({ min: 250, max: 50000 });
    setIsFilter(false);
    onFilter("");
    window.history.go(-1);
  };

  const handleFilter = () => {
    let _obj = {
      discount,
      "brand-id": JSON.stringify(brands),
      min: value?.min,
      max: value?.max,
    };
    let _queryString = objToQueryString(_obj);
    onFilter(_queryString);
    setIsFilter(false);
    window.history.go(-1);
  };
  return (
    <PureModalSheet
      isOpen={isFilter}
      viewHeight={"auto"}
      onClose={() => setIsFilter(false)}
    >
      <div className="item-filter">
        <Space height={16} />
        <Container>
          <div className="flex-space">
            <Text bold size={18}>
              تصفية حسب
            </Text>
            <PureButtonIcon
              onClick={() => {
                setIsFilter(false);
                window.history.go(-1);
              }}
            >
              <BalyClose size={24} />
            </PureButtonIcon>
          </div>
        </Container>
        <section className="item-filter-content">
          <div>
            <Container>
              <Space height={21} />
              <PureCard>
                <div className="flex-space">
                  <Text size={18}>العروض الخاصة</Text>
                  <SwitchButton
                    value={discount}
                    onChange={(val) => setDiscount(val)}
                  />
                </div>
              </PureCard>
              <Space height={8} />
              {data?.data && data?.data?.length !== 0 && (
                <PureCard>
                  <div className="flex-space">
                    <Text size={18}>العلامات التجارية</Text>
                    <PureButtonIcon onClick={() => setIsCollaps(!isCollaps)}>
                      <BalyArrowDown
                        style={{
                          transform: `rotate(${!isCollaps ? "0" : "180deg"})`,
                        }}
                      />
                    </PureButtonIcon>
                  </div>
                  <div
                    className={`collaps-content ${
                      !isCollaps && "collaps-disable"
                    }`}
                  >
                    <Space height={16} />
                    <div className="filter-radio-list">
                      {data?.data?.map((el) => (
                        <TagRadio
                          key={el?.id}
                          active={brands.find((_id) => _id === el?.id)}
                          onClick={() => handleBrand(el?.id)}
                        >
                          {el?.name}
                        </TagRadio>
                      ))}
                    </div>
                  </div>
                </PureCard>
              )}

              <Space height={8} />
              <PureCard>
                <Text size={18}>السعر</Text>
                <Space height={16} />
                <RangeSlider
                  min={250}
                  max={50000}
                  step={50}
                  value={value}
                  onChange={setValue}
                />
                <div className="flex-space">
                  <Text>{`${Number(value?.max).toLocaleString(
                    "en"
                  )} د.ع`}</Text>
                  <Text>{`${Number(value?.min).toLocaleString(
                    "en"
                  )} د.ع`}</Text>
                </div>
              </PureCard>
            </Container>
          </div>
          <Space height={30} />
          <div>
            <Container>
              <Row>
                <Col flex={1}>
                  <PureButton type="secondary" block onClick={handleReset}>
                    حذف الفلتر
                  </PureButton>
                </Col>
                <Space width={10} />
                <Col flex={1}>
                  <PureButton type="primary" block onClick={handleFilter}>
                    تطبيق
                  </PureButton>
                </Col>
              </Row>
              <Space height={20} />
            </Container>
          </div>
        </section>
      </div>
    </PureModalSheet>
  );
};

export default ItemsFilter;
