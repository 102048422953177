export const BalyColck = ({
  size = 18,
  color = "#000",
  outline = false,
  ...props
}) => {
  return !outline ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 6C0.25 2.82436 2.82436 0.25 6 0.25C9.17566 0.25 11.75 2.82437 11.75 6C11.75 9.17566 9.17566 11.75 6 11.75C2.82437 11.75 0.25 9.17566 0.25 6ZM6.5 3.66663C6.5 3.39048 6.27614 3.16663 6 3.16663C5.72386 3.16663 5.5 3.39048 5.5 3.66663V5.99996C5.5 6.13257 5.55268 6.25974 5.64645 6.35351L7.39645 8.10351C7.59171 8.29877 7.90829 8.29877 8.10355 8.10351C8.29882 7.90825 8.29882 7.59167 8.10355 7.39641L6.5 5.79285V3.66663Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1725_25114)">
        <path
          d="M7 4.33333V7L9 9M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1725_25114">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
