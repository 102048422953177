import dayjs from "dayjs";

export const parseExpectedTime = (expectedDeliveryTime) => {
  let ETA = `${dayjs(expectedDeliveryTime).format("hh:mmA")} - ${dayjs(
    expectedDeliveryTime
  )
    .add(10, "m")
    .format("hh:mmA")}`;

  return ETA.replaceAll("PM", " م").replaceAll("AM", " ص");
};

export const parsePreviousETA = (expectedDeliveryTime, format) => {
  let ETA = dayjs(expectedDeliveryTime).format(format);

  const days = [
    "الأحد",
    "الاثنين",
    "الثلاثاء",
    "الأربعاء",
    "الخميس",
    "الجمعة",
    "السبت",
  ];

  ETA = ETA.replace(
    dayjs(expectedDeliveryTime).format("dddd"),
    days[dayjs(expectedDeliveryTime).day()]
  )
    .replaceAll("PM", " م")
    .replaceAll("AM", " ص");

  return ETA;
};
