export const FinishReview = ({ size = 18, color = "#000", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#F0AD01"
        fillOpacity={0.24}
        stroke="#F0AD01"
        strokeWidth={3}
        d="M51.705 3.208a.324.324 0 0 1 .568.151l1.045 6.012c.02.11.095.204.2.246l5.67 2.252a.324.324 0 0 1 .032.588l-5.394 2.851a.324.324 0 0 0-.172.266l-.39 6.089a.324.324 0 0 1-.55.212l-4.378-4.25a.324.324 0 0 0-.306-.081l-5.912 1.51a.324.324 0 0 1-.37-.456l2.688-5.478a.324.324 0 0 0-.018-.316L41.155 7.65a.324.324 0 0 1 .32-.494l6.04.864a.324.324 0 0 0 .295-.114l3.895-4.697ZM20.616 14.41a.324.324 0 0 1 .557-.19l4.204 4.422a.324.324 0 0 0 .302.093l5.968-1.271a.324.324 0 0 1 .352.47L29.093 23.3a.324.324 0 0 0 .005.316L32.15 28.9a.324.324 0 0 1-.34.48l-6-1.105a.324.324 0 0 0-.3.102l-4.08 4.536a.324.324 0 0 1-.562-.174l-.802-6.049a.324.324 0 0 0-.19-.253l-5.575-2.479a.324.324 0 0 1-.008-.588l5.505-2.632a.324.324 0 0 0 .182-.259l.634-6.068ZM22.326 65.54a.324.324 0 0 1 .583.08l1.768 5.84a.324.324 0 0 0 .228.22l5.902 1.547c.267.07.33.422.103.58l-5.008 3.485a.324.324 0 0 0-.139.285l.353 6.091a.324.324 0 0 1-.52.277l-4.862-3.685a.324.324 0 0 0-.313-.044l-5.685 2.218a.324.324 0 0 1-.424-.409l2.003-5.763a.324.324 0 0 0-.055-.312l-3.866-4.72a.324.324 0 0 1 .258-.53l6.1.124a.324.324 0 0 0 .28-.149l3.294-5.135ZM73.972 8.256a.324.324 0 0 1 .573-.135l3.76 4.805c.07.089.18.135.292.122l6.062-.693a.324.324 0 0 1 .306.503l-3.408 5.061a.324.324 0 0 0-.026.316l2.532 5.551a.324.324 0 0 1-.384.446l-5.867-1.677a.324.324 0 0 0-.308.073l-4.497 4.123a.324.324 0 0 1-.543-.227l-.217-6.098a.324.324 0 0 0-.165-.27l-5.311-3.003a.324.324 0 0 1 .048-.587l5.732-2.09a.324.324 0 0 0 .207-.24l1.215-5.98ZM11.873 39.527a.324.324 0 0 1 .589 0l2.555 5.54a.324.324 0 0 0 .257.187l6.059.718a.324.324 0 0 1 .182.56l-4.48 4.142a.324.324 0 0 0-.098.301l1.19 5.985a.324.324 0 0 1-.477.346l-5.324-2.98a.324.324 0 0 0-.317 0l-5.324 2.98a.324.324 0 0 1-.476-.346l1.189-5.985a.324.324 0 0 0-.098-.3l-4.48-4.143a.324.324 0 0 1 .182-.56l6.06-.718a.324.324 0 0 0 .256-.186l2.555-5.541Z"
      />
      <path
        fill="#00F"
        fillOpacity={0.08}
        stroke="#00F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M59.43 88.12S85.374 73.72 94.976 63.077c9.601-10.643 6.86-26.844 6.86-26.844s-16.029-3.614-27.176 5.397c-11.147 9.01-26.932 34.136-26.932 34.136L59.43 88.121Z"
      />
      <path
        fill="#F0AD01"
        fillOpacity={0.24}
        stroke="#F0AD01"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M32.175 102.216c13.513 2.68 21.163-11.422 21.163-11.422l-8.612-9.092c-.165.157-14.66 7.032-12.55 20.514Z"
      />
      <path
        fill="#00F"
        fillOpacity={0.08}
        d="M47.728 75.765 37.68 65.158S52.27 50.676 66.016 50.48"
      />
      <path
        stroke="#00F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M47.728 75.765 37.68 65.158S52.27 50.676 66.016 50.48"
      />
      <path
        fill="#00F"
        fillOpacity={0.08}
        d="m59.43 88.12 9.938 10.49s15.25-13.783 16.19-27.5"
      />
      <path
        stroke="#00F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="m59.43 88.12 9.938 10.49s15.25-13.783 16.19-27.5"
      />
      <path
        fill="#00F"
        fillOpacity={0.08}
        stroke="#00F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M77.707 59.087c3.11 3.283 8.45 3.276 11.926-.017 3.476-3.293 3.773-8.624.663-11.907-3.11-3.283-8.449-3.275-11.925.018-3.477 3.292-3.773 8.623-.664 11.906Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h105v105H.5z" />
      </clipPath>
    </defs>
  </svg>
);
