import Screen from "../components/Global/Screen";
import Space from "../components/Global/Space";
import Container from "../components/Global/Container";
import ChildHeader from "../components/Global/ChildHeader";
import { BalyTrash } from "../components/BalyIcons";
import PureButtonIcon from "../components/Global/ButtonIcon";
import { CartItems, CartNote, CartCTA, LoginModal } from "../components/Cart";
import { ConfirmModalSheet } from "../components/Global/ConfirmModalSheet";
import Divider from "../components/Global/Divider";
import ItemBannerList from "../components/Global/ItemBannerList";
import Text from "../components/Global/Text";
import { FiPlus } from "react-icons/fi";
import { useEffect } from "react";
import { useAppStore } from "../store";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { getSimilar } from "../services";
import { useState } from "react";
import ItemsBannerSkeleton from "../components/Global/ItemBannerList/Skeleton";
import Checkbox from "../components/Global/Checkbox";
import { DeleteControll } from "../components/Cart/DeleteControll";
import { GoToLink } from "../helper";
import { Helmet } from "react-helmet";

const CartScreen = () => {
  const {
    basket,
    setLogout,
    trashList,
    setTrashList,
    setBasket,
    setCartAvalibleQTY,
    vendorID,
  } = useAppStore();

  const [similarList, setSimilarList] = useState([]);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  let navigate = useNavigate();

  const { mutate, isLoading } = useMutation([`similarItems`], getSimilar);

  useEffect(() => {
    mutate(
      {
        IDS: basket?.map((el) => el?.item?.id),
        "vendor-id": vendorID,
      },
      {
        onSuccess: (list) => {
          if (list?.data && list.success) {
            setSimilarList(list?.data.similar);
            setCartAvalibleQTY(list?.data.cart);
          }
        },
      }
    );
  }, []);

  const handleDelete = () => {
    try {
      let newBasket = [...basket];
      if (trashList?.length !== 0 && trashList?.length === basket?.length) {
        localStorage.removeItem("grocery_basket");
        setBasket([]);
        GoToLink(() => navigate("/", { replace: true }));
      } else {
        newBasket = basket?.filter((_, i) => {
          return !trashList?.includes(`item-${i}`);
        });
        setBasket(newBasket);
        localStorage.setItem("grocery_basket", JSON.stringify(newBasket));
      }
      setIsConfirmDelete(false);
      setIsDelete(false);
      setTrashList([]);
    } catch (err) {
      console.error(`ShoopingTrashModal handleDelete: ${err}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>سلة التسوق</title>
      </Helmet>
      <ChildHeader
        title={"سلة التسوق"}
        customeTitle={
          isDelete && (
            <div className="trash-header">
              <Checkbox
                checked={trashList?.length > 0}
                name="all"
                id="all"
                onChange={() => {
                  setTrashList(
                    trashList?.length === 0
                      ? basket?.map((_, index) => `item-${index}`)
                      : []
                  );
                }}
              />
              <Text bold size={18}>
                {`تم تحديد ${trashList?.length}`}
              </Text>
            </div>
          )
        }
        withoutBack={!!isDelete}
        actions={
          !isDelete && [
            <PureButtonIcon onClick={() => setIsDelete(true)}>
              <BalyTrash size={26} />
            </PureButtonIcon>,
          ]
        }
      />
      <Screen>
        <Space height={16} />
        <Container>
          <CartItems isDelete={isDelete} />
          {!isDelete && <Divider />}
          <Space height={4} />
          {!isDelete && (
            <div className="flex-space">
              <Space width={10} />
              <Text link="../" color="#0000ff" size={12}>
                <div className="flex-center">
                  اضافة عناصر
                  <Space width={6} />
                  <FiPlus color="#0000ff" size={14} />
                </div>
              </Text>
            </div>
          )}
        </Container>
        <Space height={16} />
        {isLoading ? (
          <Container>
            <ItemsBannerSkeleton />
          </Container>
        ) : (
          !isDelete &&
          similarList?.length !== 0 && (
            <ItemBannerList title="منتجات مشابهة" list={similarList} />
          )
        )}
        {!isDelete && (
          <Container>
            <Space height={16} />
            <CartNote />
          </Container>
        )}
        <Space height={108} />
        {!isDelete ? (
          <CartCTA />
        ) : (
          <DeleteControll
            disabled={trashList?.length === 0}
            handleCancel={() => {
              setIsDelete(false);
              setTrashList([]);
            }}
            handleConfirm={() => setIsConfirmDelete(true)}
          />
        )}
        <LoginModal backTo="cart" />
      </Screen>

      <ConfirmModalSheet
        title="هل تريد الاستمرار في الحذف؟"
        msg="سيتم حذف السلة بشكل نهائي, ان كنت تريد الاستمرار, اضغط على الغاء واستمر بالطلب."
        confirmText="حذف"
        cancelText="الغاء"
        isOpen={isConfirmDelete}
        onClose={setIsConfirmDelete}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default CartScreen;
