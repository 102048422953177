import dayjs from "dayjs";
import PureButton from "../../../Global/Button";
import PureCard from "../../../Global/Card";
import Space from "../../../Global/Space";
import Text from "../../../Global/Text";
import { useAppStore } from "../../../../store";
import "./voucherCard.css";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Touchable from "../../../Global/Ripple";
import VoucherSteps from "../VoucherSteps";

/**
 * Voucher Card
 * @returns ReactNode | ReactElement
 */
export const VoucherCard = ({ voucherInformation }) => {
  const [applyButtonProps, setApplyButtonProps] = useState({
    title: "تطبيق",
    class: "primary-voucher-btn",
    disabled: false,
  });
  const navigate = useNavigate();

  const {
    setIsVoucherCenterModal,
    setDeepVoucher,
    setVoucherCenterInfo,
    deepVoucher,
    setDeepVoucherInfo,
  } = useAppStore();

  const isVoucherApplied =
    deepVoucher === voucherInformation.code &&
    applyButtonProps.title === "الغاء التطبيق";

  const currentDate = dayjs(new Date());
  const endDate = voucherInformation?.endDate;

  const voucherTags = () => {
    const tags = [];

    if ("cityTags" in voucherInformation) {
      if (
        voucherInformation?.cityTags?.length === 1 &&
        !!voucherInformation?.cityTags[0]
      ) {
        tags.push(`داخل يعمل ${voucherInformation?.cityTags[0]}`);
      } else {
        tags.push(`يعمل داخل عدة محافظات`);
      }
    }

    if ("categoryTags" in voucherInformation) {
      if (
        voucherInformation?.categoryTags?.length === 1 &&
        !!voucherInformation?.categoryTags[0]
      ) {
        tags.push(`على قسم ${voucherInformation?.categoryTags[0]}`);
      } else {
        tags.push(`على اقسام متعددة`);
      }
    }

    if ("noOfUsage" in voucherInformation) {
      tags.push(`${voucherInformation["noOfUsage"]} استخدامات`);
    }

    if ("startDate" in voucherInformation) {
      const humanReadableVoucherValidity =
        dayjs(endDate).diff(currentDate, "day") + 1 >= 1
          ? `صالح لمدة ${dayjs(endDate).diff(currentDate, "day") + 1} ايام`
          : null;

      tags.push(humanReadableVoucherValidity);
    }

    return tags;
  };

  const handleApplyVoucher = () => {
    if (isVoucherApplied) {
      setApplyButtonProps({
        title: "تطبيق",
        class: "primary-voucher-btn",
        disabled: false,
      });
      setDeepVoucher(null);
      setIsVoucherCenterModal(false);
      setDeepVoucherInfo(null);
    } else if (
      deepVoucher !== voucherInformation.code &&
      applyButtonProps.title === "تطبيق"
    ) {
      setApplyButtonProps({
        title: "الغاء التطبيق",
        class: "applied-voucher-btn",
        disabled: false,
      });
      setDeepVoucher(voucherInformation?.code);
      setIsVoucherCenterModal(false);
      setDeepVoucherInfo(voucherInformation);
      toast.success("تم تفعيل رمز الخصم");
      navigate("/");
    }
  };

  useEffect(() => {
    if (voucherInformation) {
      if (deepVoucher === voucherInformation?.code) {
        setApplyButtonProps({
          title: "الغاء التطبيق",
          class: "applied-voucher-btn",
          disabled: false,
        });
      } else {
        setApplyButtonProps({
          title: "تطبيق",
          class: "primary-voucher-btn",
          disabled: false,
        });
      }
    }
  }, [voucherInformation, deepVoucher]);

  return (
    <Touchable
      onClick={() => {
        setIsVoucherCenterModal(true);
        setVoucherCenterInfo(voucherInformation);
      }}
      className="voucher-card-touchable"
    >
      <PureCard
        style={{
          borderRadius: 12,
          borderColor: isVoucherApplied ? "#0000FF" : "#F0F0F0",
          padding: 12,
        }}
      >
        <div className="voucher_details">
          <Text bold size={16}>
            {voucherInformation.customerDesc}
          </Text>
          {voucherInformation?.voucherType === "COMPOUND" && (
            <VoucherSteps
              steps={voucherInformation?.voucherSteps || []}
              clientUsage={voucherInformation?.clientUsage}
              currentStep={voucherInformation?.currentStep}
            />
          )}
          <Space height={8} />
          <Text color="#666666">{voucherTags().join(" . ")}</Text>
        </div>
        <Space height={8} />
        <div className="voucher_actions">
          <PureButton
            radius={16}
            type="accent"
            small
            styles={{
              padding: "4px 24px",
            }}
            className={applyButtonProps.class}
            onClick={() => handleApplyVoucher()}
          >
            {applyButtonProps.title}
          </PureButton>
          <PureButton
            type="default"
            radius={100}
            small
            styles={{
              background: "#fff",
              border: "none",
              padding: "4px 24px",
            }}
            onClick={() => {
              setIsVoucherCenterModal(true);
              setVoucherCenterInfo(voucherInformation);
            }}
          >
            التفاصيل
          </PureButton>
        </div>
      </PureCard>
    </Touchable>
  );
};
