export const EmptyIcon = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52" cy="52" r="51.5" fill="#F6F6F6" stroke="#F0F0F0" />
    <path
      d="M63.6763 52.5128V40.718C63.6763 34.2038 58.4482 28.9231 51.9994 28.9231C45.5504 28.9231 40.3224 34.2038 40.3224 40.718V52.5128M31.5647 46.6154H72.434L75.3532 82H28.6455L31.5647 46.6154Z"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.7921 68.1999C43.3097 68.1999 46.1613 65.3483 46.1613 61.8307C46.1613 58.313 43.3097 55.4614 39.7921 55.4614C36.2745 55.4614 33.4229 58.313 33.4229 61.8307C33.4229 65.3483 36.2745 68.1999 39.7921 68.1999ZM43.2617 60.3873C43.637 59.9369 43.5761 59.2675 43.1258 58.8922C42.6754 58.5169 42.006 58.5778 41.6307 59.0281L38.9077 62.2957L37.954 61.1512C37.5787 60.7008 36.9093 60.64 36.4589 61.0153C36.0085 61.3906 35.9477 62.06 36.323 62.5104L38.0922 64.6335C38.2939 64.8755 38.5927 65.0154 38.9077 65.0154C39.2228 65.0154 39.5215 64.8755 39.7232 64.6335L43.2617 60.3873Z"
      fill="#CCCCCC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.4908 78.8154C64.8879 78.8154 68.4524 75.2509 68.4524 70.8539C68.4524 66.4568 64.8879 62.8923 60.4908 62.8923C56.0938 62.8923 52.5293 66.4568 52.5293 70.8539C52.5293 75.2509 56.0938 78.8154 60.4908 78.8154ZM61.5519 66.3045C61.5519 65.7182 61.0767 65.243 60.4904 65.243C59.9041 65.243 59.4289 65.7182 59.4289 66.3045V69.7924H55.9409C55.3547 69.7924 54.8794 70.2677 54.8794 70.8539C54.8794 71.4402 55.3547 71.9155 55.9409 71.9155H59.4289V75.4034C59.4289 75.9897 59.9041 76.4649 60.4904 76.4649C61.0767 76.4649 61.5519 75.9897 61.5519 75.4034V71.9155H65.0398C65.6261 71.9155 66.1014 71.4402 66.1014 70.8539C66.1014 70.2677 65.6261 69.7924 65.0398 69.7924H61.5519V66.3045Z"
      fill="#CCCCCC"
    />
    <circle
      r="2.12308"
      transform="matrix(1 0 0 -1 47.753 73.5078)"
      fill="#CCCCCC"
    />
    <circle
      r="2.12308"
      transform="matrix(1 0 0 -1 43.5069 22.5539)"
      fill="#CCCCCC"
    />
    <path
      d="M43.6203 69.83C41.5389 71.9114 41.5389 75.2549 43.6203 77.3363C41.5389 75.2549 38.1954 75.2549 36.1141 77.3362C38.1954 75.2549 38.1954 71.9114 36.1141 69.83C38.1678 71.8837 41.5389 71.9114 43.6203 69.83Z"
      fill="#CCCCCC"
    />
    <path
      d="M66.3301 63.9537C66.3301 61.5982 64.4395 59.7076 62.084 59.7076C64.4395 59.7076 66.3301 57.817 66.3301 55.4614C66.3301 57.817 68.2208 59.7076 70.5763 59.7076C68.2208 59.7076 66.3301 61.5982 66.3301 63.9537Z"
      fill="#CCCCCC"
    />
    <path
      d="M50.3292 63.1895C51.929 59.4726 50.2489 55.2533 46.532 53.6535C50.2489 55.2533 54.4682 53.5732 56.068 49.8562C54.4682 53.5732 56.1483 57.7924 59.8653 59.3923C56.1483 57.7924 51.8924 59.5578 50.3292 63.1895Z"
      fill="#CCCCCC"
    />
    <path
      d="M33.9532 36.3539C33.9532 33.3946 31.6048 31.0462 28.6455 31.0462C31.6048 31.0462 33.9532 28.6978 33.9532 25.7385C33.9532 28.6978 36.3016 31.0462 39.2609 31.0462C36.3016 31.0462 33.9532 33.4625 33.9532 36.3539Z"
      fill="#CCCCCC"
    />
    <path
      d="M71.6382 39.5384C71.6382 37.4669 69.9943 35.8231 67.9229 35.8231C69.9943 35.8231 71.6382 34.1792 71.6382 32.1077C71.6382 34.1792 73.2821 35.8231 75.3536 35.8231C73.2821 35.8231 71.6382 37.5145 71.6382 39.5384Z"
      fill="#CCCCCC"
    />
    <path
      d="M50.4068 41.6615C50.4068 39.59 48.7629 37.9461 46.6914 37.9461C48.7629 37.9461 50.4068 36.3022 50.4068 34.2307C50.4068 36.3022 52.0507 37.9461 54.1222 37.9461C52.0507 37.9461 50.4068 39.6375 50.4068 41.6615Z"
      fill="#CCCCCC"
    />
    <path
      d="M62.6144 27.8615C62.6144 23.7186 59.3266 20.4308 55.1836 20.4308C59.3266 20.4308 62.6144 17.143 62.6144 13C62.6144 17.143 65.9021 20.4308 70.0451 20.4308C65.9021 20.4308 62.6144 23.8136 62.6144 27.8615Z"
      fill="#CCCCCC"
    />
  </svg>
);
