export const BalyMarker = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.2999 8.71652C8.75349 9.26287 7.61097 10.4054 6.82476 11.1916C6.36912 11.6472 5.6312 11.6475 5.17556 11.1918C4.40307 10.4193 3.28263 9.29892 2.70021 8.71652C0.877762 6.89407 0.877762 3.93928 2.70021 2.11684C4.52266 0.294388 7.47745 0.294388 9.2999 2.11684C11.1223 3.93928 11.1223 6.89407 9.2999 8.71652Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.75 5.4165C7.75 6.38303 6.96652 7.1665 6 7.1665C5.03347 7.1665 4.25 6.38303 4.25 5.4165C4.25 4.45001 5.03347 3.6665 6 3.6665C6.96652 3.6665 7.75 4.45001 7.75 5.4165Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};


