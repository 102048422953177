export const calculateFreeDeliveryAmount = (
  deliveryFee,
  freeDiscountPercentage
) => {
  return (deliveryFee * freeDiscountPercentage) / 100;
};

/**
 * @author Wael Noori
 * @returns number
 */
export const calculateDeliveryFeeDiscount = (
  deliveryDiscountInfo,
  vendor,
  total,
  voucher
) => {
  if (!!voucher && voucher?.voucherType === "COMBINED") {
    return 0;
  }

  if (
    !!voucher &&
    voucher?.voucherType === "DELIVERY" &&
    total >= voucher?.minAmount
  ) {
    let deliveryDiscountValue = vendor?.delivery * (voucher?.totalValue / 100);

    if (deliveryDiscountValue > voucher?.maxAmount) {
      return voucher?.maxAmount;
    }

    return deliveryDiscountValue;
  }

  if (deliveryDiscountInfo?.Discount == 0) return vendor?.delivery;

  if (total >= deliveryDiscountInfo?.Minamount) {
    let deliveryDiscountValue =
      vendor?.delivery * (deliveryDiscountInfo?.Discount / 100);

    if (deliveryDiscountValue > deliveryDiscountInfo?.Maxamount)
      return vendor?.delivery - deliveryDiscountInfo?.Maxamount;

    return vendor?.delivery - deliveryDiscountValue < 0
      ? 0
      : vendor?.delivery - deliveryDiscountValue;
  }
  return vendor?.delivery;
};
