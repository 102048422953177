import { useAppStore } from "../../../store";
import { FaRegUserCircle } from "react-icons/fa";

import "./style.css";
import { useNavigate } from "react-router-dom";
import { GoToLink } from "../../../helper";
import queryClient from "../../../queryClient";
import {
  BalyHeart,
  BalyComment,
  BalyUsers,
  BalySupport,
  BalyLogout,
  BalyClose,
  BalyVoucher,
  BalyHome,
} from "../../BalyIcons";
import Space from "../Space";
import { useRateStore } from "../RatingModal/store";

const AppMenu = () => {
  const {
    isMenu,
    setIsMenu,
    setIsLogin,
    setUser,
    isLogin,
    user,
    setSelectedAddress,
    setOrderData,
    setCurrentLocation,
    isSuperApp,
    voucherCenterNotification,
    isReferralAvailable,
  } = useAppStore();

  const { reset } = useRateStore();

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();

    setIsLogin(false);
    setSelectedAddress(null);
    setUser(null);
    setOrderData([]);
    setCurrentLocation(null);
    setIsMenu(false);
    queryClient.clear();
  };

  return (
    <div
      className="app-sidebar"
      style={{
        transform: `translateX(${isMenu ? "0px" : "100vw"})`,
        boxShadow: isMenu ? "-1px -2px 26px 5px #ccccccab" : "none",
      }}
    >
      <div className="container">
        <span
          className="tauchable menu-close-btn"
          onClick={() => setIsMenu(false)}
        >
          <BalyClose size={24} />
        </span>

        <br />
        {isLogin && (
          <div className="menu-item menu-item-user">
            <div className="user-avatar">
              <FaRegUserCircle size={24} />
            </div>
            <div className="user-info">
              <div className="app-flex-space">
                <b>
                  {user && user.firstName
                    ? user.firstName + " " + user.lastName
                    : "....."}
                </b>
              </div>
              <a
                className="tauchable"
                onClick={() =>
                  GoToLink(() => {
                    setIsMenu(false);
                    navigate("/profile");
                  })
                }
              >
                إظهار معلومات المستخدم
              </a>
            </div>
          </div>
        )}

        {!isLogin && (
          <div className="menu-item">
            <div className="icon">
              <BalyHome size={26} />
            </div>
            <p
              className="tauchable"
              onClick={() => {
                navigate("/login");
                setIsMenu(false);
              }}
            >
              تسجيل الدخول أو انشاء حساب
            </p>
          </div>
        )}

        <Space height={18} />
        {/* {isLogin && (
          <div className="menu-item">
            <div className="notified-dev icon">
              <BalyVoucher size={26} />
              {voucherCenterNotification && (
                <div className="notification-dot"></div>
              )}
            </div>

            <p
              className="tauchable"
              onClick={() => {
                navigate("/voucher-center");
                setIsMenu(false);
              }}
            >
              مركز القسائم
            </p>
            {voucherCenterNotification && (
              <p
                style={{
                  fontSize: "12px",
                  padding: "0 10px",
                  color: "#F24822",
                }}
              >
                جديد
              </p>
            )}
          </div>
        )} */}
        {isLogin && (
          <div className="menu-item">
            <div className="icon">
              <BalyHeart size={24} />
            </div>

            <p
              className="tauchable"
              onClick={() => {
                navigate("/faivorate");
                setIsMenu(false);
              }}
            >
              المفضلات
            </p>
          </div>
        )}
        {isLogin && (
          <div className="menu-item">
            <div className="icon">
              <BalyComment size={24} />
            </div>

            <p
              className="tauchable"
              onClick={() => {
                navigate("/comments");
                setIsMenu(false);
              }}
            >
              تعليقاتي
            </p>
          </div>
        )}
        {/* {isLogin && isReferralAvailable?.id !== 0 && (
          <div className="menu-item">
            <div className="icon">
              <BalyUsers size={24} />
            </div>

            <p
              className="tauchable"
              onClick={() => {
                navigate("/referral");
                setIsMenu(false);
              }}
            >
              شارك بلي مارت مع اصدقائك
            </p>
          </div>
        )} */}
        <div className="menu-item">
          <div className="icon">
            <BalySupport size={24} />
          </div>
          <p
            className="tauchable"
            onClick={() => {
              // window?.$crisp?.push([
              //   "set",
              //   "session:segments",
              //   [["balymart", "SUPPORT"]],
              // ]);
              // if (isLogin && user) {
              //   window?.$crisp?.push(["set", "user:phone", [user?.phone]]);
              //   window?.$crisp?.push([
              //     "set",
              //     "user:nickname",
              //     [`${user?.firstName} ${user?.lastName}`],
              //   ]);
              // }
              // window?.$crisp?.push(["do", "chat:open"]);

              window.fcWidget.open({ name: "Support" });
              setIsMenu(false);
            }}
          >
            اتصل بالدعم
          </p>
        </div>

        {isLogin && (
          <div className="menu-item">
            <div className="icon">
              <BalyLogout size={24} />
            </div>
            <p
              className="tauchable"
              onClick={() => {
                reset();
                if (isSuperApp) {
                  window?.location?.replace("balyhomepage://");
                } else {
                  handleLogout();
                }
              }}
            >
              خــروج
            </p>
          </div>
        )}
      </div>
      <div
        className="version"
        onClick={() => {
          navigate("/components");
          setIsMenu(false);
        }}
      >
        الاصدار 1.00
      </div>
    </div>
  );
};

export default AppMenu;
