import { useMutation } from "react-query";
import { BalyClose } from "../../../BalyIcons";
import PureButton from "../../../Global/Button";
import { PureModalSheet } from "../../../Global/PureModalSheet";
import Space from "../../../Global/Space";
import Text from "../../../Global/Text";
import { useCancellationStore } from "../store";
import "./cancelModal.css";
import { cancelOrder } from "../../../../services";

export const ConfirmationCancelModal = () => {
  const {
    isConfirmModalOpen,
    setIsConfirmModalOpen,
    orderId,
    setCancellationErrorMessage,
    setCancellationReasons,
    setIsCancelModalOpen,
  } = useCancellationStore();

  const onModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  const { mutate, isLoading } = useMutation(cancelOrder, {
    onSuccess: (data) => {
      if (!data?.success) {
        setIsConfirmModalOpen(false);
        setCancellationErrorMessage(data.err);

        return;
      }
      setCancellationReasons(data?.data);
      setIsConfirmModalOpen(false);
      setIsCancelModalOpen(true);
    },
  });

  return (
    <>
      <PureModalSheet
        isOpen={isConfirmModalOpen}
        onClose={onModalClose}
        viewHeight={259}
      >
        <div className="cancel-modal-content">
          <div className="cancel-modal-header">
            <Text size={18} bold>
              نأسف لهذا, هل تريد الغاء الطلب؟
            </Text>
            <PureButton
              onClick={onModalClose}
              styles={{ background: "#ffffff", border: 0, padding: "0px 8px" }}
            >
              <BalyClose size={24} />
            </PureButton>
          </div>
          <Space height={8} />
          <div className="cancel-modal-description">
            <Text size={16} color="#666666">
              هل تريد الاستمرار بالغاء الطلب الخاص بك, يمكنك اعادة وضع طلب جديد
              من خلال الصفحة الرئيسية
            </Text>
          </div>
          <Space height={56} />
          <div className="cancel-modal-actions">
            <PureButton
              styles={{
                minWidth: 170,
              }}
              radius={33}
              onClick={onModalClose}
            >
              عودة
            </PureButton>
            <PureButton
              styles={{
                minWidth: 170,
                background: "#FFFFFF",
                color: "#FF0000",
                border: 0,
              }}
              radius={33}
              onClick={() => {
                mutate({
                  id: parseInt(orderId),
                });
              }}
              disabled={isLoading}
            >
              الغاء الطلب
            </PureButton>
          </div>
        </div>
      </PureModalSheet>
    </>
  );
};
