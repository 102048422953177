import Screen from "../components/Global/Screen";
import Space from "../components/Global/Space";
import Container from "../components/Global/Container";
import ItemsFilter from "../components/Items/Filter";
import ChildHeader from "../components/Global/ChildHeader";
import { useParams } from "react-router-dom";
import { BalyFilter } from "../components/BalyIcons";
import PureButtonIcon from "../components/Global/ButtonIcon";
import { useAppStore } from "../store";
import ItemGrid from "../components/Global/ItemGrid";
import { useInfiniteQuery } from "react-query";
import { getProductsByBanner, pageSize } from "../services";
import { useEffect } from "react";
import { useState } from "react";
import ItemGridSkeleton from "../components/Global/ItemGrid/Skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import Divider from "../components/Global/Divider";
import Text from "../components/Global/Text";

const GroupScreen = () => {
  const { id, title } = useParams();
  const { setIsFilter, setLogout, vendorID } = useAppStore();
  const [productList, setProductList] = useState([]);
  const [total, setTotal] = useState(0);
  const [queryString, setQueryString] = useState("");

  const { data, isLoading, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery(
      [`group-${id}`, id, queryString, vendorID],
      getProductsByBanner,
      {
        getNextPageParam: (lastPage) => lastPage?.nextCursor,
        select: (data) => ({
          ...data,
          success: data.pages.flatMap((page) => page?.data?.success)[0],
          total: data.pages.flatMap((page) => page?.data?.data?.total)[0],
          err: data.pages.flatMap((page) => page?.data?.err)[0] || "",
          pages: data.pages.flatMap((page) => page?.data?.data),
        }),
        refetchOnWindowFocus: false,
        refetchInterval: 0,
        enabled: !!id,
      }
    );

  useEffect(() => {
    if (data && data?.success && Array.isArray(data?.pages[0]?.records)) {
      setProductList(
        data?.pages?.map((el) => el.records)?.reduce((a, b) => [...a, ...b], [])
      );
      setTotal(data?.total);
    }
  }, [data]);

  const appendComp = (text) =>
    productList?.length >= pageSize && (
      <p style={{ textAlign: "center" }}>
        <Space height={10} />
        <Divider />
        <Space height={10} />
        <Text size={16} bold>
          {text}
        </Text>
      </p>
    );

  return (
    <>
      <ChildHeader
        title={data?.data?.appBannerTitle || title}
        actions={[
          <PureButtonIcon onClick={() => setIsFilter(true)}>
            <BalyFilter size={26} />
          </PureButtonIcon>,
        ]}
      />
      <Screen>
        <Space height={16} />
        <Container>
          {isLoading ? (
            <ItemGridSkeleton />
          ) : (
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={productList?.length}
              next={fetchNextPage}
              hasMore={hasNextPage}
              loader={appendComp("جاري التحميل ...")}
              endMessage={appendComp("تم تحميل جميع المنتجات")}
            >
              <ItemGrid list={productList || []} />
            </InfiniteScroll>
          )}
        </Container>
      </Screen>
      <ItemsFilter
        brandQuery={`?bid=${id}`}
        onFilter={(val) => setQueryString(val)}
      />
    </>
  );
};

export default GroupScreen;
