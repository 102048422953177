import './style.css'
import { toast } from "react-hot-toast";

const copy = require("clipboard-copy"); 

const copyLink = (val) => {
  let successPromise = copy(val);
  if (successPromise) {
    toast.success("تم نسخ رمز الخصم");
  } else {
    toast.error("Faild Copy !");
  }
}

export const PureTagVouchersList = ({ title, icon, color = "#000000", bgColor, code}) => {
  return (
    <div className="pure-tag-vouchers-list" style={{background: bgColor || "#F0F0F0", color: color}}>
      <span className="title-tag-vouchers-list">{title}</span>
      {icon && <span className="icon-tag-vouchers-list" onClick={()=>code && copyLink(code)}>{icon}</span>}
    </div>
  );
};
