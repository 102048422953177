export const Copy = ({ size = 14, color = "black", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.77778 3.22222V7.66667C2.77778 8.28033 3.27524 8.77778 3.88889 8.77778H7.22222M2.77778 3.22222V2.11111C2.77778 1.49746 3.27524 1 3.88889 1H6.43656C6.58389 1 6.72522 1.05853 6.82939 1.16272L9.28172 3.61506C9.38589 3.71924 9.44445 3.86056 9.44445 4.00789V7.66667C9.44445 8.28033 8.947 8.77778 8.33333 8.77778H7.22222M2.77778 3.22222H1.66667C1.05302 3.22222 0.555557 3.71968 0.555557 4.33333V9.88889C0.555557 10.5026 1.05302 11 1.66667 11H6.11111C6.72478 11 7.22222 10.5026 7.22222 9.88889V8.77778"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
