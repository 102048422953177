import "./style.css";
import Text from "../Text";
import Container from "../Container";
import { BalyArrowLeft } from "../../BalyIcons";
import Space from "../Space";
import ItemCard from "../ItemCard";

const ItemBannerList = ({ id, title, description = "", list }) => {
  const hasDiscount = list?.find((el) => el?.discount && el?.discount !== 0)
    ? true
    : false;
  return list?.length > 0 ? (
    <div className="item-bannerr-list">
      <Container>
        <section className="list-title">
          <div>
            <Text size={16} bold>
              {title}
            </Text>
            {!!description && (
              <Text size={14} color="#666666">
                {description}
              </Text>
            )}
          </div>
          {id && (
            <Text
              color="#0000ff"
              link={`/group/${id}/${encodeURIComponent(title)}`}
              size={14}
            >
              <div className="flex-center">
                <span>المزيد</span>
                <Space width={6} />
                <BalyArrowLeft color="#0000ff" size={24} />
              </div>
            </Text>
          )}
        </section>
      </Container>
      <Space height={10} />
      <Container style={{ padding: "4px 0px 10px" }}>
        <ul className={hasDiscount ? "has-discount" : "item-list"}>
          <li></li>
          {list?.map((el, i) => (
            <li key={i}>
              <ItemCard
                id={el.id}
                status={el?.status}
                productID={el?.productID}
                imgs={JSON.parse(el?.img)}
                name={el?.name} // this for variation name
                product={el?.product} // this for main product
                description={el?.description}
                size={String(
                  `${!!el?.size ? el?.size : ""} ${!!el?.unit ? el?.unit : ""}`
                ).trim()}
                price={el?.price}
                count={el?.count}
                availableQty={el?.availableQty}
                endprice={el?.endprice}
                discount={el?.discount ? el?.discount : 0}
                width={112}
              />
            </li>
          ))}
          <li></li>
        </ul>
      </Container>
    </div>
  ) : null;
};

export default ItemBannerList;
