import "./style.css";
const PureCard = ({ children, ...props }) => {
  return (
    <div
      className={`pure-card ${props?.onClick ? `pure-card-clickable` : ''}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default PureCard;
