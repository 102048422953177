const InvoiceSkeleton = () => {
  const arr = [130, 160, 100];
  return (
    <div className="invoice-sk">
      {arr.map((el) => (
        <div className="invoice-sk-item">
          <div className="invoice-sk-info">
            <div className="pure-skeleton invoice-sk-img"></div>
            <div className="pure-skeleton invoice-sk-title" style={{width: el}}></div>
          </div>
          <div className="pure-skeleton invoice-sk-price"></div>
        </div>
      ))}
    </div>
  );
};

export default InvoiceSkeleton
