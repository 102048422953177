export const BalyNavOrder = ({
  isFill,
  size = 18,
  color = "#000",
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size + 1}
      viewBox="0 0 24 25"
      fill={isFill ? color : "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {!isFill ? (
        <path
          d="M9 12.5H15M9 16.5H15M17 21.5H7C5.89543 21.5 5 20.6046 5 19.5V5.5C5 4.39543 5.89543 3.5 7 3.5H12.5858C12.851 3.5 13.1054 3.60536 13.2929 3.79289L18.7071 9.20711C18.8946 9.39464 19 9.649 19 9.91421V19.5C19 20.6046 18.1046 21.5 17 21.5Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.00586 5.5C4.00586 3.84315 5.349 2.5 7.00586 2.5H12.5917C13.122 2.5 13.6308 2.71066 14.0059 3.08578L19.4201 8.5C19.795 8.87498 20.0059 9.38369 20.0059 9.91421V19.5C20.0059 21.1569 18.6627 22.5 17.0059 22.5H7.00586C5.34901 22.5 4.00586 21.1569 4.00586 19.5V5.5ZM8.00586 12.5C8.00586 11.9477 8.45357 11.5 9.00586 11.5H15.0059C15.5581 11.5 16.0059 11.9477 16.0059 12.5C16.0059 13.0523 15.5581 13.5 15.0059 13.5H9.00586C8.45357 13.5 8.00586 13.0523 8.00586 12.5ZM8.00586 16.5C8.00586 15.9477 8.45357 15.5 9.00586 15.5H15.0059C15.5581 15.5 16.0059 15.9477 16.0059 16.5C16.0059 17.0523 15.5581 17.5 15.0059 17.5H9.00586C8.45357 17.5 8.00586 17.0523 8.00586 16.5Z"
          fill={color}
        />
      )}
    </svg>
  );
};
