import { useAppStore } from "../../../store";
import { MapContainer, TileLayer, useMap, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "../../../assets/images/marker.png";

let MarkerIcon = L.icon({
  iconUrl: icon,
  iconSize: [50, 50],
});

L.Marker.prototype.options.icon = MarkerIcon;

export const StaticMap = ({ position }) => {
  const { currentLocation } = useAppStore();
  const center =
    position.length > 0 ? position : currentLocation || [33.3152, 44.3661];

  const MapResize = () => {
    // const map = useMap();
    // setTimeout(function () {
    //   map.invalidateSize();
    // }, 200);
  };

  return (
    <MapContainer
      center={center}
      zoomControl={false}
      zoom={18}
      scrollWheelZoom={true}
      attributionControl={false}
      style={{ height: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://osrm.baly.dev/osm/{z}/{x}/{y}.png"
      />
      <Marker position={center} />
      <MapResize />
    </MapContainer>
  );
};
