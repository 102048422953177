import * as React from "react";
const BalyPlus = ({ size = 18, color = "#000", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.5 5v6m0 0v6m0-6h6m-6 0h-6"
    />
  </svg>
);
export default BalyPlus;
