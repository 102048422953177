import Screen from "../components/Global/Screen";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { GoToLink } from "../helper";
import ChildHeader from "../components/Global/ChildHeader";
import { login } from "../services";
import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { Toaster, toast } from "react-hot-toast";
import { useAppStore } from "../store";
import OtpInput from "react-otp-input";
import ga4 from "react-ga4";
import { Helmet } from "react-helmet";
import PureButton from "../components/Global/Button";

const VerifyScreen = () => {
  const navigate = useNavigate();
  const { setUser, setIsLogin, setIsUserModal, device } = useAppStore();
  const [otp, setOtp] = useState("");
  const { phone, type } = useParams();
  const [searchParams] = useSearchParams();
  let location = useLocation();

  useEffect(() => {
    if (location && location.state) {
      setOtp(location.state.otp);
    }
  }, [location]);

  const ArToEn = (numb) => {
    return numb.replace(/[\u0660-\u0669]/g, (d) => d.charCodeAt() - 1632);
  };

  const { mutate, isLoading } = useMutation(login, {
    onSuccess: async (data) => {
      if (data.success) {
        localStorage.setItem("grocery_token", data.data.token);
        localStorage.setItem("grocery_user", JSON.stringify(data.data.user));
        localStorage.setItem("grocery_refresh_token", data.data.refreshToken);
        handleWebengage(data?.data?.type, data?.data?.user, data?.data?.os);
        setIsLogin(true);
        setUser(data.data.user);
        if (type === "register") {
          GoToLink(() =>
            navigate(
              searchParams.get("p") ? `/${searchParams.get("p")}` : "/",
              { replace: true }
            )
          );
          setIsUserModal(true);
        } else {
          GoToLink(() =>
            navigate(
              searchParams.get("p") ? `/${searchParams.get("p")}` : "/",
              { replace: true }
            )
          );
        }
      } else {
        toast.error(data.msg);
      }
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const handleWebengage = (type, user, OS) => {
    if (!type || !user) return;
    if (type === "login") {
      ga4.event("Grocery Login", {
        items: [
          {
            channel: "SSO",
            source: device,
            OS: OS,
          },
        ],
      });
      if (window && window.webengage)
        window.webengage.track("Grocery Login", {
          channel: "SSO",
          source: device,
        });
    }
    if (type === "register") {
      ga4.event("Grocery Signup", {
        items: [
          {
            channel: "SSO",
            source: device,
          },
        ],
      });
      if (window && window.webengage)
        window.webengage.track("Grocery Signup", {
          channel: "SSO",
          source: device,
        });
    }
  };

  const handleVerify = () => {
    mutate({
      phone: ArToEn(phone),
      otp,
    });
  };

  return (
    <>
      <Helmet>
        <title>تاكيد تسجيل الدخول</title>
      </Helmet>
      <ChildHeader></ChildHeader>
      <Screen>
        <Toaster />
        <div className="verfiy app-container">
          <section className="card">
            <p className="title">أدخل رمز التحقق</p>
            <span className="desc">سوف تصلك رسالة تأكيد عبر ال SMS.</span>

            <div className="otp">
              <OtpInput
                value={otp}
                onChange={(val) => setOtp(val)}
                numInputs={5}
                placeholder="00000"
                separator={<span style={{ opacity: 0.4, padding: 6 }}>-</span>}
              />
            </div>
          </section>
          <section className="confirm-box app-container">
            <PureButton
              type={"primary"}
              block
              onClick={() => {
                handleVerify();
              }}
              style={{ width: "100%" }}
            >
              {isLoading ? <div className="btn-loading"></div> : "تــــأكيد"}
            </PureButton>
          </section>
        </div>
      </Screen>
    </>
  );
};

export default VerifyScreen;
