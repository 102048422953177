import { useEffect } from "react";
import PureButton from "../../../Global/Button";
import { useCancellationStore } from "../store";
import dayjs from "dayjs";
import { useState } from "react";
import { useAppStore } from "../../../../store";

export const CancellationCTA = ({ orderCreatedTime, order }) => {
  const { orderStatus: OrderStatusSSE } = useAppStore();
  const { cancelTime, setIsConfirmModalOpen, orderId } = useCancellationStore();

  const [orderStatus, setOrderStatus] = useState(
    JSON.parse(order.status).pop()
  );

  const cancellationTime =
    cancelTime -
    Math.floor(
      (dayjs(new Date(orderCreatedTime)).diff(dayjs(), "milliseconds", false) /
        1000) *
        -1
    );

  const [inCancellationPeriod] = useState(
    dayjs().isBefore(
      dayjs(new Date(orderCreatedTime)).add(cancelTime, "seconds")
    )
  );

  useEffect(() => {
    if (!!OrderStatusSSE && OrderStatusSSE?.id == orderId) {
      setOrderStatus(JSON.parse(OrderStatusSSE?.status || order.status).pop());
    }
  }, [order, OrderStatusSSE]);

  const [localCancellationTime, setLocalCancellationTime] =
    useState(cancellationTime);

  const canUserCancelOrder =
    orderStatus.status !== "CANCELLED" &&
    orderStatus.status !== "PICKED_UP" &&
    inCancellationPeriod &&
    cancellationTime >= 0;

  useEffect(() => {
    if (!orderCreatedTime) {
      return;
    }

    if (canUserCancelOrder) {
      const timer = setTimeout(() => {
        if (localCancellationTime > cancelTime) {
          setLocalCancellationTime(cancelTime);
        }
        setLocalCancellationTime((prev) => prev - 1);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [localCancellationTime, orderCreatedTime]);

  const cancellationButtonText = canUserCancelOrder
    ? `يمكن الغاء الطلب خلال ${localCancellationTime} ث`
    : "تحدث مع الدعم لتعديل الطلب";

  const cancellationButtonStyles = canUserCancelOrder
    ? {
        border: "1px solid #FF0000",
        background: "#fff",
        color: "#FF0000",
      }
    : {
        border: "1px solid #9A9A9A",
        background: "#fff",
        color: "#9A9A9A",
      };

  return (
    cancelTime > 0 && (
      <PureButton
        onClick={() => {
          setIsConfirmModalOpen(true);
        }}
        radius={30}
        block
        disabled={!canUserCancelOrder}
        styles={cancellationButtonStyles}
      >
        {cancellationButtonText}
      </PureButton>
    )
  );
};
