export const NoteIcon = ({ size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2866_9232)">
        <path
          d="M9.9999 6.19037L9.99878 11.0007M9.9999 13.8094H10.0094M18.5713 9.9999C18.5713 14.7338 14.7338 18.5713 9.9999 18.5713C5.26603 18.5713 1.42847 14.7338 1.42847 9.9999C1.42847 5.26603 5.26603 1.42847 9.9999 1.42847C14.7338 1.42847 18.5713 5.26603 18.5713 9.9999Z"
          stroke="#9A9A9A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2866_9232">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
