import "./style.css";
import Text from "../../Global/Text";
import PureButton from "../../Global/Button";
import Container from "../../Global/Container";
import Space from "../../Global/Space";

export const DeleteControll = ({
  handleConfirm,
  handleCancel,
  disabled = false,
}) => {
  return (
    <div className="cart-cta">
      <Container>
        <div className="flex-center">
          <PureButton block onClick={handleCancel}>
            <Text size={18}>الغاء</Text>
          </PureButton>
          <Space width={20} />
          <PureButton block onClick={() => !disabled ? handleConfirm() : undefined}>
            <Text size={18} color={disabled ? "#ccc" : "#ff0000"}>
              تأكيد
            </Text>
          </PureButton>
        </div>
      </Container>
    </div>
  );
};
