import React, { useState, useCallback } from "react";

import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppStore } from "../../store";
import { useQuery } from "react-query";
import ga4 from "react-ga4";
import {
  getNearAddress,
  getSettings,
  SSO_REQUEST,
  checkServiceArea,
  vendorID,
} from "../../services";
import { useCancellationStore } from "../Screens/OrderCancellation/store";

const tagManagerArgs = {
  gtmId: "GTM-TKZ27BW",
};

TagManager.initialize(tagManagerArgs);

function AppWrapper({ children }) {
  const {
    setCurrentLocation,
    currentLocation,
    setOrderData,
    setSelectedAddress,
    setIsLogin,
    setUser,
    isLogin,
    setIsClientMap,
    setAppCloseMsg,
    setAppClose,
    setIsPreview,
    isClose,
    user,
    setAppLoading,
    setIsClose,
    setRealLocation,
    realLocation,
    setIsSuperApp,
    device,
    isSuperApp,
    setDeepVoucher,
    setIsSuperAppLoading,
    setVendorID,
    shouldRefetchVendorID,
    setComment,
    setDeliveryDiscountInfo,
  } = useAppStore();

  const { setCancelTime } = useCancellationStore();

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const { data: settings } = useQuery("settings", getSettings, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (searchParams.get("voucher_code")) {
      setDeepVoucher(searchParams.get("voucher_code"));
    }

    ReactGA.initialize("G-4B2XQT5BTB");
  }, []);

  useEffect(() => {
    if (settings && settings?.success) {
      if (settings?.data) {
        setAppClose(settings?.data?.appStatus);
        setIsClose(settings?.data?.appStatus);
        setAppCloseMsg(settings?.data?.message);
        setCancelTime(settings?.data?.orderCancelTime);
      }
    }
  }, [settings]);

  const getLocation = (cb) => {
    try {
      setLoading(true);
      if (getLocationParam().length >= 2) {
        const [lat, long] = getLocationParam();
        cb([lat, long]);
        return;
      }

      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            cb([position.coords.latitude, position.coords.longitude]);
          },
          (err) => {
            if (!isClose) setIsClientMap(true);
          }
        );
      }
    } catch (err) {
      console.error(`AppContainer getLocation: ${err}`);
    }
  };

  function getLocationParam() {
    try {
      const locationParam = new URLSearchParams(window.location.search).get(
        "location"
      );
      return typeof locationParam === "string"
        ? locationParam.split(",").map(Number)
        : [];
    } catch (err) {
      console.error(`AppContainer getLocationParam: ${err}`);
    }
  }

  useEffect(() => {
    if (!loading && !isLogin && !currentLocation) {
      setIsClientMap(true);
    }
  }, [loading]);

  const { data, isFetched } = useQuery(
    [`nearAddress`, realLocation || ""],
    getNearAddress,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: isLogin && !!realLocation,
    }
  );

  useEffect(() => {
    if (isFetched || !isLogin) {
      setAppLoading(false);
    }
  }, [isFetched]);

  useEffect(() => {
    if (searchParams.get("lat") && searchParams.get("lon")) {
      setIsPreview(true);
      setCurrentLocation([searchParams.get("lat"), searchParams.get("lon")]);
    } else {
      getLocation((LatLon) => {
        setLoading(false);
        setRealLocation(LatLon);
      });
    }
  }, []);

  useEffect(() => {
    if (realLocation && !isLogin) {
      setCurrentLocation(realLocation);
    }
  }, [realLocation]);

  useEffect(() => {
    try {
      let pwa_user = localStorage.getItem("grocery_user");
      let pwa_selectedAddress = localStorage.getItem("grocery_selectedAddress");
      let basket = localStorage.getItem("basket");
      if (pwa_user) {
        let jsonUser = JSON.parse(pwa_user);
        let jsonSelectedAddress = JSON.parse(pwa_selectedAddress);
        setUser(jsonUser);
        setIsLogin(true);

        if (isFetched && data && data?.data) {
          localStorage.setItem(
            "grocery_selectedAddress",
            JSON.stringify(data?.data[0])
          );
          setSelectedAddress(data?.data[0]);
          setCurrentLocation([data?.data[0].lat, data?.data[0].lon]);
        } else if (jsonSelectedAddress) {
          let userLocation = [jsonSelectedAddress.lat, jsonSelectedAddress.lon];
          setSelectedAddress(jsonSelectedAddress);
          setCurrentLocation(userLocation);
        } else if (realLocation) {
          setIsClientMap(true);
        }
      } else if (realLocation) {
        setCurrentLocation(realLocation);
      }

      if (basket && basket.length > 0) {
        setOrderData(JSON.parse(basket));
      }
    } catch (error) {
      localStorage.clear();
      setIsLogin(false);
      setOrderData([]);
      setUser(null);
    }
    ga4.event("Grocery Session Start", {
      Source: isSuperApp ? "Super App" : "PWA",
      OS: device,
      city: currentLocation,
    });
    if (window && window.webengage)
      window.webengage.track("Grocery Session Start", {
        Source: isSuperApp ? "Super App" : "PWA",
        OS: device,
        city: currentLocation,
      });
  }, [data]);
  let webengageInit = useCallback(() => {
    if (
      isLogin &&
      isSuperApp &&
      user &&
      user.webEngageToken &&
      window &&
      window.webengage
    ) {
      if (
        window?.webengage?.track_ &&
        typeof window.webengage.track_ === "function"
      ) {
        window.webengage.track = window.webengage.track_;
        window.webengage.track_ = undefined;
      }
      window.webengage.user.login(user.webEngageToken);
      window.webengage.user.setAttribute("we_email", user.email);
      window.webengage.user.setAttribute("we_phone", user.phone);
      window.webengage.user.setAttribute("first_name", `${user.firstName}`);
      window.webengage.user.setAttribute("last_name", `${user.lastName}`);
      window.webengage.user.setAttribute(
        "gender",
        user.gender ? `${user.gender}` : "male"
      );
    } else if (window && window?.webengage && !window.webengage?.track_) {
      window.webengage.track_ = window.webengage.track;
      window.webengage.track = () => { };
    }
    if (!isLogin && isSuperApp && !user && window && window.webengage) {
      window.webengage.user.logout();
    }
  }, [user, isLogin, isSuperApp]);

  useEffect(() => {
    webengageInit();
  }, [webengageInit]);

  //Single Sign in with super app
  const SSO_handle = useCallback(async () => {
    if (searchParams.has("token")) {
      setIsSuperAppLoading(true);
      let response = await SSO_REQUEST({
        token: searchParams.get("token"),
        platform: device || "OTHER",
      });

      if (response?.success) {
        setIsSuperApp(true);
        setIsSuperAppLoading(false);
        localStorage.setItem("grocery_token", response?.data?.token);
        localStorage.setItem(
          "grocery_user",
          JSON.stringify(response?.data?.user)
        );
        localStorage.setItem(
          "grocery_refresh_token",
          response?.data?.refreshToken
        );
        setIsLogin(true);
        setUser(response?.data?.user);
        handleWebengage(
          response?.data?.type,
          response?.data?.user,
          response?.data?.os
        );
      } else {
        setIsSuperApp(false);
        setIsSuperAppLoading(false);
      }
    }
  }, [searchParams]);

  const handleWebengage = (type, user, OS) => {
    if (!type || !user) return;
    if (type === "login") {
      ga4.event("Grocery Login", {
        items: [
          {
            channel: "SSO",
            source: device,
            OS: OS,
          },
        ],
      });
      if (window && window.webengage)
        window.webengage.track("Grocery Login", {
          channel: "SSO",
          source: device,
        });
    }
    if (type === "register") {
      ga4.event("Grocery Signup", {
        items: [
          {
            channel: "SSO",
            source: device,
          },
        ],
      });
      if (window && window.webengage)
        window.webengage.track("Grocery Signup", {
          channel: "SSO",
          source: device,
        });
    }
  };

  useEffect(() => {
    SSO_handle();
  }, [SSO_handle]);

  const { data: serviceArea } = useQuery(
    ["checkArea", currentLocation || ""],
    checkServiceArea,
    {
      retry: 2,
      refetchOnWindowFocus: false,
      enabled: !!currentLocation,
    }
  );

  let getServiceArea = useCallback(() => {
    if (serviceArea?.data === 0) {
      setCurrentLocation(null);
      setDeliveryDiscountInfo(null);
    }
    setComment("");
    setVendorID(serviceArea?.data);
  }, [serviceArea]);

  useEffect(() => {
    if (!shouldRefetchVendorID) return;

    getServiceArea();
  }, [getServiceArea, shouldRefetchVendorID]);

  return <div className="app">{children}</div>;
}

export default AppWrapper;
