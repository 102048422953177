import { useCallback, useEffect, useState } from "react";
import { useAppStore } from "../../../../../store";
import {
  BalyBasket,
  BalyCalendar,
  BalyClose,
  BalyVoucher,
} from "../../../../BalyIcons";

import { BalyArrowTop } from "../../../../BalyIcons/ArrowTop";
import { BalyVoucherUsage } from "../../../../BalyIcons/BalyVoucherUsage";
import PureButton from "../../../../Global/Button";
import Container from "../../../../Global/Container";
import { PureModalSheet } from "../../../../Global/PureModalSheet";
import Space from "../../../../Global/Space";
import { VoucherModalItem } from "../VoucherModalItem";
import dayjs from "dayjs";
import Text from "../../../../Global/Text";
import VoucherSteps from "../../VoucherSteps";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const numberFormat = (number) => {
  let roundedNum = Math.round(number);
  return String(roundedNum).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const VoucherModalContainer = () => {
  const {
    isVoucherCenterModal,
    setIsVoucherCenterModal,
    voucherCenterInfo,
    setDeepVoucher,
    setVoucherCenterInfo,
    deepVoucher,
    setDeepVoucherInfo,
  } = useAppStore();
  const navigate = useNavigate();
  const [currentActiveVoucherCode, setCurrentActiveVoucherCode] = useState();
  const [applyButtonProps, setApplyButtonProps] = useState({
    title: "تطبيق",
    class: "primary-voucher-btn",
    disabled: false,
  });

  const isVoucherApplied =
    voucherCenterInfo?.code === currentActiveVoucherCode ||
    deepVoucher === voucherCenterInfo?.code;

  // city tags
  const cityTags =
    voucherCenterInfo?.cityTags !== null &&
      Array.isArray(voucherCenterInfo?.cityTags)
      ? voucherCenterInfo?.cityTags.length === 1
        ? `يعمل داخل ${voucherCenterInfo?.cityTags[0]}`
        : "يعمل داخل عدة محافظات"
      : [];

  // category tags
  const categoryTags =
    voucherCenterInfo?.categoryTags !== null &&
      Array.isArray(voucherCenterInfo?.categoryTags)
      ? voucherCenterInfo?.categoryTags.length === 1
        ? `على قسم ${voucherCenterInfo?.categoryTags[0]}`
        : "على عدة اقسام"
      : [];

  useEffect(() => {
    if (isVoucherApplied) {
      setApplyButtonProps({
        title: "الغاء التطبيق",
        class: "applied-voucher-btn",
        disabled: false,
      });
    } else {
      setApplyButtonProps({
        title: "تطبيق",
        class: "primary-voucher-btn",
        disabled: false,
      });
    }
  }, [voucherCenterInfo?.code, isVoucherApplied, voucherCenterInfo]);

  const handleModalClose = () => {
    setIsVoucherCenterModal(false);
    setVoucherCenterInfo(null);
  };

  const handleApplyVoucher = () => {
    if (applyButtonProps.title === "تطبيق" && !isVoucherApplied) {
      setApplyButtonProps({
        title: "الغاء التطبيق",
        class: "applied-voucher-btn",
        disabled: false,
      });
      setDeepVoucher(voucherCenterInfo?.code);
      setCurrentActiveVoucherCode(voucherCenterInfo?.code);
      setIsVoucherCenterModal(false);
      setDeepVoucherInfo(voucherCenterInfo);
      toast.success("تم تفعيل رمز الخصم");
      navigate("/");
    } else {
      setDeepVoucher(null);
      setIsVoucherCenterModal(false);
      setVoucherCenterInfo(null);
      setApplyButtonProps({
        title: "تطبيق",
        class: "primary-voucher-btn",
        disabled: false,
      });
      setCurrentActiveVoucherCode(null);
    }
  };

  const voucherValidaty = useCallback(() => {
    const date =
      dayjs(endDate).diff(currentDate, "day") + 1 > 0
        ? `${dayjs(endDate).diff(currentDate, "day") + 1} ايام`
        : null;

    const time = voucherCenterInfo?.startTime && voucherCenterInfo?.endTime && (
      <span>
        {Intl.DateTimeFormat("en-EG", {
          timeStyle: "short",
        })
          .format(new Date(voucherCenterInfo?.startTime))
          .replace("PM", "م")
          .replace("AM", "ص")}
        {" - "}
        {Intl.DateTimeFormat("en-EG", {
          timeStyle: "short",
        })
          .format(new Date(voucherCenterInfo?.endTime))
          .replace("PM", "م")
          .replace("AM", "ص")}
      </span>
    );

    return (
      <span>
        {date} ({time})
      </span>
    );
  });

  const currentDate = dayjs(new Date());
  const endDate = voucherCenterInfo && voucherCenterInfo?.endDate;

  const {
    voucherSteps,
    currentStep,
    customerDesc,
    voucherType, //'DISCOUNT', 'DELIVERY', 'COMBINED', 'COMPOUND'
  } = voucherCenterInfo || {};

  const numberString = (number) => {
    let text = "";
    if (number === voucherSteps.length - 1) text = "الاخير";
    else {
      switch (number) {
        case 0:
          text = "الاول";
          break;
        case 1:
          text = "الثاني";
          break;
        case 2:
          text = "الثالث";
          break;
        case 3:
          text = "الرابع";
          break;
        case 4:
          text = "الخامس";
          break;

        default:
          break;
      }
    }

    return text;
  };

  const renderTitle = () => {
    let title = customerDesc;
    let stepObj = voucherSteps?.find((el) => el?.priority === currentStep);
    if (voucherType === "COMPOUND") {
      if (currentStep === 0) title = "خصم متعدد لاكثر من طلب";
      else if (stepObj?.voucherType === "DELIVERY")
        title = `خصم ${stepObj?.totalValue
          }٪ من التوصيل للاستخدام ${numberString(currentStep)}`;
      else
        title = `خصم ${stepObj?.totalValue}٪ على الاستخدام ${numberString(
          currentStep
        )}`;
    }
    return title;
  };

  return (
    <PureModalSheet
      isOpen={isVoucherCenterModal}
      viewHeight={"70%"}
      onClose={handleModalClose}
    >
      <Container className="close_modal_container">
        <PureButton
          styles={{ background: "none", border: 0 }}
          small
          onClick={handleModalClose}
        >
          <BalyClose size={22} />
        </PureButton>
      </Container>
      <Container className="pure-container content_modal_container">
        <div
          className={`green_card ${voucherCenterInfo?.voucherSteps != null && "isCompound"
            }`}
        >
          <div className="card_background">
            <VoucherCardBgShader />
          </div>
          <div
            className={
              voucherCenterInfo?.voucherSteps != null ? "has-compound" : ""
            }
          >
            <Text size={16} color="#ffffff" bold>
              {renderTitle()}
            </Text>
            {voucherCenterInfo?.voucherType === "COMPOUND" && (
              <VoucherSteps
                isModal
                steps={voucherCenterInfo?.voucherSteps || []}
                clientUsage={voucherCenterInfo?.clientUsage}
                currentStep={voucherCenterInfo?.currentStep}
              />
            )}
          </div>
        </div>
        <Space height={12} />
        <div className="voucher_details modal-content2">
          <div className="voucher_items_container">
            <VoucherModalItem
              title={"رمز الخصم"}
              icon={<BalyVoucher size={24} color="#666666" />}
              value={voucherCenterInfo?.code}
              hasCopy
              shouldAlignCenter
            />

            <VoucherModalItem
              title={"الحد الأدنى للسلة"}
              icon={<BalyBasket size={24} color="#666666" />}
              value={`${numberFormat(voucherCenterInfo?.minAmount)} د.ع`}
            />
            <VoucherModalItem
              title={"الحد الأقصى "}
              icon={<BalyArrowTop size={24} color="#666666" />}
              value={`${numberFormat(voucherCenterInfo?.maxAmount)} د.ع`}
            />
            <VoucherModalItem
              title={"الأستخدامات"}
              icon={<BalyVoucherUsage size={24} color="#666666" />}
              value={`${voucherCenterInfo?.noOfUsage === 1
                ? "1 مرة"
                : voucherCenterInfo?.noOfUsage + " مرات"
                }`}
            />
            <VoucherModalItem
              title={"فعال خلال"}
              icon={<BalyCalendar size={24} color="#666666" />}
              value={voucherValidaty()}
            />
            <VoucherModalItem
              title={"فعال في"}
              icon={<BalyVoucher size={24} color="#666666" />}
              value={cityTags + `${!!categoryTags ? ", " + categoryTags : []}`}
            />
          </div>
        </div>
      </Container>
      <Container>
        <div className="voucher_control control-btn">
          <PureButton
            styles={{
              width: "100% ",
            }}
            type="primary"
            onClick={handleApplyVoucher}
            className={applyButtonProps.class}
            disabled={applyButtonProps.disabled}
          >
            {applyButtonProps.title}
          </PureButton>
        </div>
      </Container>
    </PureModalSheet>
  );
};

const VoucherCardBgShader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="324"
    height="67"
    viewBox="0 0 324 67"
    fill="none"
  >
    <path
      d="M361.106 179.671L196.983 301.709C193.85 304.038 190.292 305.734 186.509 306.701L85.4608 332.521C68.5028 336.855 51.4515 325.81 48.4716 308.563L1.31935 35.6514C-2.49078 13.5988 18.3142 -4.71101 39.7039 1.87044L203.982 52.4177C210.469 54.4134 217.44 54.1511 223.758 51.6735L268.277 34.215C281.285 29.1139 296.092 33.6681 303.984 45.1976L367.96 138.651C377.062 151.946 374.036 170.057 361.106 179.671Z"
      fill="url(#paint0_linear_3412_1070)"
      fillOpacity="0.72"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3412_1070"
        x1="499.799"
        y1="-112.912"
        x2="112.362"
        y2="289.137"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01B348" />
        <stop offset="0.791667" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
