import { useQuery } from "react-query";
import { OrderCard } from "../Card";
import { getActiveOrdersList } from "../../../services";
import { useAppStore } from "../../../store";
import { useEffect, useState } from "react";
import { Empty } from "../../Global/Empty";
import { useNavigate } from "react-router-dom";
import { OrderSkeleton } from "../Skeleton";

export const ActiveList = () => {
  const { isLogin } = useAppStore();
  const [list, setList] = useState([]);
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    [`order-active`],
    getActiveOrdersList,
    {
      refetchOnWindowFocus: false,
      enabled: isLogin,
    }
  );

  useEffect(() => {
    if (data?.data && data?.success) {
      setList(data?.data?.records);
    }
  }, [data]);

  if (isLoading) return <OrderSkeleton />;

  if (!isLoading && list?.length === 0)
    return (
      <Empty
        top={"35vh"}
        title="ابدأ بالطلب"
        msg={"قم بوضع طلبات جديدة, وستظهر لك الطلبات النشطة هنا"}
        screen="CART"
        action={() => navigate("/")}
        actionText={"اطلب الان"}
      />
    );

  return list?.map((el, i) => <OrderCard key={i} order={el} />);
};
