import { BalyCalendar, BalyLocation } from "../../../BalyIcons";
import { BsClock } from "react-icons/bs";
import {
  AiOutlineLike,
  AiOutlineDislike,
  AiFillDislike,
  AiFillLike,
} from "react-icons/ai";
import Text from "../../Text";
import "./style.css";
import Reasions from "../Reasions";
import { useRateStore } from "../store";
import bikerImg from "../../../../assets/images/biker-empty.svg";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { submitBikerReview } from "../../../../services";
import { getBikerModalHeight } from "../../../../helper/biker-review";

const BikerRating = () => {
  const {
    vendorRate,
    setView,
    bikerRate,
    setBikerRate,
    order,
    comment,
    reasons,
    selectedBikerReasons,
    setComment,
    view,
  } = useRateStore();
  let currentView = null;

  const [bikerReasons, setBikerReasons] = useState([]);

  const ETA_DATE = `${dayjs(order?.CreatedAt).format("DD-MM-YYYY")}`;
  const ETA_TIME = `${dayjs(order?.CreatedAt).add(10, "m").format("hh:mm A")}`;

  useEffect(() => {
    const reasonsType = reasons?.filter(
      (el) => parseInt(el.rating) === (bikerRate === false ? 1 : 5)
    );
    setBikerReasons(reasonsType[0]?.reasons);
  }, [bikerRate, reasons]);

  const { mutate, isLoading } = useMutation(submitBikerReview, {
    onSuccess: () => {
      setView(currentView);
      setView({
        step: "VENDOR",
        height: vendorRate !== null ? 720 : 428,
      });
    },
  });

  const handleSubmit = (bikerRate, view) => {
    mutate({
      bikerID: order?.bikerOrderID || "0",
      orderID: order?.id,
      trackNo: parseInt(order?.trackNo),
      bikerComment: comment,
      bikerRate: bikerRate === true ? 5 : 1,
      bikerReason: JSON.stringify(selectedBikerReasons),
      phoneNo: order?.biker_phoneNo || "0",
    });
    currentView = view;
  };

  return (
    <div
      className="biker"
      style={{ height: getBikerModalHeight(view), overflow: "auto" }}
    >
      <section className="head">
        <p className="title">ما هو رأيك بالتوصيل؟</p>
        <button
          className="skip-btn"
          onClick={() =>
            setView({ step: "VENDOR", height: vendorRate !== null ? 720 : 428 })
          }
        >
          تخطي
        </button>
      </section>

      <section className="body">
        <div className="avatars">
          <img src={"./logo.png"} alt="" />
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = bikerImg;
            }}
            src={order?.biker_img || bikerImg}
          />
        </div>
        <b>{order?.biker_name || ". . ."}</b>
        <div className="info">
          <div className="item">
            <BalyCalendar size={14} color="#A9A9A9" />
            <Text size={12} color="#A9A9A9">
              {ETA_DATE}
            </Text>
          </div>
          <div className="item">
            <BsClock size={14} color="#A9A9A9" />
            <Text size={12} color="#A9A9A9">
              <span style={{ display: "block", direction: "ltr" }}>
                {ETA_TIME}
              </span>
            </Text>
          </div>
          <div className="item">
            <BalyLocation size={16} color="#A9A9A9" />
            <Text ellipsis width={80} size={12} color="#A9A9A9">
              {order?.clientAddress?.length > 16
                ? `${order?.clientAddress?.substr(0, 16)}...`
                : order?.clientAddress}
            </Text>
          </div>
        </div>

        <div className="rate-btns">
          <button
            onClick={() => {
              setBikerRate(false);
              setView({ step: "BIKER", height: 720 });
            }}
          >
            {bikerRate === false ? (
              <AiFillDislike
                size={26}
                color="#FF0000"
                className="__dislike-icon"
              />
            ) : (
              <AiOutlineDislike size={26} className="__dislike-icon" />
            )}
          </button>
          <button
            onClick={() => {
              let newRate = bikerRate === true ? null : true;
              setBikerRate(newRate);
              if (newRate === null) return;
              handleSubmit(newRate, {
                step: newRate === true ? "VENDOR" : "BIKER",
                height: newRate ? (vendorRate ? 720 : 428) : 428,
              });
            }}
          >
            {bikerRate === true ? (
              <AiFillLike size={26} color="#01B248" />
            ) : (
              <AiOutlineLike size={26} />
            )}
          </button>
        </div>

        {bikerRate === false && (
          <Reasions
            value={comment}
            onChange={setComment}
            title={"ما الذي يمكننا تحسينه؟"}
            list={bikerReasons}
          />
        )}
      </section>
      {bikerRate === false && (
        <section className="actions">
          <button
            disabled={isLoading}
            onClick={() => {
              handleSubmit();
            }}
            className="primary"
          >
            التالي
          </button>
        </section>
      )}
    </div>
  );
};

export default BikerRating;
