import "./style.css";

import { useEffect, useState } from "react";
import {
  WarningIcon,
  ErrorIcon,
  SuccessIcon,
  NoteIcon,
  BalyClose,
} from "../../BalyIcons";
import Touchable from "../Ripple";

export const BalyAlertMessage = ({
  message,
  type,
  isOpen,
  onClose,
  duration = 6000,
}) => {
  const [bgColor, setBgColor] = useState(null);
  const [animationKey, setAnimationKey] = useState(Math.random());

  useEffect(() => {
    if (type === "error") {
      setBgColor("rgba(255, 0, 0, 0.12)");
    } else if (type === "warning") {
      setBgColor("rgba(240, 173, 1, 0.12)");
    } else if (type === "success") {
      setBgColor("rgba(1, 178, 72, 0.12)");
    } else if (type === "note") {
      setBgColor("rgba(240, 240, 240, 1)");
    }

    if (message) {
      setAnimationKey(Math.random());
    }
  }, [message, type]);

  useEffect(() => {
    const visibleTimeout = setTimeout(() => {
      onClose();
      clearTimeout(visibleTimeout);
    }, duration);

    return () => {
      clearTimeout(visibleTimeout);
    };
  }, [isOpen]);

  const Icon = () => {
    switch (type) {
      case "error":
        return <ErrorIcon />;
      case "warning":
        return <WarningIcon />;
      case "success":
        return <SuccessIcon />;
      case "note":
        return <NoteIcon />;
      default:
        break;
    }
  };

  return (
    <div
      key={animationKey}
      className={`OrderAlert-msg ${isOpen ? "show-animation" : ""}`}
      style={{ visibility: isOpen ? "visible" : "hidden" }}
    >
      <div className="icon" style={{ backgroundColor: bgColor }}>
        <Icon />
      </div>
      <div className="text">
        <p className="title">
          <b>{message}</b>
        </p>
      </div>
      <div className="CloseIcon">
        <Touchable
          onClick={() => {
            onClose();
          }}
        >
          <BalyClose />
        </Touchable>
      </div>
    </div>
  );
};
