export const BalyLocation = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 22 22`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9498 15.0748C15.1302 15.8943 13.4164 17.6081 12.2371 18.7874C11.5536 19.4709 10.4467 19.4712 9.76328 18.7878C8.60455 17.629 6.92389 15.9484 6.05026 15.0748C3.31658 12.3411 3.31658 7.90892 6.05026 5.17526C8.78392 2.44158 13.2161 2.44158 15.9498 5.17526C18.6834 7.90892 18.6834 12.3411 15.9498 15.0748Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.625 10.125C13.625 11.5748 12.4498 12.75 11 12.75C9.55021 12.75 8.375 11.5748 8.375 10.125C8.375 8.67526 9.55021 7.5 11 7.5C12.4498 7.5 13.625 8.67526 13.625 10.125Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
