import "./style.css";

const CatAvatarSkeleton = () => {
  const textSize = [80, 60, 30, 68, 66, 45, 70, 56];
  return (
    <div className="sk-h-cat">
      <div className="sk-h-cat-list">
        {[0, 1, 2, 3, 4, 5].map((el) => (
          <div key={el} className="sk-h-cat-item">
            <div className="sk-h-cat-item-img pure-skeleton"></div>
            <div
              className="sk-h-cat-item-text pure-skeleton"
              style={{ width: `${textSize[el]}%` }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CatAvatarSkeleton;
