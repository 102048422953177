export const BalyVoucher = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.50033 11.6667L12.5003 6.66667M7.91702 7.08333H7.92535M12.0837 11.25H12.092M15.8337 17.5V4.16667C15.8337 3.24619 15.0875 2.5 14.167 2.5H5.83366C4.91318 2.5 4.16699 3.24619 4.16699 4.16667V17.5L7.08366 15.8333L10.0003 17.5L12.917 15.8333L15.8337 17.5ZM8.33366 7.08333C8.33366 7.31345 8.14711 7.5 7.91699 7.5C7.68688 7.5 7.50033 7.31345 7.50033 7.08333C7.50033 6.85322 7.68688 6.66667 7.91699 6.66667C8.14711 6.66667 8.33366 6.85322 8.33366 7.08333ZM12.5003 11.25C12.5003 11.4801 12.3137 11.6667 12.0837 11.6667C11.8536 11.6667 11.667 11.4801 11.667 11.25C11.667 11.0199 11.8536 10.8333 12.0837 10.8333C12.3137 10.8333 12.5003 11.0199 12.5003 11.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
