import React, { useState, useEffect } from "react";
import ga4 from "react-ga4";
import "./style.css";
import Text from "../../Global/Text";
import PureButton from "../../Global/Button";
import Container from "../../Global/Container";
import { getUDID } from "../../../helper/GetUDID";
import { useAppStore } from "../../../store";
import { useMutation, useQuery } from "react-query";
import { makeOrder, checkFirstOrder } from "../../../services";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { getPrices } from "../../Global/Invoice";
import { useCallback } from "react";
import { getServiceFeeValue } from "../../../helper/service-fee";
import { calculateDeliveryFeeDiscount } from "../../../helper/calculateFreeDelivery";
import queryClient from "../../../queryClient";

export const CheckoutCTA = ({ disabled }) => {
  const [isFirstOrder, setIsFirstOrder] = useState(false);

  const {
    setNotSameLocationModal,
    device,
    realLocation,
    selectedAddress,
    user,
    basket,
    setBasket,
    paymentMethod,
    isAddressAvilabel,
    voucher,
    setVoucher,
    vendor,
    Comment,
    setComment,
    vendorID,
    isSameLocation,
    placeOrderWithAddressWarning,
    setPlaceOrderWithAddressWarning,
    serviceFeeInformation,
    setVoucherCenterInfo,
    setDeepVoucher,
    deliveryDiscountInfo,
  } = useAppStore();
  const navigate = useNavigate();

  let { total, subTotal, voucherValue } = getPrices(basket, voucher, vendor);
  const [datae, setdatae] = useState();
  const { mutate, isLoading } = useMutation(makeOrder, {
    onSuccess: (data) => {
      if (data.success) {
        queryClient.invalidateQueries(["VoucherAvailable"]);
        setdatae(data?.data);
        setVoucher(null);
        setVoucherCenterInfo(null);
        setDeepVoucher(null);
        setComment("");
        ga4.event("Grocery Purchase", {
          voucherCode: data?.data?.voucherCode || "",
          itemName: data?.data?.orderDetail?.map((el) => el?.product?.name),
          Category: data?.data?.orderDetail?.map((el) => el?.product?.category),
          totalOrdervalue: data?.data?.totalValue,
          totalOrderItems: data?.data?.orderDetail?.length,
          vendorID: data?.data?.vendorID,
          orderID: data?.data?.id,
          discountValue: data?.data?.discountTotalValue,
        });
        if (window && window.webengage)
          window.webengage.track("Grocery Purchase", {
            voucherCode: data?.data?.voucherCode || "",
            itemName: data?.data?.orderDetail?.map((el) => el?.product?.name),
            Category: data?.data?.orderDetail?.map(
              (el) => el?.product?.category
            ),
            totalOrdervalue: data?.data?.totalValue,
            totalOrderItems: data?.data?.orderDetail?.length,
            vendorID: data?.data?.vendorID,
            orderID: data?.data?.id,
            discountValue: data?.data?.discountTotalValue,
          });
        localStorage.removeItem("grocery_basket");
        setBasket([]);
        navigate(`/order/${data?.data?.id}`);
      } else {
        toast.error(data?.err);
      }
    },
    onError: (e) => {
      toast.error("خطأ بالخادم!");
    },
  });
  const { data, refetch } = useQuery(["isFirstOrder"], checkFirstOrder, {
    refetchOnWindowFocus: false,
    enabled: !!user,
  });
  useEffect(() => {
    if (data && data.success && data.data) {
      setIsFirstOrder(data.data.firstOrder);
    }
  }, [data]);

  const handleSubmit = () => {
    if (!isSameLocation) {
      setNotSameLocationModal(true);
      return;
    }

    const data = {
      orderDetail: basket?.map((el) => {
        return {
          productID: el?.item?.vID,
          qty: el?.qty,
        };
      }),
      clientID: user?.id,
      clientAddressID: selectedAddress?.id || 0,
      currenClientLat: realLocation ? parseFloat(realLocation[0]) : 0,
      currenClientLon: realLocation ? parseFloat(realLocation[1]) : 0,
      vendorID: vendorID,
      paymentTypeID: 1,
      voucher_code: voucher?.isReferral ? "" : voucher?.code || "",
      referalCode: !voucher?.isReferral ? "" : voucher?.referalCode || "",
      platform: device,
      UDID: getUDID(),
      declineReason: "{}",
      discountCapID: vendor?.discountCap?.id || 0,
      clientComment: Comment,
      clientCommentAddress: "",
      vendorComment: "",
      preOrder: false,
      preOrderDeliveryTime: "2016-06-22 19:10:25-07",
    };
    mutate(data);
  };

  useEffect(() => {
    if (placeOrderWithAddressWarning) {
      handleSubmit();
      setPlaceOrderWithAddressWarning(false);
    }
  }, [isSameLocation]);

  const serviceFeeValue = useCallback(
    () => getServiceFeeValue(serviceFeeInformation, subTotal),
    [serviceFeeInformation, subTotal]
  );

  const hasFreeDeliveryFeature =
    (deliveryDiscountInfo?.Discount > 0 &&
      total + voucherValue >= deliveryDiscountInfo?.Minamount) ||
    voucher?.voucherType === "COMBINED" ||
    (voucher?.voucherType === "DELIVERY" &&
      total + voucherValue >= voucher?.minAmount);

  const calculateServiceFee = useCallback(
    () => getServiceFeeValue(serviceFeeInformation, subTotal),
    [serviceFeeInformation, subTotal]
  );

  let freeDeliveryValue = calculateDeliveryFeeDiscount(
    deliveryDiscountInfo,
    vendor,
    total + voucherValue,
    voucher
  );

  const orderTotalValue = () => {
    if (["COMBINED", "DISCOUNT"].includes(voucher?.voucherType)) {
      return freeDeliveryValue;
    }

    if (voucher?.voucherType === "DELIVERY") {
      if (freeDeliveryValue == vendor?.delivery || freeDeliveryValue === 0) {
        return 0;
      } else {
        return vendor?.delivery - freeDeliveryValue;
      }
    }

    return freeDeliveryValue + voucherValue;
  };

  return (
    <div className="checkout-cta">
      <Container>
        <PureButton
          disabled={
            disabled || isLoading || !paymentMethod || !isAddressAvilabel
          }
          type="primary"
          block
          radius={24}
          onClick={handleSubmit}
        >
          {isLoading ? (
            <div className="btn-loading"></div>
          ) : (
            <Text bold size={18} color="#fff">
              {`وضع الطلب - ${Number(
                Math.ceil(
                  (total +
                    (hasFreeDeliveryFeature
                      ? orderTotalValue()
                      : vendor?.delivery)) /
                    250
                ) *
                  250 +
                  calculateServiceFee()
              ).toLocaleString("en")}
               د.ع`}
            </Text>
          )}
        </PureButton>
      </Container>
    </div>
  );
};
