import "./style.css";
import { useAppStore } from "../../../store";
import { useNavigate } from "react-router-dom";
import { IoCaretDown, IoChevronForward } from "react-icons/io5";
import { useEffect, useState } from "react";
import { GoToLink } from "../../../helper";
import { BalyHome2 } from "../../BalyIcons";
import { BalyMenu } from "../../BalyIcons/Menu";
import { useLocation } from "react-router-dom";
import Space from "../Space";
import Ripples from "react-ripples";
import Touchable from "../Ripple";
import Popover from "../Popover";

const MainHeader = ({
  withBack,
  filter,
  withoutShadow,
  withoutAddress,
  bottom,
  withoutHome,
}) => {
  const navigate = useNavigate();
  const {
    setIsClientMap,
    setIsMenu,
    selectedAddress,
    currentLocation,
    user,
    isPreview,
    isSuperApp,
    isLogin,
    addressPopoverConfirmation,
    setAddressPopoverConfirmation,
  } = useAppStore();

  const location = useLocation();

  const handleBack = () => {
    if (window.location.pathname !== location.pathname) {
      GoToLink(() => navigate(location.pathname));
    } else if (
      window.location.pathname.includes("/groups/appbanner-id/") ||
      window.location.pathname.includes("/groups/category-id")
    ) {
      GoToLink(() => navigate("/"));
    } else if (isSuperApp && location.key === "default") {
      window.location.href = "balyhomepage://";
    } else {
      GoToLink(() => window.history.go(-1));
    }
  };

  const [address, setAddress] = useState("......");

  useEffect(() => {
    if (user) {
      if (selectedAddress) {
        setAddress(selectedAddress.address);
      } else {
        if (currentLocation) {
          setAddress("الموقع الحالي");
        } else {
          setAddress("حــدد العنــوان");
        }
      }
    } else {
      if (currentLocation) {
        setAddress("الموقع الحالي");
      } else {
        setAddress("حــدد العنــوان");
      }
    }
  }, [user, selectedAddress, currentLocation]);

  return (
    <div
      className="main-header"
      style={withoutShadow ? { boxShadow: "none" } : {}}
    >
      <div className="app-container">
        <section className="app-flex-space menu">
          {withBack ? (
            <div className="tauchable" onClick={() => handleBack()}>
              <IoChevronForward size={24} />
            </div>
          ) : (
            <Touchable>
              <div className="menu-btn" onClick={() => setIsMenu(true)}>
                <BalyMenu size={24} />
              </div>
            </Touchable>
          )}
          {!withoutAddress && (
            <div
              style={{
                borderRadius: 20,
                marginRight: 0,
                marginTop: "-8px",
                overflow: "hidden",
              }}
            >
              <Popover
                show={addressPopoverConfirmation}
                title={"هل انت تتسوق من هذا العنوان المحدد حاليا؟"}
                backdrop={true}
                onClose={() => setAddressPopoverConfirmation(false)}
                closeOnBackdrop={false}
                actions={
                  <div className="app-flex-space-between">
                    <button
                      className="popover_transparent_btn secondary"
                      onClick={() => {
                        setAddressPopoverConfirmation(false);
                        setIsClientMap(true);
                      }}
                    >
                      تعديل العنوان
                    </button>
                    <button
                      className="popover_transparent_btn primary"
                      onClick={() => {
                        setAddressPopoverConfirmation(false);
                      }}
                    >
                      بلي, اكمل التسوق
                    </button>
                  </div>
                }
              >
                <Ripples>
                  <button
                    disabled={isPreview}
                    className="app-flex-space"
                    onClick={() => setIsClientMap(true)}
                  >
                    <p style={{ padding: "0px 3px" }}>{address}</p>
                    <IoCaretDown size={20} />
                  </button>
                </Ripples>
              </Popover>
            </div>
          )}

          <div style={{ width: "auto" }} className="app-flex-space">
            {!withoutHome ? (
              <Touchable>
                <a
                  style={{ padding: 0 }}
                  href={isSuperApp ? "balyhomepage://" : "/"}
                >
                  <BalyHome2 color="#000" size={24} />
                </a>
              </Touchable>
            ) : (
              <Space width={24} />
            )}
          </div>
        </section>

        {bottom || filter}
      </div>
    </div>
  );
};

export default MainHeader;
