import { useEffect, useState } from "react";
import Screen from "../components/Global/Screen";
import Tauchable from "../components/Global/Ripple";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GoToLink } from "../helper";
import ChildHeader from "../components/Global/ChildHeader";
import { useMutation } from "react-query";
import { Toaster, toast } from "react-hot-toast";
import { login } from "../services";
import { useAppStore } from "../store";
import { Helmet } from "react-helmet";

const LoginByPassScreen = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [searchParams] = useSearchParams();
  const { setUser, setIsLogin } = useAppStore();

  const ArToEn = (numb) => {
    return numb.replace(/[\u0660-\u0669]/g, (d) => d.charCodeAt() - 1632);
  };

  useEffect(() => {
    if (searchParams.get("phone")) {
      setPhone(searchParams.get("phone"));
    }
  }, [searchParams.get("phone")]);

  const { mutate, isLoading } = useMutation(login, {
    onSuccess: async (data) => {
      if (data.success) {
        toast.success(data.err);
        localStorage.setItem("grocery_token", data.data.token);
        localStorage.setItem("grocery_user", JSON.stringify(data.data.user));
        localStorage.setItem("grocery_refresh_token", data.data.refreshToken);
        setIsLogin(true);
        setUser(data.data.user);
        GoToLink(() => navigate("/"));
      } else {
        toast.error(data.msg);
      }
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const phoneValidate = () => {
    const regex = /^[\u0621-\u064A\u0660-\u0669]+|07[3-9]\d{1,11}$/;
    const result = regex.exec(phone);
    return result;
  };
  const handleRegister = () => {
    try {
      if (phoneValidate()) {
        mutate({
          phone: ArToEn(phone),
          password,
        });
      } else {
        toast.error("رقم الهاتف غير صحيح!");
      }
    } catch (err) {
      console.error(`LoginByPassScreen handleRegister: ${err}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>تسجيل الدخول</title>
      </Helmet>
      <ChildHeader></ChildHeader>
      <Screen>
        <Toaster position="top-center" reverseOrder={false} />
        <div className="login-by-pass app-container">
          <section className="card">
            <input
              className="phone"
              type="tel"
              placeholder="رقم الهاتف"
              maxLength="11"
              value={phone}
              name="phone"
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              className="pass"
              type="password"
              value={password}
              placeholder="كلمة المرور"
              onChange={(e) => setPassword(e.target.value)}
            />
          </section>
          <section className="confirm-box app-container">
            <Tauchable>
              <button
                disabled={
                  !phoneValidate() ||
                  phone.length !== 11 ||
                  phoneValidate()?.index !== 0 ||
                  !password
                }
                onClick={handleRegister}
                style={{ width: "100%" }}
                className={
                  !phoneValidate() ||
                  phone.length !== 11 ||
                  phoneValidate()?.index !== 0 ||
                  !password
                    ? "disabled-btn"
                    : "primary-btn"
                }
              >
                {isLoading ? <div className="btn-loading"></div> : "تــــأكيد"}
              </button>
            </Tauchable>
          </section>
        </div>
      </Screen>
    </>
  );
};

export default LoginByPassScreen;
