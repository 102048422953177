export const BalyHome = ({ size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 8.5L3.16667 6.83333M3.16667 6.83333L9 1L14.8333 6.83333M3.16667 6.83333V15.1667C3.16667 15.6269 3.53977 16 4 16H7.5M14.8333 6.83333L16.5 8.5M14.8333 6.83333V15.1667C14.8333 15.6269 14.4602 16 14 16H10.5M7.5 16H10.5M7.5 16V11.5H10.5V16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
