import "./style.css";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const SearchBox = ({
  hint = "ابحث عن المنتجات",
  link = "/search",
  disable = false,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="app-search-box"
      onClick={() => (!disable ? navigate(link) : undefined)}
    >
      <span>{hint}</span>
      <IoSearch size={20} />
    </div>
  );
};

export default SearchBox;
