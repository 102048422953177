import "./style.css";
import { useAppStore } from "../../../store";
import { BalyFreeDiscount } from "../../BalyIcons/FreeDiscount";
import Text from "../Text";

export const PureFreeDiscountAlert = ({
  color = "#000000",
  borderColor = "#0000FF",
}) => {
  const { deliveryDiscountInfo } = useAppStore();
  return (
    <div
      className="pure-tag-voucher"
      style={{
        background: "#fff",
        color: color,
        border: `1px solid ${borderColor}`,
      }}
    >
      <div className="discount_image">
        <BalyFreeDiscount size={40} />
      </div>
      <div className="title-tag">
        <Text size={14}>
          على كل طلب بقيمة{" "}
          {Number(deliveryDiscountInfo?.Minamount).toLocaleString("en")} د.ع او
          اكثر,{" "}
          {deliveryDiscountInfo?.Discount === 100
            ? " توصيل مجاني "
            : ` خصم ${deliveryDiscountInfo?.Discount}% على قيمة التوصيل`}
        </Text>
      </div>
    </div>
  );
};
