export const BalyNavHome = ({ fill, size = 18, color = "#000", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M20 7.767v12.466a2.276 2.276 0 0 1-2.286 2.267H6.286A2.276 2.276 0 0 1 4 20.233V4.5l4 2.267L12 4.5l4 4.4 4-1.133Z"
          stroke={fill ? "#0000ff" : "#000"}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill={fill ? "#0000ff" : "#fff"}
        />
        <path
          d="M10.134 9.24c.043-.817 1.106-1.102 1.552-.416.199.306.573.45.926.355.79-.211 1.39.712.875 1.347a.844.844 0 0 0-.052.992c.446.686-.247 1.54-1.01 1.248a.844.844 0 0 0-.96.257c-.514.635-1.542.24-1.499-.576a.844.844 0 0 0-.54-.832c-.764-.293-.706-1.393.084-1.604a.844.844 0 0 0 .624-.772Z"
          fill={fill ? "#fff" : "#000"}
        />
        <rect
          x={6}
          y={16.398}
          width={4.421}
          height={4.421}
          rx={1}
          transform="rotate(-25.431 6 16.398)"
          fill={fill ? "#fff" : "#000"}
        />
        <rect
          x={14}
          y={2.398}
          width={4.421}
          height={4.421}
          rx={2.211}
          transform="rotate(-25.431 14 2.398)"
          fill={fill ? "#0000ff" : "#000"}
        />
        <path
          d="m17.46 17.768-2.165-2.094a1 1 0 0 0-1.656.444l-.828 2.896a1 1 0 0 0 1.22 1.24l2.993-.801a1 1 0 0 0 .437-1.685Z"
          fill={fill ? "#fff" : "#000"}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
