import { lazy, Suspense } from "react";
import ChildHeader from "../components/Global/ChildHeader";
import Screen from "../components/Global/Screen";
import { Helmet } from "react-helmet";

const UserForm = lazy(() => import("../components/UserProfile/Form"));

const UserProfileScreen = () => {
  return (
    <>
      <Helmet>
        <title>الملف الشخصي</title>
      </Helmet>
      <ChildHeader title={"معلومـــات المستخدم"}/>
      <Screen>
        <Suspense fallback={<div className="loader"></div>}>
          <UserForm />
        </Suspense>
      </Screen>
    </>
  );
};

export default UserProfileScreen;
