import "./style.css";
import Text from "../../Global/Text";
import Divider from "../../Global/Divider";
import PureButtonIcon from "../../Global/ButtonIcon";
import { BalyClose } from "../../BalyIcons";
import { useAppStore } from "../../../store";

export const SearchHistory = ({ setSearchValue }) => {
  const { setSearchHistory, searchHistory, setQuerySearch } = useAppStore();

  const handleTrashHistory = (item) => {
    setSearchHistory(searchHistory.filter((el) => el !== item));
  };

  return searchHistory.length ? (
    <div className="search-history">
      <Text bold size={18}>
        سجل البحث
      </Text>
      <Divider />
      {searchHistory.map((el, i) => (
        <div
          key={i}
          className="search-history-item flex-space"
          onClick={() => {
            setSearchValue(el);
            setQuerySearch(el);
          }}
        >
          <Text size={18}>{el}</Text>
          <PureButtonIcon
            onClick={(e) => {
              e.stopPropagation();
              handleTrashHistory(el);
            }}
          >
            <BalyClose color="#ccc" size={20} />
          </PureButtonIcon>
        </div>
      ))}
    </div>
  ) : null;
};
